import {
  Box,
  MobileStepper,
  Modal,
  Radio,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const ModalBoxContent = styled(Box)(({ theme }) => ({
  // paddingBottom: activeStep === 3 ? theme.spacing(8) : 0, // Remove paddingBottom when activeStep is 2
  // maxHeight: activeStep === 1 || 2 ? '600px' : null, // Set a maximum height for the modal box
  overflowY: 'auto' // Enable ver
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  height: '20vh',
  marginTop: '45vh',
  marginBottom: '45vh',
  maxHeight: '100px',

  [theme.breakpoints.down('md')]: {
    height: 'auto',
    maxHeight: 'auto',
    zIndex: 10000
  },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    maxHeight: 'auto',
    zIndex: 10000
  },
  [theme.breakpoints.down('xs')]: {
    height: 'auto',
    maxHeight: 'auto',
    zIndex: 10000
  }
}));

export const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10,
  width: '90%',
  minWidth: 300,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    width: '60%'
  },
  [theme.breakpoints.up('lg')]: {
    width: '40%'
  }
}));

export const ModalBoxHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.2),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start'
}));

export const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.palette.primary.main};
`;

export const ModalBoxFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.2),
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const StyledMobileStepper = styled(MobileStepper)`
  & .MuiStepConnector-line {
    min-width: 140px;
  }
  width: 100%;
  max-width: 400px;
  flex-grow: 2;
  background-color: transparent;
  & .MuiMobileStepper-progress {
    background-color: #eff0f6;
  }
  .MuiLinearProgress-root {
    width: 100%;
    .MuiLinearProgress-bar {
      background-color: #4bd2d2;
    }
  }
`;

export const StepperContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  flex-direction: column;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: 350px;
  overflow-y: auto;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: white;
  height: 35px;
`;

export const RadioIcon = styled(Radio)`
  padding: 0;
`;

export const StyledTableCell = styled(TableCell)`
  height: 40px;
  &.MuiTableCell-root {
    padding: 0 ${({ theme }) => theme.spacing(2)};
  }
  .radio {
    display: none;
  }
  &:hover {
    .radio {
      display: block;
    }
    .icon {
      display: none;
    }
  }
  &.selected {
    .radio {
      display: block;
    }
    .icon {
      display: none;
    }
  }
`;

export const AddNewButton = styled('div')`
  display: flex;
  align-items: center;
`;

export const Row = styled(TableRow)`
  height: 40px;
`;

export const StyledHeaderLabel = styled(TableSortLabel)`
  .MuiTableSortLabel-icon {
    opacity: 0.9;
    width: ${({ theme }) => theme.spacing(2)};
    height: ${({ theme }) => theme.spacing(2)};
    transition: 0.9s;
  }
  text-align: left;
  padding: 0;
`;

export const StyledHeaderTableCell = styled(TableCell)(
  ({ theme }) => `
  &.MuiTableCell-root.MuiTableCell-head{
    padding: 0 ${theme.spacing(2)};
  }
`
);

export const CreatedAtCell = styled(StyledHeaderTableCell)`
  width: 30%;
`;
