import { Navigate, useParams } from 'react-router';

export const NavigateToChecklistBuilder = () => {
  const { checklistId, templateId, unitId } = useParams();

  return (
    <Navigate
      to={`/checklist-builder?${
        checklistId ? `unitId=${unitId}&checklistId=${checklistId}` : ''
      }${templateId ? 'templateId=' + templateId : ''}`}
      replace
    />
  );
};
