import { FC, Fragment, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
  DroppableProvided,
  DropResult,
  ResponderProvided,
  Draggable as DndDraggable,
  DraggableProvidedDragHandleProps
} from 'react-beautiful-dnd';
import { Box } from '@mui/material';

interface Props {
  onDragEnd: OnDragEndResponder;
  DraggableItemsWrapper: (p: DroppableProvided) => JSX.Element;
}
/** @deprecated please use exported const DraggableList instead */
const Draggable: FC<Props> = ({ DraggableItemsWrapper, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => <DraggableItemsWrapper {...provided} />}
      </Droppable>
    </DragDropContext>
  );
};

export default Draggable;

interface DraggableListProps<T extends { id: number }> {
  list: T[];
  render: (
    item: T,
    index: number,
    dragHandleProps: DraggableProvidedDragHandleProps
  ) => React.ReactNode;
  disabled?: boolean;
  containerStyle?: any;
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
}

export function DraggableList<T extends { id: number }>({
  list,
  containerStyle,
  disabled,
  render,
  onDragEnd
}: DraggableListProps<T>) {
  const [activeDraggableId, setActiveDraggableId] = useState(undefined);
  const hash = useMemo(() => Math.random(), []);
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeDragStart={({ draggableId }) => setActiveDraggableId(draggableId)}
    >
      <Droppable droppableId={`droppable-${hash}`}>
        {(provided) => {
          return (
            <div ref={provided.innerRef} className="draggable-list-container">
              {list.map((e, i) => (
                <DndDraggable
                  key={e.id}
                  draggableId={e.id.toString()}
                  index={i}
                  isDragDisabled={disabled}
                >
                  {(provided, snapshot) => {
                    return (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...(e.id.toString() != activeDraggableId && {
                          style: {
                            transition: 'none !important'
                          }
                        })}
                        sx={containerStyle}
                      >
                        {render(e, i, provided.dragHandleProps)}
                      </Box>
                    );
                  }}
                </DndDraggable>
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
