import {
  Box,
  Breadcrumbs,
  Link,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.tooltip.background,
    color: theme.tooltip.color,
    boxShadow: theme.tooltip.boxShadow,
    fontSize: 11
  }
}));

export const StyledTypography = styled(Typography)`
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;
  letter-spacing: -1%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
