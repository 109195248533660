import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { LogicIcon } from 'src/components/Icons/Logic';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
interface Props {
  callbacks: {
    addInstruction?: () => void;
    addImage?: () => void;
    addLogic?: () => void;
    addMedia?: () => void;
    open?: () => void;
    edit?: () => void;
    duplicate?: () => void;
    delete?: () => void;
    manageTemplate?: () => void;
  };
}

export const useActionMenu = ({ callbacks }: Props) => {
  const { t } = useTranslation();
  let items = [];

  if (callbacks.addInstruction) {
    items.push({
      Icon: <InfoOutlinedIcon />,
      title: t('ADD_INSTRUCTIONS'),
      onClick: () => callbacks.addInstruction()
    });
  }

  if (callbacks.addImage) {
    items.push({
      Icon: <ImageOutlinedIcon />,
      title: t('ADD_IMAGE'),
      onClick: () => callbacks.addImage()
    });
  }

  if (callbacks.addLogic) {
    items.push({
      Icon: <LogicIcon />,
      title: t('ADD_LOGIC'),
      onClick: () => callbacks.addLogic()
    });
  }

  if (callbacks.addMedia) {
    items.push({
      Icon: <AutoAwesome />,
      title: t('VERIFY_WITH_AI'),
      onClick: () => callbacks.addMedia()
    });
  }

  if (callbacks.open) {
    items.push({
      Icon: <OpenWithIcon fontSize="small" />,
      title: t('OPEN'),
      onClick: () => callbacks.open()
    });
  }

  if (callbacks.edit) {
    items.push({
      Icon: <EditIcon fontSize="small" />,
      title: t('EDIT'),
      onClick: () => callbacks.edit()
    });
  }

  if (callbacks.manageTemplate) {
    items.push({
      Icon: <OpenWithIcon fontSize="small" />,
      title: t('MANAGE_TEMPLATE'),
      onClick: () => callbacks.manageTemplate()
    });
  }

  if (callbacks.duplicate) {
    items.push({
      Icon: <ContentCopyIcon fontSize="small" />,
      title: t('DUPLICATE'),
      onClick: () => callbacks.duplicate()
    });
  }

  if (callbacks.delete) {
    items.push({
      Icon: <DeleteOutlineIcon fontSize="small" />,
      title: t('DELETE'),
      onClick: () => callbacks.delete()
    });
  }

  return {
    items
  };
};
