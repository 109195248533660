import { Fragment } from 'react';
import NotesIcon from '@mui/icons-material/Notes';
import { Box, Typography } from '@mui/material';
import { EllipsisTypography } from '../EllipsisTypography';
import { OverflownText } from '../SiteNavigationBreadcrumbs/components/OverflownText';

interface Props {
  id: number | string;
  Icon?: () => JSX.Element;
  width?: string;
  lineHeight?: any;
  showTooltip?: boolean;
}
export const IdWrapper = ({
  id,
  Icon,
  width = '200px',
  lineHeight,
  showTooltip = false
}: Props) => {
  if (showTooltip)
    return (
      <Box display="flex" flexDirection="row">
        {Icon && <Icon />}
        <Box paddingRight={0.5} />
        <OverflownText title={id} headVar={'h5'} />
      </Box>
    );
  return (
    <Box display="flex" flexDirection="row">
      {Icon && <Icon />}
      <Box paddingRight={0.5} />
      <EllipsisTypography
        variant="h5"
        width={width}
        whiteSpace="nowrap"
        lineHeight={lineHeight}
      >
        {id}
      </EllipsisTypography>
    </Box>
  );
};
