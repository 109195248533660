const enJSON = {
  NO: 'No',
  YES: 'Yes',
  NOT_SURE: 'Not Sure',
  NA: 'N/A',
  REPORT_DETAILS_HEADER_TITLE: 'Report Details',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'Download PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Download XLSX',
  REPORT_DETAILS_NO_ANSWER: 'No Answer',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Accountability',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'This section shows who performed the report',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Verification',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' All data verified with Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unit',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspection',
  ISSUES_HEADER: 'Flagged',
  ISSUES_SUBHEADER: 'Manage issues found during inspection',
  ISSUES_PRIORITY: 'Priority',
  ISSUES_REPORTED: 'issues reported',
  COMPLETED_AT: 'Completed at',
  BY: 'by',
  ON: 'on',
  CRITERIA_CHECKED: 'criteria were checked across',
  SECTIONS: 'sections',
  NO_FLAGGED_ITEMS: 'No items were flagged',
  FLAGGED_ITEMS: 'items were flagged across',
  FLAGS_RAISED: 'flags raised',
  CHECKER: 'checker reported that they were',
  NO_ISSUES_RAISED: 'The report has no issues',
  ISSUE_RAISED: 'Issue raised',
  REMARK: 'Remark',
  LOCATION: 'Location',
  UPPERCASE_TITLE: 'TITLE',
  STATUS: 'STATUS',
  UPPERCASE_UNIT: 'UNIT',
  UNIT: 'Unit',
  DEVICE_DETAILS: 'Device Details',
  DATE: 'Date',
  NAME: 'Name',
  SIGNATURE: 'Signature',
  COUNTER_SIGNATURE_CREATED: 'co-signature added',
  CO_SIGNED: 'Co-signed',
  CO_SIGNATURE: 'Co-signature',
  IP_ADDRESS: 'IP Address',
  SIGN: 'Sign',
  DOWNLOAD: 'Download',
  DOWNLOADED: 'Downloaded Successfully',
  CHECKLIST: 'Checklist',
  IMAGES: 'IMAGES',
  REPORTED: 'REPORTED',
  ID: 'ID',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  SAVED: 'Saved Successfully',
  REPORT_SUMMARY: 'Report Summary',
  CRITERIA: 'Criteria',
  RESPONSE: 'Response',
  ISSUE_ID: 'Issue ID',
  ISSUES_TABLE: 'Issues Table',
  SECTION: 'Section',
  CONDITION_RESPONSES: {
    DAMAGED: 'Damaged',
    POOR: 'Poor',
    FAIR: 'Fair',
    GOOD: 'Good',
    GREAT: 'Great'
  },
  CUSTOMISE: 'Customise',
  REPORT_CONFIG: {
    SHOW_SUMMARY: 'Report summary',
    SHOW_ISSUES: 'Issues raised',
    SHOW_FLAGGED: 'Flagged items',
    SHOW_SCORING: 'Scoring',
    SHOW_CO_SIGNATURE: 'Co-signature',
    SHOW_WATERMARK: 'Watermarked images'
  },
  SCORING_SUMMARY: {
    SCORING: 'Scoring',
    ITEM_TYPES: {
      SINGLE_CHOICE: 'Multiple choice',
      'Yes/No': 'Yes/No',
      MULTI_MEDIA: 'Media',
      CONDITION: 'Condition',
      TEXT: 'Text',
      MULTIPLE_CHOICE: 'Multiple choice - multiple response'
    },
    TABLE: {
      NUMBER: 'No.',
      SECTION: 'Section',
      OBTAINED: 'Obtained',
      POSSIBLE: 'Possible',
      SCORE: 'Score',
      TOTAL: 'Total'
    }
  },
  FLAGGED_ITEMS_ACCORDION_SUMMARY: 'Flagged items',
  SECTION_NAME: 'Section',
  NO_FLAGGED_ITEMS_CREATED: 'No flagged items'
};

export default enJSON;
