import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { alpha, Box, lighten, styled, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import useAuth from 'src/hooks/useAuth';
import { CreateCheckFormModal } from '../../components/CreateCheckFormModal';
import { useStores } from '../../hooks/useMobxStores';
import { FlaggedOption } from '../../models/unit';
import { useReports } from '../../hooks/useReports';
import { useTranslation } from 'react-i18next';
import { LoadingState } from '../../axios/types';
import { httpGet } from '../../axios/axiosUtils';
import { clients as clientsUrl } from '../../axios/requests';
import { Client } from '../../models/client';
import useRefMounted from '../../hooks/useRefMounted';
// const formRef: any = createRef();
// const {
//     backend: { templateStore }
// } = useStores();

interface ExtendedSidebarLayoutProps {
  children?: ReactNode;
}

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  minWidth: 300,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(0.5)
}));

const ModalBoxHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.2)
}));

const ExtendedSidebarLayout: FC<ExtendedSidebarLayoutProps> = () => {
  const theme = useTheme();
  const { user, isAuthenticated } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const {
    backend: { reportStore: reportStore }
  } = useStores();
  const [freeReportsCount, setFreeReportsCount] = useState(
    reportStore.freeReportsCount
  );
  const { getReports, getFreeReportsCount } = useReports();
  const showSidebar = isAuthenticated;
  const { t } = useTranslation();
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [clientState, setClientState] = useState<LoadingState>(
    LoadingState.IDLE
  );
  const isMountedRef = useRefMounted();
  let isFreeUser = user.SubscriptionType === 'FREE';
  const [isLoading, setIsLoading] = useState(true);
  const [freeReportCount, setFreeReportCount] = useState<number | null>(null);
  const location = useLocation();

  useEffect(() => {
    const count = window.localStorage.getItem('freeReportCount');
    if (count) {
      setFreeReportsCount(parseInt(count));
    }
  }, [location]);

  const handleOpenModal = () => {
    console.log('Test');
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setFreeReportsCount(reportStore.freeReportsCount);
  }, [reportStore.freeReportsCount]);

  const getClient = useCallback(async () => {
    // console.debug("getClient: ",user.client.id)
    try {
      setClientState(LoadingState.LOADING);
      let response;
      if (user && user.client && user.client.id && user.roles[0] === 'Client') {
        response = await httpGet(`${clientsUrl}/${user.client.id}`, false);
        if (isMountedRef.current) {
          setClient(response);
          setClientState(LoadingState.DONE);
        }
      }
    } catch (err) {
      setClientState(LoadingState.FAILED);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  return (
    <>
      {typeof freeReportsCount === undefined ? (
        <></>
      ) : (
        <Box
          sx={{
            flex: 1,
            height: '100%',
            label: 'test123456789',

            '.MuiPageTitle-wrapper': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.colors.alpha.trueWhite[5]
                  : theme.colors.alpha.white[50],
              marginBottom: `${theme.spacing(4)}`,
              boxShadow:
                theme.palette.mode === 'dark'
                  ? '0 1px 0 ' +
                    alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
                    ', 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)'
                  : '0px 2px 4px -3px ' +
                    alpha(theme.colors.alpha.black[100], 0.1) +
                    ', 0px 5px 12px -4px ' +
                    alpha(theme.colors.alpha.black[100], 0.05)
            }
          }}
        >
          <Header showSidebar={showSidebar} />
          {showSidebar && <Sidebar handleOpenModal={handleOpenModal} />}
          <Box
            id="root-container"
            sx={{
              // label: 'Label987654432',

              position: 'relative',
              zIndex: 5,
              display: 'block',
              flex: 1,
              pt: 2,
              pb: 2,
              height: `calc(100% - ${theme.header.height} - ${theme.spacing(
                2
              )})`,
              ...(showSidebar && {
                [theme.breakpoints.up('lg')]: {
                  ml: `${theme.sidebar.width}`,
                  width: `calc(100% - ${theme.sidebar.width} - ${theme.spacing(
                    2
                  )})`
                }
              }),
              backgroundColor: theme.palette.background.paper,
              marginTop: theme.header.height,
              borderRadius: 1,
              overflowY: 'auto',
              scrollbarGutter: 'stable',
              '&.root-container--transparent': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <Box
              display="block"
              sx={{
                label: 'Label987654432'

                // position: 'relative',
                // zIndex: 5,
                // display: 'block',
                // flex: 1,
                // pt: 20,
                // pb: 2,
                // height: `calc(100% - ${theme.header.height} - ${theme.spacing(2)})`,
                // ...(showSidebar && {
                //     [theme.breakpoints.up('lg')]: {
                //         ml: `${theme.sidebar.width}`,
                //         width: `calc(100% - ${theme.sidebar.width} - ${theme.spacing(2)})`,
                //     }
                // }),
                // backgroundColor: theme.palette.background.paper,
                // marginTop: theme.header.height,
                // borderRadius: 1,
                // overflowY: 'auto'
                // maxHeight:'1250px'
              }}
            >
              <Outlet />
            </Box>
          </Box>
          {isFreeUser ? (
            <Box
              id="subscriptions-banner"
              style={{
                position: 'absolute',
                bottom: '0px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                maxWidth: '616px',
                height: '60px',
                color: '#fff',
                zIndex: 9998
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '0',
                  borderRadius: '6px 6px 0px 0px',
                  backgroundColor: '#14142b',
                  width: '100%',
                  height: '60px'
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '2%',
                  transform: 'translateY(-50%)',
                  textAlign: 'right',
                  width: '46%'
                }}
              >
                <span>{t(`YOU_HAVE`)}</span>
                <span style={{ fontWeight: '600' }}>
                  {freeReportsCount} {t('free')}{' '}
                </span>
                <span> {t('REPORTS_LEFT_THIS_MONTH')}</span>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: '#fff',
                  width: '1px',
                  height: '40px'
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '54%',
                  transform: 'translateY(-50%)',
                  width: '44%',
                  lineHeight: '15px'
                }}
              >
                <a
                  href="https://checkfirst.ai/pricing"
                  style={{
                    textDecoration: 'underline',
                    fontWeight: '600',
                    color: '#fff'
                  }}
                >
                  {t('UPGRADE')}
                </a>
                <span> {t('FOR_UNLIMITED_ACCESS')}</span>
              </div>
            </Box>
          ) : (
            ''
          )}
        </Box>
      )}

      <CreateCheckFormModal
        open={openModal}
        handleClose={handleCloseModal}
        selectedToken={null}
      />
    </>
  );
};

export default ExtendedSidebarLayout;
