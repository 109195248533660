import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, LanguageText, Props } from './types';
import { useStores } from 'src/hooks/useMobxStores';
import LanguageIcon from '@mui/icons-material/Language';
import { Menu } from '@mui/material';
import { IconButton } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { Typography } from '@material-ui/core';

export const TranslationDropdown = ({
  handleLanguageChanges,
  defaultLanguage,
  isMobile
}: Props) => {
  const [language, setLanguage] = useState<Language | undefined>(
    defaultLanguage || undefined
  );
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (lang: Language) => {
    setLanguage(lang);
    handleClose();
  };

  useEffect(() => {
    if (language) {
      handleLanguageChanges(language);
    }
  }, [language]);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ minWidth: isMobile && 'fit-content' }}
      >
        <LanguageIcon color="primary" />
        {!isMobile && (
          <Box paddingX={1}>{LanguageText[language || i18n.language]}</Box>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        onChange={handleChange as any}
      >
        {Object.values(Language).map((lang) => (
          <MenuItem key={lang} onClick={() => handleChange(lang)} value={lang}>
            {LanguageText[lang]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
