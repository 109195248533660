import { styled } from '@mui/material';
import { FormTextField } from 'src/components/FormFields';
import { FormSelect } from 'src/components/FormFields/FormSelect';

export const ValueTextField = styled(FormTextField)`
  ${({ theme }) => `
    max-width: 150px;
  ${theme.breakpoints.down('md')} {
    flex-grow: 2;
    max-width: none;
  }
  `}
`;

export const RuleSelect = styled(FormSelect)`
  min-width: 100px;
  ${({ theme }) => `
  
  ${theme.breakpoints.down('md')} {
    flex-grow: 2;
  }
  `}
`;
