import { TextField, Divider, colors, Zoom, useTheme } from '@mui/material';
import * as S from './styled';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStores } from 'src/hooks/useMobxStores';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { StyledMuiTextField } from '../../styled';

const validationSchema = (t) =>
  Yup.object().shape({
    title: Yup.string().max(255).required(t('TITLE_VALIDATION')),
    description: Yup.string().max(2000).optional().nullable().default('')
  });

type Props = {
  title: string;
  description: string;
  isReadOnly?: boolean;
};

export const HeadingForm: React.FC<Props> = ({
  title,
  description,
  isReadOnly
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    backend: { checklistBuilderStore }
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    title,
    description
  });

  const onSubmit = (values) => {
    const { title, description } = values;
    const request = checklistBuilderStore.updateUnitChecklist({
      title,
      description
    });
    request.then(() => {
      enqueueSnackbar(t('CHECKLIST_BUILDER.SAVED_MESSAGE'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
      checklistBuilderStore.getChecklist();
      setFormValues(values);
    });
  };

  useEffect(() => {
    setFormValues({
      title,
      description
    });
  }, [title, description]);

  return (
    <Formik
      validateOnBlur
      enableReinitialize
      initialValues={formValues}
      validationSchema={validationSchema(t)}
      onSubmit={onSubmit}
    >
      <S.HeadingContainer>
        <S.Title
          required
          placeholder={t('CHECKLIST_BUILDER.TITLE_PLACEHOLDER')}
          name="title"
          disabled={isReadOnly}
        />
        <Divider
          orientation="horizontal"
          sx={{ backgroundColor: theme.borders.color }}
        />
        <StyledMuiTextField
          multiline
          placeholder={t('CHECKLIST_BUILDER.DESCRIPTION_PLACEHOLDER')}
          maxRows={2}
          rows={2}
          name="description"
          inputProps={{ maxLength: 2000 }}
          disabled={isReadOnly}
        />
      </S.HeadingContainer>
    </Formik>
  );
};
