export enum Language {
  pt = 'pt',
  en = 'en',
  fr = 'fr',
  de = 'de',
  zh = 'zh', // Chines
  sp = 'es',
  original = 'Original'
}
export const LanguageText = {
  [Language.pt]: 'Português ',
  [Language.en]: 'English',
  [Language.fr]: 'Français',
  [Language.de]: 'Deutsch',
  [Language.zh]: '中文 ', // Chines
  [Language.sp]: 'Español'
};

export interface Props {
  handleLanguageChanges: (lang: Language) => void;
  defaultLanguage: Language;
  isMobile?: boolean;
}
