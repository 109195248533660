import { Fragment, useState } from 'react';
import { Box, Menu, MenuItem, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as S from './styled';
interface MenuItemProps {
  items: Array<{
    title: string;
    Icon: JSX.Element;
    onClick: () => void;
  }>;
  onMenuClick?: () => void;
}

export const ActionsMenu = ({ items, onMenuClick }: MenuItemProps) => {
  const [contextMenuArchorEl, setContextMenuArchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(contextMenuArchorEl);
  return (
    <Fragment>
      <S.StyledIconButton
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          onMenuClick?.();
          setContextMenuArchorEl(event.currentTarget);
        }}
      >
        <S.StyledMoreVertIcon />
      </S.StyledIconButton>

      <S.StyledActionMenu
        id="basic-menu"
        anchorEl={contextMenuArchorEl}
        open={open}
        onClose={() => setContextMenuArchorEl(null)}
        onBackdropClick={(event) => {
          event.stopPropagation();
          setContextMenuArchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {items.map(({ Icon, onClick, title }, index) => (
          <S.StyledActionMenuItem
            key={index}
            onClick={(e) => {
              onClick();
              setContextMenuArchorEl(null);
              e.stopPropagation();
            }}
          >
            <Box display="flex" alignItems="center">
              {Icon}
              <Box padding={0.3} />
              {title}
            </Box>
          </S.StyledActionMenuItem>
        ))}
      </S.StyledActionMenu>
    </Fragment>
  );
};
