import { Grid, useTheme, Accordion, Zoom, Divider } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import IconContainer from 'src/components/IconContainer';
import {
  ChecklistItemCategory,
  ChecklistItemType,
  ViewChecklistItem
} from 'src/models/unit';
import DraggableList from 'src/components/DraggableList';
import { reorder } from 'src/helpers/Array';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import Criteria from './components/Criteria';
import { AccordionExpandMoreIcon } from 'src/components/AccordionSummary';
import * as S from './styled';
import { FormTextField } from 'src/components/FormFields';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import ConfirmationDialog from 'src/components/Dialogs/confirmationDialog';
import { BorderBox, StyledMuiTextField } from '../../styled';
import { ActionButtons } from '../ActionButtons';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { useStores } from 'src/hooks/useMobxStores';
import { useChecklistStore } from 'src/modules/ChecklistBuilder/hooks/useChecklistStore';

interface Props {
  checklistItems: ViewChecklistItem[];
  isExpanded?: boolean;
  isReadOnly?: boolean;
  currentCatgories: string[];
  category: ChecklistItemCategory;
  dragHandleProps: any;
  ref: any;
  active: boolean;
  onClick: () => void;
  onToggle: (expanded: boolean) => void;
  onDeleteConfirmed: () => void;
}

const validationSchema = (t) =>
  Yup.object().shape({
    title: Yup.string()
      .max(255)
      .required(t('CHECKLIST_BUILDER.SECTION.ERRORS.REQUIRED'))
  });

export const Section: FC<Props> = observer(
  forwardRef(
    (
      {
        currentCatgories,
        isExpanded = false,
        checklistItems,
        category,
        dragHandleProps,
        active,
        onClick,
        onDeleteConfirmed,
        onToggle,
        isReadOnly
      },
      ref
    ) => {
      const inputRef = useRef();
      const {
        t
      }: {
        t: any;
      } = useTranslation();
      const theme = useTheme();
      const containerRef = useRef();
      const [activeDraggableId, setActiveDraggableId] = useState(undefined);
      const [activeCriteria, setActiveCriteria] = useState<any>({});

      const checklistBuilderStore = useChecklistStore();
      const { enqueueSnackbar } = useSnackbar();
      const [highestOrder, setHighestOrder] = useState<number>();
      const [showDeleteDialog, setShowDeleteDialog] = useState(false);
      const initialValues = {
        title: category?.name,
        submit: null
      };
      const handleSuccess = () => {
        enqueueSnackbar(t('CHECKLIST_BUILDER.SAVED_MESSAGE'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          TransitionComponent: Zoom,
          autoHideDuration: 2000
        });
      };

      const onChecklistItemsDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return;
        const orderedCheckListItem = reorder(
          checklistItems,
          source.index,
          destination.index
        ).map((item, index) => ({
          ...item,
          order: index
        }));

        const request = checklistBuilderStore.reorderChecklistItems(
          orderedCheckListItem,
          undefined,
          {
            categoryId: category.id
          }
        );
        request.then(handleSuccess);
      };

      const onSubmit = (_values) => {
        const payload = {
          name: _values.title.trim(),
          order: category.order,
          isDeleted: false
        };
        const request = checklistBuilderStore.updateCategory(payload, {
          categoryId: category.id
        });
        request.then(handleSuccess);
      };

      const onDelete = () => {
        const request = checklistBuilderStore.deleteCategory({
          categoryId: category.id
        });

        request.then(() => {
          handleSuccess();
          setShowDeleteDialog(false);
          onDeleteConfirmed();
        });
      };

      const addNewCriteria = (order = highestOrder) => {
        const payload = {
          title: ' ', //TODO: need to change backend validation for adding a new section
          isDeleted: false,
          type: ChecklistItemType.MULTIPLE_CHOICE,
          mandatory: true,
          checklistItemOptions: [
            { id: null, isDeleted: false, order: 0, title: 'Yes' },
            { id: null, isDeleted: false, order: 1, title: 'No' },
            { id: null, isDeleted: false, order: 2, title: 'N/A' }
          ],
          order: order + 1
        };
        setHighestOrder(highestOrder + 1);
        const request = checklistBuilderStore.addChecklistItem(
          payload,
          undefined,
          {
            categoryId: category.id
          }
        );
        request.then(({ id, order }) => {
          onToggle(true);
          setActiveCriteria({ id, order });
          handleSuccess();
        });
      };

      useEffect(() => {
        const currentOrders = checklistItems.length
          ? checklistItems.map(({ order }) => order || 0)
          : [-1];
        setHighestOrder(Math.max(...currentOrders));
      }, []);

      useImperativeHandle(ref, () => ({
        //TODO: refactor this solution using rxjs subject and implementing it as eventbus
        addCriteria(addLastItem: boolean = false) {
          addNewCriteria(addLastItem ? highestOrder : activeCriteria.order);
        },
        deleteSection() {
          setShowDeleteDialog(true);
        },
        element: containerRef
      }));

      useEffect(() => {
        if (active && inputRef?.current && checklistItems?.length == 0) {
          (inputRef.current as any).focus();
          // (inputRef.current as any).scrollIntoView();
        }
      }, [active, inputRef.current]);

      console.log('active', category.name, activeCriteria);

      return (
        <>
          <S.StyledSectionContainer ref={containerRef} onClick={onClick}>
            <Accordion
              defaultExpanded={isExpanded}
              expanded={isExpanded}
              onChange={(e, state) => onToggle(state)}
            >
              <BorderBox>
                <S.StyledAccordionSummary {...dragHandleProps}>
                  <Grid container justifyContent="space-between">
                    <S.StyledTitle variant="h5">
                      <DragIndicatorIcon />
                      <AccordionExpandMoreIcon />
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema(t)}
                        onSubmit={onSubmit}
                      >
                        {({ values }) => (
                          <S.StyledSectionTitle
                            name="title"
                            placeholder={t(
                              'CHECKLIST_BUILDER.SECTION.TITLE_PLACEHOLDER'
                            )}
                            onClick={(e) => {
                              onClick();
                              e.stopPropagation();
                            }}
                            onEnterKeyDown={() => addNewCriteria(-1)}
                            trim={true}
                            inputRef={inputRef}
                            disabled={isReadOnly}
                          />
                        )}
                      </Formik>
                    </S.StyledTitle>
                  </Grid>
                </S.StyledAccordionSummary>
              </BorderBox>

              {isExpanded && (
                <S.StyledAccordionDetails>
                  <DragDropContext
                    onDragEnd={onChecklistItemsDragEnd}
                    onBeforeDragStart={({ draggableId }) =>
                      setActiveDraggableId(draggableId)
                    }
                  >
                    <Droppable droppableId={`droppable-section-${category.id}`}>
                      {(provided) => {
                        return (
                          <S.CriteriaWrapper ref={provided.innerRef}>
                            <TransitionGroup>
                              {_.sortBy(
                                (checklistItems ?? []).filter(
                                  (e) => !e.isDeleted
                                ),
                                'order'
                              )?.map((item, index) => {
                                return (
                                  <Collapse key={item.id}>
                                    <div>
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id?.toString()}
                                        index={index}
                                        isDragDisabled={isReadOnly}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...(item.id.toString() !=
                                                activeDraggableId && {
                                                style: {
                                                  transition: 'none !important'
                                                }
                                              })}
                                            >
                                              <Criteria
                                                dragHandleProps={
                                                  provided.dragHandleProps
                                                }
                                                item={item}
                                                sectionId={category.id}
                                                highestOrder={highestOrder}
                                                onClick={() => {
                                                  onClick();
                                                  setActiveCriteria({
                                                    order: item.order,
                                                    id: item.id
                                                  });
                                                }}
                                                active={
                                                  active &&
                                                  item.id == activeCriteria.id
                                                }
                                                isLastItem={
                                                  index ==
                                                  checklistItems?.filter(
                                                    (e) => !e.isDeleted
                                                  ).length -
                                                    1
                                                }
                                                isReadOnly={isReadOnly}
                                                onDuplicate={(newItem) => {
                                                  setHighestOrder(
                                                    highestOrder + 1
                                                  );
                                                  setActiveCriteria({
                                                    id: newItem.id,
                                                    order: newItem.order
                                                  });
                                                }}
                                                onDelete={() => {
                                                  const activeIndex =
                                                    checklistItems.findIndex(
                                                      (e) =>
                                                        e.id ==
                                                        activeCriteria.id
                                                    );
                                                  const newActiveCriteria =
                                                    activeIndex - 1 in
                                                    checklistItems
                                                      ? checklistItems[
                                                          activeIndex - 1
                                                        ]
                                                      : checklistItems[1];
                                                  if (newActiveCriteria) {
                                                    setActiveCriteria({
                                                      id: newActiveCriteria.id,
                                                      order:
                                                        newActiveCriteria.order
                                                    });
                                                  }
                                                }}
                                                onEnterKeyDown={() =>
                                                  addNewCriteria(
                                                    activeCriteria.order
                                                  )
                                                }
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    </div>
                                  </Collapse>
                                );
                              })}
                            </TransitionGroup>
                          </S.CriteriaWrapper>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </S.StyledAccordionDetails>
              )}
            </Accordion>
          </S.StyledSectionContainer>
          <ConfirmationDialog
            open={showDeleteDialog}
            onConfirm={onDelete}
            setOpen={(open) => {
              if (!open) {
                setShowDeleteDialog(false);
              }
            }}
            title={t('EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE')}
            subtitle={t(
              'CHECKLIST_BUILDER.SECTION.DELETE_CONFIRMATION_MESSAGE'
            )}
            onCancel={() => setShowDeleteDialog(false)}
            onClose={() => setShowDeleteDialog(false)}
          />
        </>
      );
    }
  )
);
