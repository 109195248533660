import { Divider, IconButton, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const Container = styled('div')`
  ${({ theme }) => `
    transition: top 0.5s ease;
    position: absolute;
    top: 0px;
    border: 2px solid ${theme.borders.color};
    border-radius: 3px 6px 6px 3px;
    padding: ${theme.spacing(2)} ${theme.spacing(1)};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
    background-color: ${theme.colors.alpha.white[100]};
    left: 100%;
    z-index: 10000;

    ${theme.breakpoints.down('md')}{
      position: fixed;
      flex-direction: row;
      top: inherit;
      left: 0;
      bottom: -4px;
      width: calc(100% - ${theme.spacing(6)});
      justify-content: space-around;
      margin-left: ${theme.spacing(3)};
      margin-right: ${theme.spacing(3)};
    }
`}
`;

export const StyledDivider = styled(Divider)<{
  orientation: 'horizontal' | 'vertical';
}>`
  ${({ orientation }) =>
    orientation == 'vertical' ? 'width: 2px;' : 'height: 2px;'}
  background-color: ${({ theme }) => theme.borders.color};
  ${({ theme }) => theme.breakpoints.down('md')} {
    order: 3;
  }
`;

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.tooltip.secondary.background,
    color: theme.tooltip.secondary.color
  }
}));

export const StyledIconButton = styled(IconButton)<{ $type: string }>(
  ({ theme, $type }) => ({
    svg: {
      fill: theme.icon.secondary.fill,
      width: theme.spacing(3),
      height: theme.spacing(3)
    },
    [theme.breakpoints.down('md')]: {
      order: getOrder($type)
    }
  })
);
const getOrder = (type) => {
  switch (type) {
    case 'ADD_CRITERIA':
      return 1;
    case 'ADD_SECTION':
      return 2;
    case 'DUPLICATE':
      return 4;
    case 'DELETE':
      return 5;
  }
};
