import { LoadingState } from 'src/axios/types';
import {
  CreateChecklistParams,
  UpdateChecklistParams
} from '../../stores/backend/checklist/types';
import { SortType } from '../../models/sort';

export interface CreateChecklistFormModalProps {
  open?: boolean;
  selectedToken?: any;
  setSelectedChecklistId?: any;
  selectedChecklistId?: any;
  selectedUnitId?: any;
  setSelectedTemplateId?: any;
  selectedTemplateId?: any;
  params: {
    checklistId?: number;
    unitId?: number;
  };
  handleClose: () => void;
  handleSelectTemplate: (id: number) => Promise<void>;
}

export enum TemplateSortKey {
  title = 'title',
  createdAt = 'createdAt'
}

export interface TemplateSortParams {
  order: SortType;
  key: TemplateSortKey;
}
