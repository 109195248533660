import { YesNo } from '../components/LogicUI/variants/YesNo';
import { Number } from '../components/LogicUI/variants/Number';
import { Text } from '../components/LogicUI/variants/Text';
import { LogicUIProps } from '../components/LogicUI';
import { ChecklistItemType } from 'src/models/unit';
import {
  ActionTypes,
  MultimediaRules,
  MultipleChoiceRules,
  NumberRules,
  TextRules
} from 'src/models/logic';
import { MultipleChoice } from '../components/LogicUI/variants/MultipleChoice';
import { Multimedia } from '../components/LogicUI/variants/Multimedia';
import { FeatureStatus } from 'src/models/feature.toggle';

type checkListItemTypeValues = `${ChecklistItemType}`;

const allowLogic = process.env.REACT_APP_LOGIC == FeatureStatus.ENABLED;

const commonActions: ActionTypes[] = allowLogic
  ? ['ADD_CRITERIA', 'FLAG_REPORT', 'SCORING']
  : ['FLAG_REPORT'];

const emptyRules: any = ['IS_EMPTY', 'IS_NOT_EMPTY'];

export const LOGIC_MAP: {
  [key in checkListItemTypeValues]?: {
    rules: (MultipleChoiceRules | NumberRules | TextRules | MultimediaRules)[];
    allowedValues?: string[];
    actions: ActionTypes[];
  };
} = {
  'Yes/No': {
    rules: ['IS', 'IS_NOT'].concat(
      ...(allowLogic ? emptyRules : [])
    ) as MultipleChoiceRules[],
    allowedValues: ['YES', 'NO', 'NA'],
    actions: commonActions
  },
  CONDITION: {
    rules: ['IS', 'IS_NOT'].concat(
      ...(allowLogic ? emptyRules : [])
    ) as MultipleChoiceRules[],
    allowedValues: ['GREAT', 'GOOD', 'FAIR', 'POOR', 'DAMAGED'],
    actions: commonActions
  },
  TEXT: {
    rules: [
      'IS',
      'IS_NOT',
      'CONTAINS',
      'DOES_NOT_CONTAIN',
      'STARTS_WITH'
    ].concat(...(allowLogic ? emptyRules : [])) as TextRules[],
    allowedValues: ['YES', 'NO', 'NA'],
    actions: commonActions
  },
  NUMBER: {
    rules: [
      'EQUALS',
      'DOES_NOT_EQUAL',
      'GREATER_THAN',
      'LESS_THAN',
      'GREATER_THAN_OR_EQUAL_TO',
      'LESS_THAN_OR_EQUAL_TO',
      'BETWEEN',
      'NOT_BETWEEN'
    ].concat(...(allowLogic ? emptyRules : [])) as NumberRules[],
    actions: commonActions
  },
  RADIO_BUTTON: {
    rules: ['IS', 'IS_NOT'].concat(
      ...(allowLogic ? emptyRules : [])
    ) as MultipleChoiceRules[],
    actions: commonActions
  },
  CHECK_BOX: {
    rules: ['IS', 'IS_NOT'].concat(
      ...(allowLogic ? emptyRules : [])
    ) as MultipleChoiceRules[],
    actions: commonActions
  },
  MULTIPLE_CHOICE: {
    rules: ['IS', 'IS_NOT'].concat(
      ...(allowLogic ? emptyRules : [])
    ) as MultipleChoiceRules[],
    actions: ['ADD_CRITERIA', 'FLAG_REPORT']
  },
  MULTI_MEDIA: {
    rules: ['CONTAINS', 'DOES_NOT_CONTAIN'],
    actions: ['FLAG_REPORT']
  }
};

export const CHECKLIST_ITEM_TYPE_COMPONENT_MAP: {
  [key in checkListItemTypeValues]?: React.FC<LogicUIProps>;
} = {
  'Yes/No': YesNo,
  MULTI_MEDIA: Multimedia,
  MEDIA: Multimedia,
  CONDITION: YesNo,
  TEXT: Text,
  NUMBER: Number,
  RADIO_BUTTON: MultipleChoice,
  CHECK_BOX: MultipleChoice,
  MULTIPLE_CHOICE: MultipleChoice
};

export interface LogicForm {
  rule: MultipleChoiceRules | NumberRules | TextRules | MultimediaRules;
  values: string | number[];
  action: ActionTypes;
  score: number;
}
export const INITIAL_VALUES_MAP: {
  [key in checkListItemTypeValues]?: LogicForm;
} = {
  'Yes/No': {
    rule: 'IS',
    values: [],
    action: undefined,
    score: undefined
  },
  TEXT: {
    rule: 'IS',
    values: [],
    action: undefined,
    score: undefined
  },
  CONDITION: {
    rule: 'IS',
    values: [],
    action: undefined,
    score: undefined
  },
  NUMBER: {
    rule: 'EQUALS',
    values: [0, 0],
    action: undefined,
    score: undefined
  },
  CHECK_BOX: {
    rule: 'IS',
    values: [],
    action: undefined,
    score: undefined
  },
  RADIO_BUTTON: {
    rule: 'IS',
    values: [],
    action: undefined,
    score: undefined
  },
  MULTIPLE_CHOICE: {
    rule: 'IS',
    values: [],
    action: undefined,
    score: undefined
  },
  MULTI_MEDIA: {
    rule: 'CONTAINS',
    values: [],
    action: undefined,
    score: undefined
  }
};

export const NUMBER_RULE_DISPLAY_VALUE_MAP: { [key in NumberRules]?: string } =
  {
    EQUALS: '=',
    DOES_NOT_EQUAL: '≠',
    GREATER_THAN: '>',
    LESS_THAN: '<',
    GREATER_THAN_OR_EQUAL_TO: '≥',
    LESS_THAN_OR_EQUAL_TO: '≤'
  };
