import { Box, Grid, Typography, styled } from '@mui/material';
import { FormSelect } from 'src/components/FormFields/FormSelect';

export const LogicGrid = styled(Grid)`
  // border-bottom: 1px solid ${({ theme }) => theme.borders.color};
  border-radius: 0px 0px 3px 3px;
  padding-left: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const Container = styled('div')`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)} 0;
  flex-wrap: wrap;
  ${({ theme }) => `
  
  ${theme.breakpoints.down('md')} {
    flex-grow: 2;
  }
  background-color: ${({ theme }) => theme.palette.background.paper};
  `}

  .MuiOutlinedInput-root {
    max-height: ${({ theme }) => theme.spacing(5)};
    min-height: ${({ theme }) => theme.spacing(5)};
  }
`;

export const IfSection = styled('div')`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const Text = styled(Typography)<{ component }>`
  text-wrap: nowrap;
  font-weight: 600;
`;

export const CloseButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing(1.5)};
  path {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  ${({ theme }) => `
  
  ${theme.breakpoints.down('sm')} {
    padding-right: 0;
  }
  `}
`;

export const RuleSelect = styled(FormSelect)`
  min-width: 150px;
  ${({ theme }) => `
  
  ${theme.breakpoints.down('md')} {
    flex-grow: 2;
  }
  `}
`;

export const LeftContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
