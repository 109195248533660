import { LayoutProps } from 'src/modules/types';
import { Header } from './components/Header';
import * as S from './styled';
import { Outlet } from 'react-router';
import { Grid } from '@mui/material';

export const ChecklistBuilderLayout = ({ children }: LayoutProps) => {
  return (
    <S.Container>
      <Header />
      <S.Body id="root-container">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={9}>
            {children || <Outlet />}
          </Grid>
        </Grid>
      </S.Body>
    </S.Container>
  );
};
