import i18next from 'i18next';

import deJSON from './translations/de';
import enJSON from './translations/en';
import esJSON from './translations/es';
import frJSON from './translations/fr';
import ptJSON from './translations/pt';
import zhJSON from './translations/zh';

import enReportJSON from './translations/report/en';
import deReportJSON from './translations/report/de';
import esReportJSON from './translations/report/es';
import frReportJSON from './translations/report/fr';
import ptReportJSON from './translations/report/pt';
import zhReportJSON from './translations/report/zh';

const resources = {
  de: { translation: deJSON },
  en: { translation: enJSON },
  es: { translation: esJSON },
  fr: { translation: frJSON },
  pt: { translation: ptJSON },
  zh: { translation: zhJSON }
};

const reportResources = {
  de: { report_translation: deReportJSON },
  en: { report_translation: enReportJSON },
  es: { report_translation: esReportJSON },
  fr: { report_translation: frReportJSON },
  pt: { report_translation: ptReportJSON },
  zh: { report_translation: zhReportJSON }
};

const universalI18n = i18next.createInstance(
  {
    lng: 'en',
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS: 'translation',
    react: { useSuspense: false },
    interpolation: { escapeValue: false },
    resources
  },
  // We must provide a function as second parameter, otherwise i18next errors
  (err, t) => {
    if (err) return console.log(err);
  }
);

const reportI18n = i18next.createInstance(
  {
    lng: '',
    fallbackLng: '',
    ns: ['report_translation'],
    defaultNS: 'report_translation',
    react: { useSuspense: false },
    interpolation: { escapeValue: false },
    resources: reportResources
  },
  // We must provide a function as second parameter, otherwise i18next errors
  (err, t) => {
    if (err) return console.log(err);
  }
);
export default universalI18n;

export { universalI18n, reportI18n };
