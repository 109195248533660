import { action, makeObservable, observable } from 'mobx';
import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from 'src/axios/axiosUtils';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import type { GetTemplateBase, GetTemplateDto, SaveTemplateDto } from './types';
import {
  templateAddLogic,
  templateCategories,
  templateCategoryItems,
  templateDeleteLogic,
  templatesURL,
  templateUpdateLogic
} from 'src/axios/requests';
import { CategoryDto, Reorder } from '../unit/types';
import type { ChecklistItemCategory, ViewChecklistItem } from 'src/models/unit';
import { ChecklistItemsStore } from '../checklistItems';
import { TemplateSortParams } from 'src/content/dashboards/Checklist/SelectTemplateModal/types';
import type { LogicDto } from 'src/models/logic';
import { PaginationMeta } from 'src/models/pagination';

export class TemplateStore extends BaseBackendStore {
  private checklistItemsStore: ChecklistItemsStore;

  @observable templatesState: LoadingState = LoadingState.IDLE;

  @observable templates: GetTemplateDto[] = [];

  @observable platformTemplates: GetTemplateBase[] = [];

  @observable clientTemplates: GetTemplateBase[] = [];

  @observable template: GetTemplateDto;

  @observable templateState: LoadingState = LoadingState.IDLE;

  @observable saveTemplateState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
    this.checklistItemsStore = new ChecklistItemsStore(rootStore);
  }

  async deleteTemplate(templateId: number) {
    try {
      this.saveTemplateState = LoadingState.LOADING;
      await httpDelete(`${templatesURL}/${templateId}`);
      this.saveTemplateState = LoadingState.DONE;
    } catch {
      this.saveTemplateState = LoadingState.FAILED;
    }
  }

  async getTemplates({ sort }: { sort?: TemplateSortParams }) {
    try {
      let getTemplatesURL = `${templatesURL}`;
      if (sort) {
        getTemplatesURL += `?sort-by=${sort.key}&sort-order=${sort.order}`;
      }
      this.templatesState = LoadingState.LOADING;
      this.templates = await httpGet(getTemplatesURL, false);
      this.clientTemplates = [];
      this.platformTemplates = [];
      this.templates.forEach((t) => {
        if (t.client) {
          this.clientTemplates.push(t);
        } else {
          this.platformTemplates.push(t);
        }
      });
      this.templatesState = LoadingState.DONE;
    } catch {
      this.templatesState = LoadingState.FAILED;
    }
  }

  async getPlatformTemplates() {
    try {
      let getTemplatesURL = `${templatesURL}/platform`;
      this.templatesState = LoadingState.LOADING;
      const result = await httpGet(getTemplatesURL, false);
      this.platformTemplates = result.data;
      this.templatesState = LoadingState.DONE;
    } catch {
      this.templatesState = LoadingState.FAILED;
    }
  }

  async getClientTemplates(
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    page?: number,
    pageSize?: number
  ) {
    try {
      let getTemplatesURL;
      if (page && pageSize) {
        getTemplatesURL = `${templatesURL}/client?page=${page}&take=${pageSize}`;
      } else {
        getTemplatesURL = `${templatesURL}/client`;
      }
      if (sortBy && sortOrder)
        getTemplatesURL += `&sort-by=${sortBy}&sort-order=${sortOrder}`;
      this.templatesState = LoadingState.LOADING;
      const result: {
        data: GetTemplateDto[];
        meta: PaginationMeta;
      } = await httpGet(getTemplatesURL, false);
      this.clientTemplates = result.data;
      this.templatesState = LoadingState.DONE;
      return result;
    } catch {
      this.templatesState = LoadingState.FAILED;
    }
  }

  async getTemplate(templateId: number) {
    try {
      this.templateState = LoadingState.LOADING;
      this.template = await httpGet(`${templatesURL}/${templateId}`, false);
      this.checklistItemsStore.setChecklistItemCategories(
        this.template.checklistItemsCategories
      );
      this.templateState = LoadingState.DONE;
    } catch {
      this.templateState = LoadingState.FAILED;
    }
  }

  async updateTemplate(id: number, template: SaveTemplateDto) {
    try {
      this.saveTemplateState = LoadingState.LOADING;
      await httpPut(`${templatesURL}/${id}`, template);
      this.saveTemplateState = LoadingState.DONE;
    } catch {
      this.saveTemplateState = LoadingState.FAILED;
    }
  }

  async createTemplate(template: SaveTemplateDto) {
    try {
      this.saveTemplateState = LoadingState.LOADING;
      const result = await httpPost(`${templatesURL}`, template);
      this.saveTemplateState = LoadingState.DONE;
      return result;
    } catch {
      this.saveTemplateState = LoadingState.FAILED;
    }
  }

  async duplicateTemplate(template: SaveTemplateDto) {
    try {
      this.saveTemplateState = LoadingState.LOADING;
      await httpPost(`${templatesURL}`, template);
      this.saveTemplateState = LoadingState.DONE;
    } catch {
      this.saveTemplateState = LoadingState.FAILED;
    }
  }

  /**
   *
   * @param templateId
   * @param payload
   * @description
   *  - create category
   *  - insert it locally at the beginning of the list
   *  - reorder current categories
   */
  async addNewCategory(templateId: number, payload: CategoryDto) {
    const createdCategory: ChecklistItemCategory = await httpPost(
      templateCategories(templateId),
      payload
    );
    this.template.checklistItemsCategories =
      this.checklistItemsStore.addNewCategory(createdCategory);
    return createdCategory;
  }

  async updateCategory(
    templateId: number,
    categoryId: number,
    payload: CategoryDto
  ) {
    await httpPut(`${templateCategories(templateId)}/${categoryId}`, payload);
    this.template.checklistItemsCategories =
      this.checklistItemsStore.updateCategory(categoryId, payload);
  }

  async deleteCategory(templateId: number, categoryId: number) {
    await httpDelete(`${templateCategories(templateId)}/${categoryId}`);
    this.template.checklistItemsCategories =
      this.checklistItemsStore.deleteCategory(categoryId);
  }

  async reorderCategories(
    templateId: number,
    categories: ChecklistItemCategory[]
  ) {
    const fallbackCategoriesOrder = [...this.template.checklistItemsCategories];
    try {
      this.template.checklistItemsCategories =
        this.checklistItemsStore.reorderCategories(categories);
      const ordered = this.template.checklistItemsCategories.map(
        ({ id, order }) => ({
          id,
          order
        })
      );
      await httpPatch(templateCategories(templateId), ordered);
    } catch (error) {
      this.template.checklistItemsCategories = fallbackCategoriesOrder;
    }
  }

  /** Checklist items */
  @action
  async deleteChecklistItem(
    templateId: number,
    categoryId: number,
    id: number,
    logicState?: LogicDto
  ) {
    this.template.checklistItemsCategories =
      this.checklistItemsStore.deleteChecklistItem(categoryId, id, logicState);

    await httpDelete(`${templateCategoryItems(templateId, categoryId)}/${id}`);
  }

  @action
  async updateChecklistItem(
    templateId: number,
    categoryId: number,
    id: number,
    checklistItem: ViewChecklistItem,
    checklistItemState?: ViewChecklistItem
  ) {
    const result = await httpPut(
      `${templateCategoryItems(templateId, categoryId)}/${id}`,
      checklistItem
    );

    this.template.checklistItemsCategories =
      this.checklistItemsStore.updateChecklistItem(
        categoryId,
        id,
        result,
        checklistItemState
      );
    return result;
  }

  @action
  async addChecklistItem(
    templateId: number,
    categoryId: number,
    item: ViewChecklistItem,
    logicState?: LogicDto
  ) {
    const { subLogics, ...itemToAdd } = item;
    const { id, order }: ViewChecklistItem = await httpPost(
      templateCategoryItems(templateId, categoryId),
      itemToAdd,
      {
        headers: {
          logicId: logicState?.id.toString()
        }
      }
    );
    this.template.checklistItemsCategories =
      this.checklistItemsStore.addChecklistItem(
        categoryId,
        { ...item, id },
        logicState
      );
    return { id, order };
  }

  @action
  async duplicateChecklistItem(
    templateId: number,
    categoryId: number,
    item: ViewChecklistItem,
    logicState?: LogicDto
  ) {
    const { subLogics, ...itemToAdd } = item;
    const result: ViewChecklistItem = await httpPost(
      templateCategoryItems(templateId, categoryId),
      itemToAdd,
      {
        headers: {
          logicId: logicState?.id.toString()
        }
      }
    );
    this.template.checklistItemsCategories =
      this.checklistItemsStore.addChecklistItem(
        categoryId,
        { ...result },
        logicState
      );
    return result;
  }

  @action
  async reorderChecklistItems(
    templateId: number,
    categoryId: number,
    checklistItems: Array<ViewChecklistItem>,
    logicState?: LogicDto
  ) {
    const categoryIndex = this.template.checklistItemsCategories.findIndex(
      (cat) => cat.id === categoryId
    );
    const fallbackChecklistItems = [
      ...this.template.checklistItemsCategories[categoryIndex].checklistItems
    ];
    try {
      this.template.checklistItemsCategories =
        this.checklistItemsStore.reorderChecklistItems(
          categoryId,
          checklistItems,
          logicState
        );

      const checklistItemsOrdered: Reorder[] = (
        logicState
          ? logicState.subChecklistItems
          : this.template.checklistItemsCategories[categoryIndex].checklistItems
      ).map(({ order, id }) => ({ order, id }));
      await httpPatch(
        templateCategoryItems(templateId, categoryId),
        checklistItemsOrdered
      );
    } catch (error) {
      this.template.checklistItemsCategories[categoryIndex] = {
        ...this.template.checklistItemsCategories[categoryIndex],
        checklistItems: fallbackChecklistItems
      };
    }
  }

  /**Logics */

  @action
  async addLogic(
    checklistItemState: ViewChecklistItem,
    item: LogicDto,
    templateId: number
  ) {
    const { id }: LogicDto = await httpPost(
      templateAddLogic(templateId, checklistItemState.id),
      item
    );
    this.checklistItemsStore.addLogic(checklistItemState, {
      ...item,
      id
    });
  }

  @action
  async updateLogic(
    logicState: LogicDto,
    newLogic: LogicDto,
    templateId: number
  ) {
    await httpPut(templateUpdateLogic(templateId, logicState.id), newLogic);
    this.checklistItemsStore.updateLogic(logicState, newLogic);
  }

  @action
  async deleteLogic(
    checklistItemState: ViewChecklistItem,
    logicId: number,
    templateId: number
  ) {
    await httpDelete(templateDeleteLogic(templateId, logicId));
    this.checklistItemsStore.deleteLogic(checklistItemState, logicId);
  }
}
