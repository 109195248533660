import instance from 'src/axios/axios';
import { User } from 'src/content/dashboards/Users/types';

export const setSession = (
  accessToken: string | null,
  refreshToken: string | null,
  user: User | null
): void => {
  if (accessToken) {
    // console.log('accessToken getting called', accessToken)
    const localUser = window.localStorage.getItem('user');
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    window.localStorage.setItem('user', JSON.stringify(user));
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    instance.defaults.headers.common.RefreshAuthorization = `Bearer ${refreshToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    delete instance.defaults.headers.common.Authorization;
    delete instance.defaults.headers.common.RefreshAuthorization;
  }
};
