import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import ClientRole from 'src/content/dashboards/Clients/clientRole';
import ParentComponentRouter from 'src/router/ParentComponentRouter';
import UnitForm from 'src/content/dashboards/Units/UnitForm';
import { NavigateToChecklistBuilder } from 'src/modules/ChecklistBuilder/components/NavigateToChecklistBuilder';
import { Loader } from 'src/components/Loader';

// Dashboards

const Units = Loader(lazy(() => import('src/content/dashboards/Units')));
const UnitDetails = Loader(
  lazy(() => import('src/content/dashboards/Units/UnitDetails'))
);
const Reports = Loader(lazy(() => import('src/content/dashboards/reports')));
const Report = Loader(
  lazy(() => import('src/content/dashboards/reports/report'))
);
const Clients = Loader(lazy(() => import('src/content/dashboards/Clients')));
const ClientsConfigurations = Loader(
  lazy(() => import('src/content/dashboards/Clients-Configurations'))
);
const ClientConfigurations = Loader(
  lazy(() => import('src/content/dashboards/Clients/Cleint-Configurations'))
);
const ClientConfigurationsForm = Loader(
  lazy(
    () => import('src/content/dashboards/Clients/Cleint-Configurations-form')
  )
);
const IssuesScreen = Loader(
  lazy(() => import('src/content/dashboards/Issues'))
);
const Tokens = Loader(lazy(() => import('src/content/dashboards/Tokens')));

const ApiKeys = Loader(lazy(() => import('src/content/dashboards/ApiKeys')));

const ClientUsersScreen = Loader(
  lazy(() => import('src/content/dashboards/Users/ClientUsers'))
);

const UpdateClientProfileScreen = Loader(
  lazy(() => import('src/content/dashboards/Clients/updateClientForm'))
);

const ClientsActivitiesScreen = Loader(
  lazy(() => import('src/content/dashboards/Clients-Activities'))
);

const StatisticsScreen = Loader(
  lazy(() => import('src/content/dashboards/Statistics'))
);

const IssuesDetailsScreen = Loader(
  lazy(() => import('src/content/dashboards/IssueDetails'))
);

const UpdateUserForm = Loader(
  lazy(() => import('src/content/dashboards/Users/UpdateUserForm'))
);

const UpdateUserPasswordForm = Loader(
  lazy(() => import('src/content/dashboards/Users/UpdateUserPasswordForm'))
);

const HooksList = Loader(
  lazy(() => import('src/content/dashboards/hooks/list'))
);

const HooksForm = Loader(
  lazy(() => import('src/content/dashboards/hooks/save'))
);

const HookDetails = Loader(
  lazy(() => import('src/content/dashboards/hooks/details'))
);

const TemplateListScreen = Loader(
  lazy(() => import('src/content/dashboards/Templates/Page'))
);

const ChecksScreen = Loader(
  lazy(() => import('src/content/dashboards/Checks'))
);

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <StatisticsScreen />
  },
  {
    path: 'checklists',
    element: <TemplateListScreen />
  },
  /**@deprecated route, kept only for bookmarks */
  {
    path: 'templates',
    element: <Navigate to="../checklists" replace />
  },
  /**@deprecated route, kept only for bookmarks */
  {
    path: 'templates/:templateId/checklist-items',
    element: <NavigateToChecklistBuilder />
  },
  {
    path: 'checklists/:templateId/checklist-items',
    element: <NavigateToChecklistBuilder />
  },
  {
    path: 'units',
    element: <ParentComponentRouter />,
    children: [
      {
        path: '/',
        element: <Units />
      },
      {
        path: ':unitId',
        element: <UnitDetails />
      },
      {
        path: '/add',
        element: <UnitForm />
      },
      {
        path: ':unitId/edit',
        element: <UnitForm />
      },
      {
        path: ':unitId/reports/',
        element: <Reports />
      },
      {
        path: ':unitId/reports/:reportId',
        element: <Report />
      },
      {
        path: ':unitId/checklists/:checklistId',
        element: <NavigateToChecklistBuilder />
      }
    ]
  },
  {
    path: 'checklists/:checklistId/tokens',
    element: <Tokens />
  },
  {
    path: 'users',
    element: <ParentComponentRouter />,
    children: [
      {
        path: ':userId/updateProfile',
        element: <UpdateUserForm />
      },
      {
        path: ':userId/updatePassword',
        element: <UpdateUserPasswordForm />
      }
    ]
  },
  {
    path: 'clients',
    element: <ParentComponentRouter />,
    children: [
      {
        path: '/',
        element: (
          <ClientRole>
            {' '}
            <Clients />
          </ClientRole>
        )
      },
      {
        path: ':clientId/updateProfile',
        element: <UpdateClientProfileScreen />
      },
      {
        path: ':clientId/configurations',
        element: (
          <ClientRole>
            <ClientConfigurations />
          </ClientRole>
        )
      },
      {
        path: ':clientId/configurations/form',
        element: (
          <ClientRole>
            <ClientConfigurationsForm />
          </ClientRole>
        )
      },
      {
        path: ':clientId/configurations/form/:key',
        element: (
          <ClientRole>
            <ClientConfigurationsForm />
          </ClientRole>
        )
      },
      {
        path: ':clientId/users/:userId/updateProfile',
        element: (
          <ClientRole>
            <UpdateUserForm />
          </ClientRole>
        )
      },
      {
        path: ':clientId/reports',
        element: <Reports />
      },
      {
        path: ':clientId/reports/:reportId',
        element: <Report />
      },
      {
        path: ':clientId/api-keys',
        element: <ApiKeys />
      },
      {
        path: ':clientId/users',
        element: <ClientUsersScreen />
      },
      {
        path: ':clientId/checks',
        element: <ChecksScreen />
      }
    ]
  },
  // {
  //   path: 'payment',
  //   children: [
  //     {
  //       path: 'prices',
  //       element: (
  //         <AllowIfNotSubscribedToPlan>
  //           <PaymentPricesScreen />
  //         </AllowIfNotSubscribedToPlan>
  //       )
  //     },
  //     {
  //       path: 'subscriptions/:subscriptionId',
  //       element: (
  //         <AllowIfNotSubscribedToActivePlan>
  //           <PaymentSubscriptionScreen />
  //         </AllowIfNotSubscribedToActivePlan>
  //       )
  //     },
  //     {
  //       path: 'success',
  //       element: <PaymentSuccessScreen />
  //     },
  //     {
  //       path: 'failure',
  //       element: <PaymentFailureScreen />
  //     }
  //   ]
  // },
  {
    path: 'clients-activities',
    element: <ClientsActivitiesScreen />
  },
  {
    path: 'hooks',
    element: <ParentComponentRouter />,
    children: [
      {
        path: '/',
        element: <HooksList />
      },
      {
        path: 'add',
        element: <HooksForm />
      },
      {
        path: ':id',
        element: <HookDetails />
      },
      {
        path: ':id/edit',
        element: <HooksForm />
      }
    ]
  },
  {
    path: 'issues',
    element: <ParentComponentRouter />,
    children: [
      {
        path: '/',
        element: <IssuesScreen />
      },
      {
        path: ':id',
        element: <IssuesDetailsScreen />
      }
    ]
  },
  {
    path: 'configurations',
    element: <ClientsConfigurations />
  }
];

export default dashboardsRoutes;
