import * as Yup from 'yup';
import { FC, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Button,
  TextField,
  CircularProgress,
  Zoom,
  Link,
  Box,
  useTheme,
  Card,
  CardHeader,
  Typography
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import {
  FloatFormContainer,
  CheckFirstFormLogo
} from 'src/components/StyledForm';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { emitter } from 'src/axios/axios';
import * as S from './styled';

const params = new URLSearchParams(window.location.search);

const UpdateClientCompany: FC = () => {
  const { userVerification, createUserClient, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      userVerificationAuth();
    }
  }, [token]);

  const userVerificationAuth = async () => {
    try {
      await userVerification(token);
    } catch (error) {
      // enqueueSnackbar(error?.response?.data?.message || t('ERROR'), {
      //   variant: 'error',
      //   autoHideDuration: 3000,
      //   anchorOrigin: {
      //     vertical: 'bottom',
      //     horizontal: 'right'
      //   },
      //   TransitionComponent: Zoom
      // });
      // emitter.emit('Error', error?.response?.data?.message || t('ERROR'))
      console.log(error);
    }
  };

  const registerClientCompany = async (companyName: string) => {
    try {
      await createUserClient(companyName);
      navigate('/checkfirst');
    } catch (err) {
      // enqueueSnackbar(err?.response?.data?.message || t('ERROR'), {
      //   variant: 'error',
      //   autoHideDuration: 3000,
      //   anchorOrigin: {
      //     vertical: 'bottom',
      //     horizontal: 'right'
      //   },
      //   TransitionComponent: Zoom
      // });
      // emitter.emit('Error', err?.response?.data?.message || t('ERROR'));
      console.log(err);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
      ></Box>
      <FloatFormContainer maxWidth="sm">
        <CheckFirstFormLogo
          alt="Logo."
          src="/static/images/logo/checkfirst-Icon-logo-dark.png"
        />
        <Card sx={{ padding: 2 }}>
          <CardHeader
            title={t('UPDATE_COMPANY_HEADER')}
            subheader={t('UPDATE_COMPANY_INFO')}
            subheaderTypographyProps={{
              color: `${theme.colors.primary.main}80`,
              marginTop: 0.5
            }}
          />
          <Formik
            initialValues={{
              companyName: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              companyName: Yup.string()
                .max(255)
                .required(t('COMPANY_VALIDATION'))
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
              await registerClientCompany(values.companyName);
              setStatus({ success: true });
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }): JSX.Element => (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  margin="normal"
                  autoFocus
                  helperText={touched.companyName && errors.companyName}
                  label={t('COMPANY_LABEL')}
                  name="companyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.companyName}
                />
                <Button
                  sx={{
                    mt: 3
                  }}
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {t('UPDATE')}
                </Button>
              </form>
            )}
          </Formik>
        </Card>
      </FloatFormContainer>
    </>
  );
};

export default UpdateClientCompany;
