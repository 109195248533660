/**
 * @deprecated
 */
export type UserRole = 'admin' | 'customer' | 'subscriber';

export interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  username: string;
  client: {
    id: number;
    imageURL: string;
    name?: string;
    clientSubscription?: ClientSubscription;
    freeReportsPerMonth?: number;
  };
  roles: string[];
  rolesPermissions: RolePermission[];
  SubscriptionType?: string;
  freeReportsPerMonth?: number;
}

export interface RolePermission {
  permission: PermissionKey;
  level: PermissionLevel;
}

export enum PermissionLevel {
  ALL = 'ALL',
  GROUP = 'GROUP',
  OWN = 'OWN'
}
export interface ClientSubscription {
  id?: number;
  stripeSubscriptionId: string;
  stripeCustomerId: string;
  cancelAt: Date;
  product: {
    stripeProductId: string;
    productId: string;
  } | null;
}

export enum UserRoles {
  ADMIN = 'admin',
  CLIENT = 'client',
  CLIENT_MANAGER = 'client_manager',
  CLIENT_CONTRIBUTOR = 'client_contributor',
  CLIENT_USER = 'client_user'
}

export enum PermissionKey {
  GET_UNITS = 'GET_UNITS',
  GET_UNIT = 'GET_UNIT',
  CREATE_UNIT = 'CREATE_UNIT',
  UPDATE_UNIT = 'UPDATE_UNIT',
  DELETE_UNIT = 'DELETE_UNIT',
  DELETE_UNIT_FLAGS = 'DELETE_UNIT_FLAGS',
  DUPLICATE_UNIT = 'DUPLICATE_UNIT',
  GET_INSPECTION_LINKS = 'GET_INSPECTION_LINKS',
  CREATE_INSPECTION_LINKS = 'CREATE_INSPECTION_LINKS',
  UPDATE_INSPECTION_LINKS = 'UPDATE_INSPECTION_LINKS',
  GET_REPORTS = 'GET_REPORTS',
  GET_REPORT = 'GET_REPORT',
  CREATE_REPORT_TOKEN = 'CREATE_REPORT_TOKEN',
  GET_REPORT_ISSUES = 'GET_REPORT_ISSUES',
  GET_REPORT_CREATOR_INFO = 'GET_REPORT_CREATOR_INFO',
  UPDATE_REPORT = 'UPDATE_REPORT',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  SHARE_REPORT = 'SHARE_REPORT',
  GET_CLIENT_USERS = 'GET_CLIENT_USERS',
  UPDATE_CLIENT_USERS = 'UPDATE_CLIENT_USERS',
  DELETE_CLIENT_USERS = 'DELETE_CLIENT_USERS',
  UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS',
  GET_API_KEYS = 'GET_API_KEYS',
  DELETE_API_KEYS = 'DELETE_API_KEYS',
  GENERATE_API_KEYS = 'GENERATE_API_KEYS',
  GET_CLIENT_HOOKS = 'GET_CLIENT_HOOKS',
  CREATE_CLIENT_HOOKS = 'CREATE_CLIENT_HOOKS',
  UPDATE_CLIENT_HOOKS = 'UPDATE_CLIENT_HOOKS',
  DELETE_CLIENT_HOOKS = 'DELETE_CLIENT_HOOKS',
  GET_CLIENT_SUBSCRIPTION_PLAN = 'GET_CLIENT_SUBSCRIPTION_PLAN',
  UPDATE_CLIENT_SUBSCRIPTION_PLAN = 'UPDATE_CLIENT_SUBSCRIPTION_PLAN',
  GET_USER_PROFILE = 'GET_USER_PROFILE',
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  GET_CLIENT_REPORTS = 'GET_CLIENT_REPORTS',
  GET_REPORT_CLIENT = 'GET_REPORT_CLIENT',
  UPDATE_UNIT_CLIENT = 'UPDATE_UNIT_CLIENT',
  UPDATE_UNIT_USER = 'UPDATE_UNIT_USER',
  ACCESS_DASHBOARD_WITHOUT_SUBSCRIPTION = 'ACCESS_DASHBOARD_WITHOUT_SUBSCRIPTION',
  ACCESS_SETTINGS = 'ACCESS_SETTINGS',
  GET_ISSUES = 'GET_ISSUES',
  GET_CLIENTS = 'GET_CLIENTS',
  GET_CONFIGURATIONS = 'GET_CONFIGURATIONS',
  GET_DASHBOARD_STATISTICS = 'GET_DASHBOARD_STATISTICS',
  GET_CLIENTS_ACTIVITIES = 'GET_CLIENTS_ACTIVITIES',
  ALLOW_TO_PREVIEW_CLIENT_ORGANIZATION = 'ALLOW_TO_PREVIEW_CLIENT_ORGANIZATION',
  ALLOW_TO_ACCESS_TEMPLATES = 'ALLOW_TO_ACCESS_TEMPLATES'
}
