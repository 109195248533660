import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';

import {
  Button,
  FormHelperText,
  TextField,
  CircularProgress,
  Zoom,
  Link,
  Box,
  useTheme,
  styled,
  Typography
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import {
  GoogleLogin,
  GoogleOAuthProvider,
  TokenResponse,
  useGoogleLogin,
  useGoogleOneTapLogin
} from '@react-oauth/google';
import React from 'react';

const GoogleSignInButton = styled(Button)(
  ({ theme }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  `
);

const ImgWrapper = styled('img')(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
`
);

const LoginJWT: FC = () => {
  const { login, loginWithGoogle } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const loginError = ({ message }) => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
    // emitter.emit('Error', message);
    console.log(message);
  };

  const handleSignInWithGoogle = async (tokenResponse: {
    access_token: string;
  }) => {
    if (tokenResponse) {
      const loginResponse = await loginWithGoogle(tokenResponse.access_token);
      if (loginResponse) {
        navigate('/checkfirst');
      } else {
        loginError(t('AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS'));
      }
    }
  };

  const loginHook = useGoogleLogin({
    onSuccess: handleSignInWithGoogle
  });

  const handleSubmitLogin = (e, onSubmit) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID'))
            .max(255)
            .required(t('AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED')),
          password: Yup.string()
            .max(255)
            .required(t('AUTH_LOGIN_CARD_PASS_FIELD_ERROR'))
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            await login(values.email, values.password);
            navigate('/checkfirst');

            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form noValidate onSubmit={(e) => handleSubmitLogin(e, handleSubmit)}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              helperText={touched.email && errors.email}
              label={t('AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL')}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              margin="normal"
              helperText={touched.password && errors.password}
              label={t('AUTH_LOGIN_CARD_PASS_FIELD_LABEL')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
            />
            <Typography
              component="span"
              variant="body1"
              color={theme.colors.info.main}
            >
              {t('AUTH_LOGIN_CARD_FORGOT_PASSWORD')}
            </Typography>{' '}
            <Link
              href="resetPassword"
              color={theme.colors.primary.main}
              variant="body1"
              fontWeight={'bold'}
            >
              {t('AUTH_LOGIN_CARD_RESET_PASSWORD_LINK')}
            </Link>
            <Button
              sx={{
                mt: 3
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {t('AUTH_LOGIN_CARD_BUTTON')}
            </Button>
          </form>
        )}
      </Formik>
      <GoogleSignInButton variant="outlined" onClick={() => loginHook()}>
        <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
        <Typography color={theme.colors.primary.main} variant="button">
          {t('AUTH_LOGIN_CARD_GOOGLE_BUTTON')}
        </Typography>
      </GoogleSignInButton>

      <Box
        textAlign={'center'}
        sx={{
          mt: 3
        }}
      >
        <Typography
          component="span"
          variant="body1"
          color={theme.colors.info.main}
        >
          {t('AUTH_LOGIN_CARD_NO_ACCOUNT')}
        </Typography>{' '}
        <Link
          href="/register"
          color={theme.colors.primary.main}
          variant="body1"
          fontWeight={'bold'}
        >
          {t('AUTH_LOGIN_CARD_REGISTER_LINK')}
        </Link>
      </Box>
    </>
  );
};

export default LoginJWT;
