import axios from 'axios';
import { IssueMediaType } from 'src/stores/backend/issues/types';

export const getMediaType = async (uri: string): Promise<IssueMediaType> => {
  try {
    const response = await axios({
      method: 'head',
      url: uri
    });
    const type: string = response.headers['content-type'];
    return type.includes('image') ? IssueMediaType.IMAGE : IssueMediaType.VIDEO;
  } catch (error) {
    return IssueMediaType.IMAGE;
  }
};
