import { action, makeObservable, observable, set } from 'mobx';
import { BaseBackendStore } from '../types';
import { httpGet, httpPatch, httpPost } from 'src/axios/axiosUtils';
import { addCheck, getCheck, getChecks, patchCheck } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { UnitStore } from '../unit';
import { ChecklistStore } from '../checklist';
import { Stores } from 'src/stores';
import type { CheckDto, MetaData, PatchCheckDto } from './types';

export class ChecksStore extends BaseBackendStore {
  @observable checks: CheckDto[] = [];

  @observable totalRowCount: number = undefined;

  @observable state: LoadingState = LoadingState.IDLE;

  private get unitStore(): UnitStore {
    return this.rootStore.backend.unit;
  }

  private get checklistStore(): ChecklistStore {
    return this.rootStore.backend.checklistStore;
  }

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  @action
  async createCheck(unitId: number, checklistId?: number, templateId?: number) {
    try {
      this.state = LoadingState.LOADING;
      const result: CheckDto = await httpPost(addCheck, {
        unitId,
        checklistId,
        status: 'OPEN',
        templateId
      });
      this.checks = [result, ...this.checks];
      this.totalRowCount += 1;
      this.state = LoadingState.DONE;
      return {
        id: result.id,
        checklistUrl: result.checklistUrl,
        token: result.token
      };
    } catch (error) {
      this.state = LoadingState.FAILED;
    }
  }

  @action
  async updateCheck(checkId: number, dto: PatchCheckDto) {
    try {
      this.state = LoadingState.LOADING;
      const result: CheckDto = await httpPatch(patchCheck(checkId), {
        ...dto
      });
      const checkState = await this.getSingleCheck(checkId);
      set(checkState, { ...result });
      this.state = LoadingState.DONE;
    } catch (error) {
      this.state = LoadingState.FAILED;
    }
  }

  @action
  async getChecks(
    clientId: number,
    pageNumber: number,
    pageSize: number,
    sortBy?: string,
    order?: 'asc' | 'desc',
    unitId?: number
  ) {
    try {
      this.state = LoadingState.LOADING;
      const result: { data: CheckDto[]; meta: MetaData } = await httpGet(
        getChecks(clientId, pageNumber, pageSize, sortBy, order, unitId)
      );
      this.checks = [...result.data];
      this.state = LoadingState.DONE;
      this.totalRowCount = result.meta.itemCount;
    } catch (error) {
      this.state = LoadingState.FAILED;
    }
  }

  @action
  async getCheck(checkId: number) {
    try {
      this.state = LoadingState.LOADING;
      const result: CheckDto = await httpGet(getCheck(checkId));
      this.state = LoadingState.DONE;
      return result;
    } catch (error) {
      this.state = LoadingState.FAILED;
    }
  }

  async getSingleCheck(checkId: number) {
    return this.checks.find((e) => e.id == checkId);
  }
}
