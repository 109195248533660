import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, styled, Typography, useTheme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { httpPost, uploadFileToS3 } from 'src/axios/axiosUtils';
import { useTranslation } from 'react-i18next';
import { LoadingState } from 'src/axios/types';
import { uploadFileURL } from 'src/axios/requests';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackendResourceWrapper from '../../BackendResourceWraper';
import ConditionalWrapper from '../../ConditionalWrapper';
import { useFormikContext } from 'formik';
import * as S from './styled';

export enum FileDomain {
  UNITS = 'UNITS',
  CLIENTS = 'CLIENTS',
  INSTRUCTIONS = 'INSTRUCTIONS',
  CHECKLISTS_UNITS = 'CHECKLISTS_UNITS'
}

interface Props {
  domain: FileDomain;
  name: string;
  accept?: 'audio/*' | 'video/*' | 'image/*';
  label?: string;
  openOnInit?: boolean;
  hideCloseButton?: boolean;
  onChange?: (value: string) => void;
}

export const FormFileUploader: FC<Props> = ({
  domain,
  accept = 'image/*',
  name,
  label,
  openOnInit = false,
  hideCloseButton = false,
  onChange
}) => {
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();
  const inputFile = useRef<any>(null);

  const [uploadingStatue, setUploadingStatue] = useState<LoadingState>(
    LoadingState.DONE
  );

  const openFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const fileChange = async (files: FileList | null) => {
    try {
      const file = files?.item(0);
      if (file) {
        setUploadingStatue(LoadingState.LOADING);
        const { uploadUrl, fileUrl } = await httpPost(uploadFileURL, {
          domain
        });
        await uploadFileToS3(uploadUrl, file);
        setFieldValue(name, fileUrl);
        setUploadingStatue(LoadingState.DONE);
        onChange?.(fileUrl);
      }
    } catch {
      setUploadingStatue(LoadingState.FAILED);
    }
  };

  useEffect(() => {
    if (openOnInit && !values[name]) {
      openFile();
    }
  }, []);

  return (
    <>
      {label && <S.Label variant="caption">{label}</S.Label>}
      <S.Container>
        <S.FileUploaderContainer
          onClick={openFile}
          $hasError={touched[name] && errors[name]}
        >
          <input
            ref={inputFile}
            hidden
            type="file"
            accept={accept}
            name={name}
            onChange={(e) => fileChange(e.target.files)}
            onBlur={handleBlur}
          />
          {!values[name]?.trim() && uploadingStatue !== LoadingState.LOADING && (
            <S.ImagePlaceHolderContainer>
              <img
                src="/static/images/placeholders/illustrations/image.png"
                alt="Custom Icon"
              />
            </S.ImagePlaceHolderContainer>
          )}
          <BackendResourceWrapper
            states={[uploadingStatue]}
            showChildrenOnError={true}
            loadingNode={
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            }
          >
            {values[name]?.trim() && (
              <>
                <S.ImagePreview alt={name} src={values[name]} />
                {!hideCloseButton && (
                  <S.CancelIconContainer
                    onClick={(e) => {
                      setFieldValue(name, null);
                      onChange?.(null);
                      e.stopPropagation();
                    }}
                  >
                    <CancelIcon color="error" />
                  </S.CancelIconContainer>
                )}
              </>
            )}
          </BackendResourceWrapper>
        </S.FileUploaderContainer>
        {touched[name] && errors[name] && (
          <S.ErrorText>{errors[name]}</S.ErrorText>
        )}
      </S.Container>
    </>
  );
};
