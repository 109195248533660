import { Button, DialogActions, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialog, { AlertDialogProps } from '..';

interface ConfirmationDialogProps extends AlertDialogProps {
  onConfirm: () => void;
  onCancel?: () => void;
  subtitle?: string;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  onCancel,
  onConfirm,
  open,
  title,
  subtitle,
  setOpen
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <AlertDialog
      title={title}
      open={open}
      setOpen={setOpen}
      onClose={handleCancel}
    >
      {subtitle && (
        <Typography variant="body1" px={2.5} pb={3}>
          {subtitle}
        </Typography>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          {t('NO')}
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          {t('YES')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
};

export default ConfirmationDialog;
