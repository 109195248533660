const deJSON = {
  AUTH_SIGN_UP_CARD_TITLE: 'Melden Sie sich an',
  AUTH_SIGN_UP_CARD_SUB_TITLE:
    'Füllen Sie die folgenden Felder aus, um sich für ein Konto anzumelden.',
  AUTH_SIGN_UP_CARD_GOOGLE_BUTTON: 'Melden Sie sich bei Google an',
  AUTH_SIGN_UP_CARD_OR_SEPARATOR: 'oder',
  AUTH_SIGN_UP_CARD_NAME_FIELD_LABEL: 'Name',
  AUTH_SIGN_UP_CARD_NAME_FIELD_ERROR: 'Das Namensfeld ist erforderlich',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_LABEL: 'Email',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_REQUIRED:
    'Das E-Mail-Feld ist erforderlich',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_INVALID:
    'Bei der angegebenen E-Mail-Adresse sollte es sich um eine gültige E-Mail-Adresse handeln',
  AUTH_SIGN_UP_CARD_PASS_FIELD_LABEL: 'Passwort',
  AUTH_SIGN_UP_CARD_PASS_FIELD_ERROR: 'Das Passwortfeld ist erforderlich',
  AUTH_SIGN_UP_CARD_TERMS_AGREE_STATEMENT: 'Ich akzeptiere das',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS: 'Geschäftsbedingungen',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS_ERROR:
    'Sie müssen unseren Allgemeinen Geschäftsbedingungen zustimmen',
  AUTH_SIGN_UP_CARD_BUTTON: 'Melden Sie sich an',
  AUTH_SIGN_UP_CARD_HAVE_ACCOUNT: 'Sie haben bereits ein Konto?',
  AUTH_SIGN_UP_CARD_LOGIN_LINK: 'Hier anmelden',
  AUTH_SIGN_UP_SIDEBAR_TITLE: 'Erstellen Sie digitale Inspektionsberichte',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_1: 'Dashboard zur Inspektionsverwaltung',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_2: 'Einfach zu bedienende Inspektions-App',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_3: 'Mobile-first digitale Inspektionsberichte',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_4:
    'Probieren Sie 10 kostenlose Berichte pro Monat aus',
  AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL: 'Email',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED:
    'Das E-Mail-Feld ist erforderlich',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID:
    'Bei der angegebenen E-Mail-Adresse sollte es sich um eine gültige E-Mail-Adresse handeln',
  AUTH_LOGIN_CARD_PASS_FIELD_LABEL: 'Passwort',
  AUTH_LOGIN_CARD_PASS_FIELD_ERROR: 'Das Passwortfeld ist erforderlich',
  AUTH_LOGIN_CARD_GOOGLE_BUTTON: 'Anmeldung mit Google',
  AUTH_LOGIN_CARD_BUTTON: 'anmelden',
  AUTH_LOGIN_CARD_FORGOT_PASSWORD: 'Passwort vergessen?',
  AUTH_LOGIN_CARD_RESET_PASSWORD_LINK: 'Passwort zurücksetzen',
  AUTH_LOGIN_CARD_NO_ACCOUNT: 'Sie haben noch kein Konto?',
  AUTH_LOGIN_CARD_REGISTER_LINK: 'Registrieren',
  AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS: 'falsche Email oder Passwort',
  USERS_LIST_PAGE_HEADER_TITLE: 'Benutzer',
  USERS_LIST_PAGE_HEADER_CAPTION: 'Verwalten Sie Ihre Benutzer',
  USERS_LIST_EMPTY_MESSAGE: 'Keine Benutzer',
  USERS_LIST_PAGE_HEADER_CREATE_BUTTON: 'Neuen Benutzer erstellen',
  USERS_LIST_DELETE_USER_CONFIRMATION_MESSAGE:
    'Möchten Sie diesen Benutzer wirklich löschen?',
  USERS_LIST_DELETE_USER_BUTTON_TOOLTIP: 'Löschen',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_TITLE: 'Benutzer erstellt',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_PASSWORD:
    'Der Benutzer wurde mit dem Passwort erstellt:',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL:
    'Es wurde auch eine Einladungs-E-Mail an den Benutzer gesendet, mit der Adresse',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL_EXTENDED:
    ', sie können den Link in dieser E-Mail verwenden, um ihr eigenes Passwort festzulegen.',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_CAPTION:
    'Verwenden Sie dieses Passwort, um sich anzumelden',
  USERS_LIST_CREATE_USER_DIALOG_TITLE: 'Neuen Benutzer erstellen',
  USERS_LIST_CREATE_USER_DIALOG_CAPTION: 'Fügen Sie einen neuen Benutzer hinzu',
  USERS_LIST_CREATE_USER_DIALOG_CREATE_BUTTON: 'Benutzer erstellen',
  REPORT_DETAILS_HEADER_TITLE: 'Berichtsdetails',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'PDF Herunterladen',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Laden Sie XLSX herunter',
  REPORT_DETAILS_NO_ANSWER: 'Keine Antwort',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Rechenschaftspflicht',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'In diesem Abschnitt wird angezeigt, wer den Bericht erstellt hat',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Überprüfung',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Alle Daten mit Checkfirst verifiziert',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Einheit',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspektion',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE: 'Bist du sicher?',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_SUBTITLE:
    'Dadurch werden auch alle Elemente in dieser Kategorie gelöscht.',
  COPIED: 'Erfolgreich kopiert ...',
  NO_ACTIVITY_MESSAGE: 'Aktivitätsdetails sind noch nicht verfügbar...',
  COMMENT: 'KOMMENTAR',
  configurations: 'Konfigurationen',
  REPORT_HOOK: 'Hook-Konfigurationen melden',
  REPORT_PDF: 'PDF-Konfigurationen melden',
  DISABLED: 'Deaktiviert',
  ENABLED: 'Ermöglicht',
  new_configuration: 'neue Konfiguration',
  Key: 'Schlüssel',
  Title: 'Titel',
  Actions: 'Aktionen',
  Clients: 'Kunden',
  Value: 'Wert',
  Edit: 'Bearbeiten',
  Cancel: 'Stornieren',
  Save: 'Speichern',
  Saved: 'Gerettet',
  OPTIONS: 'Optionen',
  FORGET_PASSWORD: 'Passwort vergessen',
  UPDATE_PASSWORD_TITLE: 'Kennwort aktualisieren',
  CONFIRM_PASSWORD_VALIDATION:
    'Bitte geben Sie in den Feldern „Passwort“ und „Passwort bestätigen“ dasselbe Passwort ein',
  PASSWORD_VALIDATION: 'Das Passwortfeld ist erforderlich',
  TITLE_VALIDATION: 'Das Titelfeld ist erforderlich',
  PASSWORD_UPDATED:
    'Das Passwort wurde erfolgreich aktualisiert. Bitte versuchen Sie, sich anzumelden',
  ERROR: 'Etwas ist schief gelaufen',
  PASSWORD: 'Passwort',
  CONFIRM_PASSWORD: 'Bestätige das Passwort',
  UPDATE_PASSWORD: 'Kennwort aktualisieren',
  UPDATE_USER: 'Benutzer aktualisieren',
  INVALID_EMAIL: 'Ungültige E-Mail',
  UPDATE_PASSWORD_TOGGEL: 'Kennwort aktualisieren',
  NAME_VALIDATION: 'Das Namensfeld ist erforderlich',
  EMAIL_VALIDATION: 'Das E-Mail-Feld ist erforderlich',
  MESSAGE_VALIDATION: 'Das Nachrichtenfeld ist erforderlich',
  SHOULD_VALID_EMAIL:
    'Bei der angegebenen E-Mail-Adresse sollte es sich um eine gültige E-Mail-Adresse handeln',
  IMAGE_VALIDATION: 'Das Bildfeld ist erforderlich',
  NAME_PLACEHOLDER: 'Name...',
  NAME_LABEL: 'Name',
  EMAIL_PLACEHOLDER: 'Email...',
  EMAIL_LABEL: 'Email',
  MESSAGE_LABEL: 'Nachricht',
  CLIENT_IMAGE_LABEL: 'Bild',
  PASSWORD_PLACEHOLDER: 'Passwort...',
  PASSWORD_LABEL: 'Passwort',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Bestätige das Passwort...',
  CONFIRM_PASSWORD_LABEL: 'Bestätige das Passwort',
  SAVE_USER: 'Benutzer speichern',
  UPDATE_PROFILE: 'Profil aktualisieren',
  FLAGGED: 'Gekennzeichnet',
  REPORT_DETAILS: 'Berichtsdetails',
  REMOVE_FLAG: 'Markierung entfernen',
  ADD_FLAG: 'Flagge hinzufügen',
  CLIENT_REPORTS: 'Kundenberichte',
  CLIENTS: 'Kunden',
  REPORTS: 'Berichte',
  CHECKS: 'Überprüfungen',
  UNITS: 'Einheiten',
  DELETE: 'Löschen',
  DELETED: 'Erfolgreich gelöscht',
  UNIT_DELETE_ALERT_TITLE: 'Möchten Sie diese Einheit wirklich löschen?',
  NO: 'NEIN',
  YES: 'Ja',
  NOT_SURE: 'Nicht sicher',
  FORGET_PASSWORD_Q: 'Passwort vergessen?',
  RESET_PASSWORD: 'Passwort zurücksetzen',
  RESET_PASSWORD_TITLE: 'Passwort wiederherstellen',
  RESET_PASSWORD_SUB_TITLE:
    'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen',
  UPDATE_PASSWORD_SUB_TITLE:
    'Geben Sie Ihr Passwort ein, um Ihr Passwort zu aktualisieren',
  SEND_NEW_PASSWORD: 'Sende mir ein neues Passwort',
  TRY_SIGN: 'Möchten Sie versuchen, sich erneut anzumelden?',
  CLICK_HERE: 'Klicken Sie hier',
  PLATFORM_TEMPLATE_INFO:
    'Plattformvorlagen können erst geändert werden, wenn sie mit einer Einheit verbunden sind',
  RESET_PASSWORD_INSTRUCTIONS:
    'Die Anweisungen zum Zurücksetzen des Passworts wurden an Ihre E-Mail-Adresse gesendet',
  CHECK_EMAIL_INSTRUCTIONS: 'Weitere Anweisungen finden Sie in Ihren E-Mails',
  CONTINUE_LOGIN: 'Melden Sie sich weiter an',
  TITLE: 'Titel',
  REFERENCES: 'Inspektionen',
  REFERENCE: 'Inspektion',
  GENERATE_REFERENCE: 'Neue Inspektion erstellen',
  LINK: 'Verknüpfung',
  NO_REPORTS: 'Noch keine Berichte erstellt',
  FREE_TRIAL: 'Starten Sie noch heute Ihre kostenlose Testversion',
  EMAIL_VERIFICATION: 'Bestätigen Sie Ihre E-Mail',
  SEND_EMAIL_TO: 'Wir haben eine E-Mail an gesendet',
  EMAIL_VERIFICATION_CONTINUE:
    'Um fortzufahren, überprüfen Sie bitte Ihre E-Mails und klicken Sie auf den Link, um Ihr Konto zu bestätigen.',
  NOT_RECEIVE_EMAIL: 'Sie haben die E-Mail nicht erhalten?',
  RESEND_EMAIL: 'Sende die E-Mail erneut',
  EMAIL_VERIFICATION_RESEND_ERROR: 'Die E-Mail konnte nicht gesendet werden',
  TERMS_VALIDATION:
    'Sie müssen unseren Allgemeinen Geschäftsbedingungen zustimmen',
  REGISTRATION_LAST_STEP: 'Revolutionieren Sie Inspektionen.',
  KNOW_MORE_INFO: 'Planen und führen Sie Inspektionen durch',
  COMPANY_VALIDATION: 'Firmenname ist erforderlich',
  COMPANY_LABEL: 'Name der Firma',
  COMPANY: 'Unternehmen',
  SIGN_UP_HERE: 'Benutzerkonto erstellen',
  CREATE_ACCOUNT_DISCLAIMER:
    'Durch Aktivieren dieses Kästchens erkläre ich mich damit einverstanden, Updates, Einblicke und Angebote von Checkfirst per E-Mail und Telefon an die oben genannten Kontaktinformationen zu erhalten. Mir ist bewusst, dass ich meine Einwilligung widerrufen kann.',
  COMPANY_TYPE_VALIDATION: 'Unternehmenstyp ist erforderlich',
  COMPANY_INDUSTRY_VALIDATION: 'Unternehmensbranche ist erforderlich',
  COMPANY_PHONE_MAX:
    'Eine Telefonnummer darf nicht mehr als 32 Zeichen lang sein',
  COMPANY_PHONE_MIN:
    'Eine Telefonnummer darf nicht weniger als 8 Zeichen lang sein',
  HOW_TO_COMMUNICATE: 'Wie haben Sie von uns erfahren?',
  PHONE_NUMBER: 'Telefonnummer',
  INDUSTRY: 'Industrie',
  CHECKFIRST_AI: 'Checkfirst.ai',
  TERMS_OF_USE: 'Nutzungsbedingungen',
  PRIVACY_POLICY: 'Datenschutzrichtlinie',
  BY_SIGNING: 'Mit meiner Anmeldung stimme ich zu',
  AND: 'und',
  GETTING_START: 'Loslegen',
  THANK_YOU: 'Danke schön!',
  ON_BOARD: 'Wir freuen uns, Sie an Bord zu haben.',
  UPDATE: 'Aktualisieren',
  SAVED: 'Erfolgreich gespeichert',
  SENT: 'Erfolgreich gesendet',
  UPDATE_COMPANY_HEADER: 'Firmendetails',
  UPDATE_COMPANY_INFO: 'Sie sollten den Firmennamen festlegen, um fortzufahren',
  SEND_INSTRUCTIONS_EMAIL: 'Anweisungen per E-Mail senden',
  TOKEN_INSTRUCTION_EMAIL_TITLE: 'Berichtsanweisungen per E-Mail senden',
  INSTRUCTION_EMAIL: 'Email',
  RESET_PASSWORD_SUCCESS:
    'Vielen Dank. Wenn die von Ihnen eingegebene E-Mail-Adresse korrekt ist, erhalten Sie in Kürze eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts',
  UPDATE_CLIENT: 'Firmendetails',
  UPDATE_CLIENT_PAGE_TITLE:
    'Auf dieser Seite können Sie die Angaben zu Ihrem Unternehmen aktualisieren.',
  UPDATE_CLIENT_CARD_TITLE: 'Firmendetails',
  COMPANY_NAME_PLACEHOLDER: 'Firmennamen aktualisieren',
  COMPANY_NAME_LABEL: 'Name der Firma',
  SAVE_CLIENT: 'Kunde speichern',
  'Yes/No': 'Ja Nein',
  MEDIA: 'Medien',
  TEXT: 'Text',
  NUMBER: 'Nummer',
  RADIO_BUTTON: 'Mehrfachauswahl',
  CHECK_BOX: 'Kontrollkästchen',
  SELECT_OPTIONS_HOLDER: 'separate Eingaben mit Return-Taste',
  SELECT_OPTIONS: 'Optionen',
  SELECT_OPTIONS_HELPER:
    'Bitte listen Sie die Optionen im Textfeld auf; Verwenden Sie die Eingabetaste, um zwischen den Optionen zu trennen',
  REGISTER_HTML_TITLE: 'Checkfirst – Melden Sie sich an',
  FILTER: 'Filter',
  FLAGGED_all: 'Zeige alles',
  FLAGGED_true: 'Nur gekennzeichnete Berichte',
  MANAGE_API_KEY_SUBHEADER: 'Verwalten Sie Ihre API-Schlüssel',
  API_KEY_MODAL_HEADER: 'API-Schlüssel generieren',
  API_KEY_MODAL_SUBHEADER:
    'Bitte geben Sie einen Namen ein, um diesen Schlüssel zu identifizieren',
  CREATED_API_KEY_MODAL_HEADER: 'Schlüssel erstellt',
  CREATED_API_KEY_MODAL_SUBHEADER:
    'Bitte bewahren Sie es an einem sicheren Ort auf, da es nicht wiederhergestellt werden kann. Sehen Sie sich die Entwicklerdokumentation an, um zu erfahren, wie Sie es verwenden!',
  NAME: 'Name',
  DOWNLOAD_XLSX: 'Laden Sie Xlsx herunter',
  SUBSCRIBE_TITLE: 'Wählen Sie Ihren Plan',
  SUBSCRIBE_BODY:
    'Einfache, transparente Preisgestaltung, die mit Ihnen wächst. Testen Sie jeden Plan 30 Tage lang kostenlos.',
  SUBSCRIBE_BODY2: 'Jahrespreis (15 % sparen)',
  SUBSCRIBE: 'Abonnieren',
  PAYMENT_SUCCESS_BUTTON: 'Loslegen',
  TRY_AGAIN: 'Versuchen Sie es erneut',
  PAYMENT_SUCCESS_TITLE: 'Danke',
  PAYMENT_SUCCESS_BODY:
    'Ihre Informationen wurden erfolgreich gespeichert. Wir freuen uns, Sie an Bord zu haben!',
  PAYMENT_FAILURE_TITLE: 'Etwas ist schief gelaufen',
  PAYMENT_FAILURE_BODY:
    'Möglicherweise ist Ihre Zahlung aufgrund Ihres Kartensaldos oder aus einem anderen Grund fehlgeschlagen. Bitte wenden Sie sich für weitere Informationen an den Kundendienst',
  SUBSCRIPTION_TITLE: 'Abonnements verwalten',
  SUBSCRIPTION_DESCRIPTION: 'VERWALTEN SIE IHR ABONNEMENT',
  SUBSCRIPTION_CARD_HEADER: 'Abonnementinformationen',
  SUBSCRIPTION_CARD_SUB_HEADER:
    'Verwalten Sie Details zu Ihren verknüpften E-Mail-Adressen',
  SUBSCRIPTION_PLAN: 'Planen',
  SUBSCRIPTION_CARD: 'Kreditkarte',
  SUBSCRIPTION_BILLED_WITH: 'Abgerechnet mit Mastercard ••••',
  MANAGE_SUBSCRIPTION: 'Abonnement verwalten',
  SUBSCRIPTION_ACTIVE: 'Aktiv',
  SUBSCRIPTION_IN_ACTIVE: 'Inaktiv',
  SUBSCRIPTION_END: 'ENDTERMIN',
  PRICING_PLANS: 'Preispläne',
  CURRENCY: '€',
  MONTH: 'Monat',
  YEAR: 'Die',
  ENTERPRISE_TITLE: 'In Kontakt kommen',
  ENTERPRISE_NAME: 'Platin',
  ENTERPRISE_DESCRIPTION: 'Entwickelt für Unternehmenskunden',
  ENTERPRISE_FEATURE1: 'Unbegrenzte Einheiten',
  ENTERPRISE_FEATURE2: 'Einmalige Anmeldung',
  ENTERPRISE_FEATURE3: 'Hochverfügbarkeits-SLA',
  ENTERPRISE_FEATURE4: 'Maßgeschneiderte Integrationen',
  ENTERPRISE_FEATURE5: 'Onboarding-Unterstützung und Schulung',
  ENTERPRISE_FEATURE6: 'Alle Gold-Funktionen',
  EDIT_ITEM: 'Aktualisieren',
  UPDATE_CHECKLIST_ITEM: 'Checklistenpunkte aktualisieren',
  EDIT: 'Bearbeiten',
  N0_CLIENTS_ACTIVITIES: 'Keine Kundenaktivitäten',
  CREATION_DATE: 'Erstellungsdatum',
  ACTIVITY_ACTION: 'Aktion',
  ACTIVITY_MODULE: 'Modul',
  CLIENT_NAME: 'Name',
  CLIENT_LABEL: 'Klient',
  CLIENT_ACTIVITY_SEARCH_PLACEHOLDER: 'Wählen Sie Kunde aus',
  CLIENT_ACTIVITY_TITLE: 'Kundenaktivitäten',
  MULTI_MEDIA: 'Multimedia',
  CONDITION: 'Zustand',
  UPLOAD: 'Wählen Sie ein Bild zum Hochladen aus',
  BRONZE: 'Bronze',
  GOLD: 'Gold',
  ENTERPRISE: 'Unternehmen',
  NONE: 'Keiner',
  HISTORY: 'Geschichte',
  CHECKLIST_ITEM_DRILL_DOWN_DESCRIPTION:
    'Nachfolgend finden Sie den Verlauf aller Inspektionen für dieses Gerät. Klicken Sie auf jedes Datum, um den entsprechenden Bericht zu laden.',
  REPORTS_TO_DATE: 'Bisherige Berichte',
  FLAGGED_REPORTS: 'Gekennzeichnete Berichte',
  ACTIVE_UNITS: 'Aktive Einheiten',
  RECENT_REPORTS: 'Aktuelle Berichte',
  RECENT_UNITS: 'Kürzlich hinzugefügte Einheiten',
  INSPECTOR: 'Inspektor',
  OTHER: 'Andere',
  DATE: 'Datum',
  REPORTS_COUNT: 'Berichte',
  UNIT: 'Einheit',
  VIEW_ALL: 'Alle ansehen',
  ISSUE_PRIORITY: 'Problempriorität',
  ISSUE_TITLE: 'Problempriorität',
  ISSUE_DESCRIPTION: 'Beschreibung',
  ISSUE_ID: 'AUSWEIS',
  ISSUE_REPORTED: 'Gemeldet',
  ISSUES: 'Gekennzeichnet',
  ISSUE_GALLERY: 'Galerie',
  ISSUE_DETAILS: 'Problemdetails',
  SHARING_REPORT: 'Aktie',
  LOGGED_IN_FIRST: 'Du musst eingeloggt sein',
  WELCOME_MESSAGE: 'Willkommen,',
  YOUR_STATISTICS_MESSAGE: 'Ihre Statistiken für heute,',
  ISSUES_HEADER: 'Gekennzeichnet',
  ISSUES_SUBHEADER: 'Verwalten Sie bei der Inspektion festgestellte Probleme',
  ISSUES_PRIORITY: 'PRIORITÄT',
  UPPERCASE_TITLE: 'TITEL',
  STATUS: 'STATUS',
  UPPERCASE_UNIT: 'EINHEIT',
  DESCRIPTION: 'BESCHREIBUNG',
  IMAGES: 'BILDER',
  REPORTED: 'BERICHTET',
  DEACTIVATED: 'Deaktiviert',
  DEACTIVATE: 'Deaktivieren',
  ACTIVATE: 'Aktivieren Sie',
  SHOW_ACTIVE_CLIENTS: 'Aktive Kunden anzeigen',
  SHOW_DEACTIVATED_CLIENTS: 'Deaktivierte Clients anzeigen',
  CLIENT_USERS: 'Client-Benutzer',
  CO_SIGN: 'Mitunterzeichnen',
  CO_SIGN_HERE: 'Unterzeichnen Sie hier mit',
  SIGN_MODAL_TITLE: 'Mitunterzeichnen des Berichts',
  SIGN_MODAL_DESCRIPTION:
    'In diesem Dialogfenster können Sie den Inspektionsbericht mitunterzeichnen.',
  NAME_HOLDER: 'Namen hinzufügen',
  NAME_REQUIRED: 'Das Namensfeld ist erforderlich',
  SIGNATURE_IMAGE_URL: 'Unterschrift',
  SIGNATURE_REQUIRED: 'Das Signaturfeld ist erforderlich',
  SIGN_BUTTON: 'Einreichen',
  UPDATE_PASSWORD_INFO: 'Möchten Sie Ihr Passwort aktualisieren?',
  UPDATE_PASSWORD_LINK: 'Kennwort aktualisieren',
  UPDATE_USER_PASSWORD: 'Kennwort ändern',
  UPDATE_USER_PASSWORD_CARD_TITLE: 'Nutzerinformation',
  OLD_PASSWORD_LABEL: 'Aktuelles Passwort',
  SAVE_USER_PASSWORD: 'Speichern',
  OLD_PASSWORD_PLACEHOLDER: 'Aktuelles Passwort...',
  COPY_REPORT_TOKEN: 'Berichtstoken kopieren',
  QR_CODE: 'QR-Code',
  QR_CODE_MODAL_TITLE: 'Inspektionslink QR',
  DOWNLOAD: 'Herunterladen',
  DOWNLOADED: 'Erfolgreich heruntergeladen',
  DEVELOPMENT: 'Entwicklung',
  UNIT_FORM_DESCRIPTION_PLACEHOLDER: 'Geben Sie hier eine Beschreibung ein.',
  UNIT_FORM_TITLE_PLACEHOLDER: 'Geben Sie hier einen Titel ein.',
  UNIT_FORM_DESCRIPTION_CAPTION:
    'Diese Informationen werden dem Endbenutzer angezeigt, wenn er den Bericht startet. Stellen Sie daher sicher, dass diese Beschreibung ihm dabei hilft, den Bericht so zu erstellen, wie es für Ihr Unternehmen am besten ist.',
  CREATE_USER_NAME_PLACEHOLDER: 'Geben Sie hier den Namen ein',
  CREATE_USER_EMAIL_PLACEHOLDER: 'Geben Sie hier die E-Mail-Adresse ein',
  SAVE: 'Speichern',
  CREATE: 'Erstellen',
  UPLOAD_CLIENT_LOGO: 'Logo',
  COPY: 'Kopieren',
  ROLE: 'Rolle',
  ROLE_LABEL: 'Wählen Sie die Benutzerrolle aus',
  ROLE_client: 'Kundenadministrator',
  ROLE_client_manager: 'Kundenmanager',
  ROLE_client_contributor: 'Kundenmitarbeiter',
  USERS_LIST_EDIT_USER_BUTTON_TOOLTIP: 'Benutzer aktualisieren',
  USERS_LIST_UPDATE_USER_DIALOG_TITLE: 'Benutzerinformationen aktualisieren',
  USERS_LIST_UPDATE_USER_DIALOG_CAPTION: 'Benutzer aktualisieren',
  USERS_LIST_UPDATE_USER_DIALOG_CREATE_BUTTON: 'Benutzer aktualisieren',
  WEBHOOK_SCREEN_LIST_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_LIST_CAPTION: 'Verwalten Sie hier Ihre Webhooks',
  WEBHOOK_SCREEN_LIST_CREATE_BUTTON: 'Webhook hinzufügen',
  REPORT_CREATED: 'Bericht',
  ISSUE_CREATED: 'Ausgabe',
  COUNTER_SIGNATURE_CREATED: 'Gegenzeichnung',
  TYPE: 'TYP',
  URL: 'URL',
  MODIFIED: 'GEÄNDERT',
  NO_WEBHOOKS_ADDED: 'Es wurden noch keine Webhooks hinzugefügt.',
  ACTION: 'AKTION',
  EDIT_WEBHOOK: 'Bearbeiten',
  WEBHOOK_DETAILS: 'Einzelheiten',
  WEBHOOK_SCREEN_SAVE_TITLE: 'Webhook hinzufügen',
  WEBHOOK_SCREEN_SAVE_CAPTION:
    'Wählen Sie aus, über welche Art von Ereignis Sie benachrichtigt werden möchten, und fügen Sie dann die URL für Ihren Webhook hinzu.',
  HOOK_TYPE_REQUIRED: 'Das Feld „Typ“ ist erforderlich',
  HOOK_URL_REQUIRED: 'Das URL-Feld ist erforderlich',
  ADD_WEBHOOK: 'Webhook hinzufügen',
  WEBHOOK_TYPE_LABEL: 'Typ',
  WEBHOOK_TYPE_PLACEHOLDER: 'Typ',
  WEBHOOK_URL_PLACEHOLDER: 'URL',
  WEBHOOK_URL_LABEL: 'URL',
  WEBHOOK_SCREEN_DETAILS_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_DETAILS_CAPTION: 'Organisation / Webhooks',
  HOOK_DATE: 'DATUM',
  WEBHOOK_RETRY: 'Wiederholen',
  SUCCESS: 'Erfolg',
  FAILED: 'Fehlgeschlagen',
  REQUEST_DETAIL_MODAL_HEADER: 'Anfragedetails',
  DETAILS: 'Einzelheiten',
  RESPONSE: 'ANTWORT',
  BODY: 'Körper',
  ACTIONS: 'Aktionen',
  INVALID_MAGIC_LINK:
    'Der magische Link ist möglicherweise ungültig oder abgelaufen',
  URL_VALIDATION: 'Bitte geben Sie eine gültige URL ein',
  UNIT_FORM_REDIRECT_URL_PLACEHOLDER:
    'Geben Sie hier eine Inspektions-Weiterleitungs-URL ein.',
  UNIT_FORM_REDIRECT_URL_LABEL:
    'Geben Sie hier eine Inspektions-Weiterleitungs-URL ein.',
  ID_NAME: 'ID-Name',
  CREATED_AT: 'Erstellt',
  OPEN: 'Offen',
  NEW_CHECKLIST_TITLE: 'Neue Checkliste',
  UNTITLED_CHECKLIST: 'Unbenannte Checkliste',
  UNTITLED_CHECKLIST_FROM_TEMPLATE: 'Unbenannte Checkliste (aus der Vorlage)',
  CREATE_CHECKLIST: 'Erstellen Sie eine neue Checkliste',
  UPDATE_CHECKLIST: 'Update-Checkliste',
  SAVE_CHECKLIST_SUBTITLE: 'Checklisten-Fortschrittsformular speichern',
  CHECKLISTS_TITLE: 'Checklisten',
  MANAGE_TEMPLATE: 'Verwalten',
  COPY_INSPECTION: 'Link kopieren',
  DUPLICATE: 'Duplikat',
  DUPLICATED: 'Erfolgreich dupliziert',
  NEW_UNIT_TITLE: 'Neue Einheit',
  UPDATE_UNIT: 'Einheit aktualisieren',
  CREATE_UNIT: 'Neue einheit erstellen',
  CREATE_UNIT_SUBTITLE:
    'Einheiten sind die Ziele jeder Inspektion, beispielsweise Vermögenswerte oder Standorte',
  UNIQUE_ID: 'Eindeutige Kennung',
  SAVE_UNIT_SUBTITLE: 'Einheit speichern',
  NEW_BLANK_TEMPLATE: 'Neue leere Vorlage',
  TEMPLATE_PAGE_TITLE: 'Vorlagen',
  PLATFORM_TEMPLATE_PAGE_TITLE: 'Plattformvorlagen',
  CLIENT_TEMPLATE_PAGE_TITLE: 'Vorlagengalerie',
  UPDATE_TEMPLATE: 'Vorlage aktualisieren',
  SAVE_TEMPLATE_SUBTITLE: 'Ändern Sie Namen und Bild für die Vorlage',
  CREATE_TEMPLATE: 'Vorlage erstellen',
  SELECT_TEMPLATE: 'Vorlage auswählen',
  TEMPLATE_BUILDER: 'Vorlagenersteller',
  MANAGE_CHECKLIST_ITEMS: 'Checklistenelemente bearbeiten',
  CREATE_BLANK: 'Rohling erstellen',
  CHECKLIST_DELETE_ALERT_TITLE:
    'Sind Sie sicher, dass Sie diese Checkliste löschen möchten?',
  CHECKLIST: 'Checkliste',
  CREATE_CATEGORY: 'Erstellen Sie eine Kategorie',
  UPDATE_CATEGORY: 'Aktualisieren Sie eine Kategorie',
  PEOPLE: 'Menschen',
  SELECT: 'Wählen....',
  LANGUAGE_pt: 'Portugiesisch',
  LANGUAGE_en: 'Englisch',
  LANGUAGE_fr: 'Französisch',
  LANGUAGE_de: 'Deutsch',
  LANGUAGE_zh: 'Chinesisch',
  LANGUAGE_es: 'Spanisch',
  LANGUAGE_DEFAULT: 'Standard',
  Dashboard: 'Armaturenbrett',
  Issues: 'Probleme',
  Organisation: 'Unternehmen',
  USERS: 'Benutzer',
  Developer: 'Entwickler',
  Webhooks: 'Web-Hooks',
  Subscription: 'Abonnement',
  MANAGE_UNITS: 'Verwalten Sie Ihre Einheiten',
  ID: 'AUSWEIS',
  LAST_REPORT_DATE: 'Letzter Bericht',
  DELETE_WARNING: 'Sie können nach dem Löschen nicht mehr wiederherstellen',
  DELETE_CLIENT_WARNING: 'Möchten Sie diesen Client wirklich löschen?',
  CREATE_CLIENT: 'Neuen Kunden erstellen',
  CREATE_CLIENT_DESCRIPTION:
    'Verwenden Sie dieses Dialogfenster, um einen neuen Client hinzuzufügen',
  EMAIL: 'Email',
  IMAGE: 'Bild',
  INVALID_FILE_TYPE: 'Sie können diese Dateitypen nicht hochladen',
  CLIENT_PASSWORD_INFO: 'Verwenden Sie dieses Passwort, um sich anzumelden',
  CREATE_CLIENT_BUTTON: 'Client erstellen',
  SIGN_OUT: 'Abmelden',
  DEVELOPER_DOCUMENTATION: 'Entwicklerdokumentation',
  REPORT_PROCESSING: 'Der Bericht wird noch bearbeitet',
  OF: 'von',
  PAGINATION_INFO: 'Zeilen pro Seite',
  'Unit Title': 'Name',
  'Select Unit Image': 'Image',
  CANCEL: 'Abbrechen',
  Client: 'Zugewiesen an',
  Client_here: 'Nicht zugewiesen',
  New_Category: 'Neue Kategorie',
  EDIT_CHECKLIST_ITEM_Title: 'Frage',
  CREATE_INSTRUCTION: 'Anweisungen hinzufügen',
  UPDATE_INSTRUCTION: 'Anweisungen bearbeiten',
  SAVE_INSTRUCTION_SUBTITLE:
    'Das Anweisungsbild und die Beschreibung werden neben der Frage sichtbar sein, um den Benutzer bei der Beantwortung zu unterstützen',
  ADD_ITEM: '+ Hinzufügen',
  TYPE_OF_RESPONSE: 'Art der Antwort',
  Image: 'Bild',
  EDIT_Instructions: 'Anweisungen bearbeiten',
  ASSIGN_OWNER: 'Eigentümer zuweisen',
  ASSIGN_OWNER_TO: 'Besitzer zuweisen zu',
  REMOVE_OWNER: 'Besitzer entfernen',
  OWNER_REMOVED: 'Besitzer entfernt',
  ASSIGNED_OWNER: 'Zugewiesener Eigentümer',
  SHARE_CHECK: 'Überprüfung teilen',
  SHARE_CHECK_DESCRIPTION:
    'Verwende das Formular unten, um es mit dem Überprüfer zu teilen.',
  TEMPLATE_BUILDER_BREADCRUMB: 'Vorlagen',
  SEND_VIA: 'Senden über',
  To: 'An',
  Subject: 'Betreff',
  EMAIL_TOKEN_DESCRIPTION: 'Beschreibung',
  Share_Inspection: 'Inspektion teilen',
  Open_Modal: 'Neue Überprüfung',
  CHECKFIRST: 'Checkfirst',
  Select_unit: 'Einheit auswählen',
  SELECT_CHECKLIST: 'Checkliste auswählen',
  Checklists: 'Checklisten',
  Templates: 'Vorlagen',
  Email: 'E-Mail',
  Add: 'Hinzufügen',
  Back: 'Zurück',
  Owner: 'Besitzer',
  Description: 'Beschreibung',
  FOOTER: 'Fusszeile',
  Created: 'Erstellt',
  UPGRADE: 'Upgrade',
  READY_FOR_AN_UPGRADE: 'Bereit für ein Upgrade?',
  UPGRADE_ACCOUNT_TO_ACCESS_THEM:
    'Diese Berichte sind aufgrund von Nutzungseinschränkungen in Ihrem Plan gesperrt. Bitte' +
    '\n' +
    'upgraden Sie Ihr Konto, um darauf zugreifen zu können.',
  ADD_LOGIC: 'Logik hinzufügen',
  EDIT_CHECKLIST_ITEM: 'Anweisungen bearbeiten',
  VERIFY_WITH_AI: 'Mit KI überprüfen',
  ADD_INSTRUCTIONS: 'Anweisungen hinzufügen',
  ADD_IMAGE: 'Bild hinzufügen',
  CHECKLIST_BUILDER: {
    TITLE_PLACEHOLDER: 'Titel',
    DESCRIPTION_PLACEHOLDER: 'Beschreibung hinzufügen (optional)',
    ADD_CRITERIA_BUTTON_LABEL: 'Kriterien hinzufügen',
    ADD_SECTION_BUTTON_LABEL: 'Abschnitt hinzufügen',
    SAVED_MESSAGE: 'Erfolgreich gespeichert',
    BACK_BUTTON: 'Zurück',
    NEW_CHECK_BUTTON: 'Neue Überprüfung',
    PREVIEW_BUTTON: 'Vorschau-Modus',
    USE_TEMPLATE_BUTTON: 'Vorlage verwenden',
    FLOATING_TOOLBAR: {
      DUPLICATE: 'Abschnitt duplizieren',
      DELETE: 'Abschnitt löschen',
      ADD_CRITERIA: 'Kriterium hinzufügen',
      ADD_SECTION: 'Abschnitt hinzufügen'
    },
    SECTION: {
      TITLE_PLACEHOLDER: 'Geben Sie hier den Abschnittstitel ein',
      DELETE_CONFIRMATION_MESSAGE:
        'Dadurch werden auch alle Elemente in diesem Abschnitt gelöscht.',
      DELETE_TOOLTIP: 'löschen',
      ERRORS: {
        REQUIRED: 'Das Titelfeld ist erforderlich.',
        DUPLICATE:
          'Der Titel darf keinen der folgenden Werte haben: {{sections}}'
      }
    },
    CRITERIA: {
      TITLE_PLACEHOLDER: 'Kriterien hinzufügen',
      TYPE_PLACEHOLDER: 'Art der Antwort',
      DELETE_CONFIRMATION_MESSAGE:
        'Sind Sie sicher, dass Sie dieses Kriterium löschen möchten?',
      REQUIRED_LABEL: 'Erforderlich',
      CHANGE_TYPE_CONFIRMATION_MESSAGE:
        'Dieses Kriterium enthält Logik und verschachtelte Kriterien. Dies geht verloren, wenn Sie den Kriterientyp ändern. Möchten Sie den Kriterientyp ändern?',
      ERRORS: {
        REQUIRED: 'Das Titelfeld ist erforderlich.',
        MIN: 'Bitte geben Sie mindestens 2 Optionen ein.',
        MAX: 'Bitte geben Sie weniger als 100 Optionen ein.'
      }
    },
    LOGIC: {
      DAMAGED: 'Beschädigt',
      POOR: 'Arm',
      FAIR: 'Gerecht',
      GOOD: 'Gut',
      GREAT: 'Großartig',
      IF: 'Wenn Antwort',
      IF_IMAGE: 'Wenn Bild',
      THEN: 'Dann',
      YES: 'Ja',
      NO: 'NEIN',
      NA: 'n.v.',
      IS: 'Ist',
      IS_NOT: 'Ist nicht',
      IS_EMPTY: 'Ist leer',
      IS_NOT_EMPTY: 'Ist nicht leer',
      ADD_CRITERIA: 'Kriterien anzeigen',
      FLAG_REPORT: 'Bericht markieren',
      RAISE_ISSUE: 'Ein Problem melden',
      EQUALS: 'Gleich (=)',
      DOES_NOT_EQUAL: 'Ist nicht gleich (≠)',
      GREATER_THAN: 'Größer als (>)',
      LESS_THAN: 'Kleiner als (<)',
      GREATER_THAN_OR_EQUAL_TO: 'Größer oder gleich (≥)',
      LESS_THAN_OR_EQUAL_TO: 'Kleiner oder gleich (≤)',
      BETWEEN: 'Zwischen',
      NOT_BETWEEN: 'Nicht zwischen',
      CONTAINS: 'Enthält',
      DOES_NOT_CONTAIN: 'Enthält nicht',
      STARTS_WITH: 'Beginnt mit',
      ACTION_PLACEHOLDER: 'Aktion auswählen',
      YES_NO_PLACEHOLDER: 'Wählen Sie mehrere Werte aus',
      DELETE_CONFIRMATION_MESSAGE:
        'Sind Sie sicher, dass Sie diese Logik löschen möchten?'
    },
    INSTRUCTIONS: {
      PLACEHOLDER: 'Text...',
      LABEL: 'Anweisungen',
      IMAGE_LABEL: 'Bild'
    }
  },
  SaveBasicInformation: {
    ERRORS: {
      TITLE_REQUIRED: 'Der Name des Geräts ist erforderlich.',
      CLIENT_FIELD_REQUIRED: 'Das Feld "Kunde" ist erforderlich.',
      FOOTER_LENGTH: 'Die Fußzeile darf höchstens 630 Zeichen lang sein'
    }
  },
  SHARE_INSPECTION_MODAL: {
    STEP_1: {
      TITLE: 'Checkliste auswählen',
      SUBTITLE:
        'Wählen Sie aus der Liste der Checklisten unten aus, was Sie überprüfen möchten.',
      NAME: 'Name',
      CREATED: 'Erstellt',
      NEW_CHECKLIST: 'Neue Checkliste'
    },
    STEP_2: {
      TITLE: 'Einheit auswählen',
      SUBTITLE: 'Wählen Sie die Einheit aus, die überprüft werden soll.',
      NAME: 'Name',
      OWNER: 'Besitzer',
      CREATED: 'Erstellt',
      NEW_UNIT: 'Neue Einheit'
    },
    STEP_3: {
      TITLE: 'Erfolg!',
      SUBTITLE:
        'Die Prüfung {{id}} wurde erfolgreich erstellt. Verwenden Sie das untenstehende Formular, um sie mit dem Prüfer zu teilen.',
      SEND_VIA: 'Senden über',
      EMAIL: 'E-Mail',
      LINK: 'Link',
      QR: 'QR-Code',
      COPY: 'Kopieren',
      CANCEL: 'Abbrechen'
    }
  },
  CHECKS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Vorschau',
      SHARE: 'Teilen'
    },
    HEADERS: {
      ID: 'ID',
      CHECKLIST: 'Checkliste',
      UNIT: 'Einheit',
      SCHEDULED: 'Geplant',
      DUE: 'Fällig',
      DUE_DATE: 'Fälligkeitsdatum',
      ASSIGNED: 'Zugewiesen',
      ASSIGNED_TO: 'Zugewiesen an',
      STATUS: 'Status'
    },
    STATUS: {
      OPEN: 'Offen',
      STARTED: 'Gestartet',
      COMPLETED: 'Abgeschlossen',
      SCHEDULED: 'Geplant',
      CLOSED: 'Geschlossen'
    }
  },
  LOGO_ALT: 'Logo',
  CHECKLIST_ITEM_TYPE: {
    'Yes/No': 'Ja/Nein',
    MEDIA: 'Medien',
    TEXT: 'Text',
    NUMBER: 'Nummer',
    RADIO_BUTTON: 'Optionsfelder',
    CHECK_BOX: 'Kontrollkästchen',
    MULTIPLE_CHOICE: 'Mehrfachauswahl',
    DISCLAIMER: 'HAFTUNGSAUSSCHLUSS',
    RESPONSE_TYPES: 'Antworttypen',
    CUSTOM_OPTIONS: 'Benutzerdefinierte Optionen',
    SIGNATURE: 'Unterschrift',
    MULTI_MEDIA: 'Medien',
    TIME: 'Zeit',
    AI: 'KI',
    CONDITION: 'Bedingung',
    DATE: 'Datum',
    MULTIPLE_CHOICE_OPTIONS: {
      YES: 'Ja',
      NO: 'Nein',
      NA: 'N/A',
      GOOD: 'Gut',
      FAIR: 'Fair',
      POOR: 'Schlecht',
      CONFORM: 'Konform',
      NOT_CONFORM: 'Nicht konform',
      PENDING: 'Ausstehend',
      PLACEHOLDER: 'Options-Titel'
    },
    MULTIPLE_CHOICE_MODAL: {
      TITLE: 'Mehrfachauswahloptionen',
      SUBTITLE: 'z. B. Ja, Nein, N/A',
      OPTION: 'Option',
      DELETE: 'Löschen',
      ADD_OPTION_BUTTON:
        'Option hinzufügen (oder drücken Sie die Eingabetaste)',
      SAVE_BUTTON: 'Speichern',
      CANCEL_BUTTON: 'Abbrechen',
      MULTIPLE_SELECTION_TOGGLE: 'Mehrfachauswahl',
      SCORING_TOGGLE: 'Bewertung',
      SCORE: 'Punktzahl'
    }
  },

  REPORTS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Vorschau',
      SHARE: 'Teilen'
    },
    HEADERS: {
      IMAGE_URL: 'Bild-URL',
      RULE: 'Regel',
      IMAGE_CAPTION: 'Bildunterschrift',
      WORDS_USED_TO_CHECK: 'Zu überprüfende Wörter',
      DECISION: 'Entscheidung',
      VERIFIED: 'Verifiziert',
      VERIFICATION_PROMPT: 'Verifizierungsaufforderung',
      ASSIGNED: 'Zugewiesen',
      ASSIGNED_TO: 'Zugewiesen an',
      STATUS: 'Status'
    },
    STATUS: {
      OPEN: 'Offen',
      STARTED: 'Gestartet',
      COMPLETED: 'Abgeschlossen',
      SCHEDULED: 'Geplant',
      CLOSED: 'Geschlossen'
    }
  },
  SHOW_MORE: '▼ Mehr anzeigen',
  SHOW_LESS: '▲ Weniger anzeigen',
  SHOW_DETAILS: 'Details anzeigen',

  CONDITION_RESPONSES: {
    DAMAGED: 'Beschädigt',
    POOR: 'Arm',
    FAIR: 'Gerecht',
    GOOD: 'Gut',
    GREAT: 'Großartig'
  },
  CHECKLISTS: {
    NAME: 'Name',
    CREATED_BY: 'Erstellt von',
    LAST_MODIFIED: 'Zuletzt geändert',
    NEW_CHECKLIST: 'Neue Checkliste'
  },
  NO_CHECKS_CREATED: 'Es wurden keine Überprüfungen erstellt',
  NO_REPORTS_CREATED: 'Es wurden keine Berichte erstellt',
  NO_CHECKLIST_CREATED:
    'Es wurden keine Checklisten zu dieser Einheit hinzugefügt',
  INDUSTRIES: {
    TESTING: 'Testen',
    COMPLIANCE: 'Einhaltung',
    AUDIT: 'Prüfung',
    CERTIFICATION: 'Zertifizierung',
    INSPECTIONS: 'Inspektionen',
    CONSTRUCTION: 'Konstruktion',
    MANUFACTURING: 'Herstellung',
    MARKETPLACES: 'Marktplätze'
  },
  COMMUNICATIONS: {
    GOOGLE: 'Google',
    LINKEDIN: 'LinkedIn',
    WORD_OF_MOUTH: 'Mundpropaganda',
    EVENT: 'Ereignis'
  }
};

export default deJSON;
