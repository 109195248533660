import { Formik } from 'formik';
import * as Yup from 'yup';
import { ChangeEventHandler, FC, createRef, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { Client } from 'src/models/client';
import { SaveBasicInformationProps } from './types';
import { useTranslation } from 'react-i18next';
import { httpGet, httpPost, httpPut } from 'src/axios/axiosUtils';
import { useNavigate, useParams } from 'react-router';
import { units, clients as clientsURL } from 'src/axios/requests';
import { UnitEvent } from 'src/hooks/useAnalytics/AnalyticsEvent';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  DialogContent,
  Grid,
  styled,
  TextField,
  Typography,
  useTheme,
  Zoom
} from '@mui/material';
import BackendResourceWrapper from 'src/components/BackendResourceWraper';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import FileUploader, { FileDomain } from 'src/components/FileUploader';
import useAnalytics from 'src/hooks/useAnalytics';
import { LoadingState } from 'src/axios/types';
import { useStores } from 'src/hooks/useMobxStores';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { PermissionKey, User, UserRoles } from 'src/models/user';
import { ClientUser, useClientUsers } from 'src/hooks/useClientUsers';
import PermissionWrapper, {
  UsePermission
} from 'src/components/PermissionWrapper';
import { URL_REGEX_VALIDATION } from 'src/constants/validation';
import { BaseModal } from 'src/components/FormModal';
// import '../../../styles.css';

const StyledTextField = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border-bottom: 1px solid #d9dbe9;
  }
  /* hover (double-ampersand needed for specificity reasons. */
  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid #d9dbe9;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 1px solid #d9dbe9;
  }
`;

const SaveBasicInformation: FC<SaveBasicInformationProps> = observer(
  ({ handleClose, open, unitId }) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { logAnalyticsEvent } = useAnalytics();
    const theme = useTheme();
    const {
      backend: { unit: unitStore }
    } = useStores();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { hasPermission } = UsePermission();

    const { getClientUsers, users } = useClientUsers();

    const [clients, setClients] = useState<Client[]>([]);
    const [clientsState, setClientsState] = useState(LoadingState.IDLE);
    const [clientSearch, setClientSearch] = useState('');
    const [userSearch, setUserSearch] = useState('');
    const formRef: any = createRef();
    const [unitsState, setUnitsState] = useState(LoadingState.IDLE);

    // const styles = styled(TextField)({
    //
    //
    // })

    //@todo - add translation to label={t('Client')}

    useEffect(() => {
      (async () => {
        if (auth?.user?.roles.includes(UserRoles.ADMIN)) {
          setClientsState(LoadingState.LOADING);
          const response = await httpGet(
            `${clientsURL}?search=${clientSearch}`,
            false
          );
          setClients(response.data);
          setClientsState(LoadingState.DONE);
        }
      })();
    }, [clientSearch]);

    useEffect(() => {
      return () => {};
    }, []);

    useEffect(() => {
      if (+unitStore.unit?.id === unitId) {
        return;
      }
      if (unitId) {
        unitStore.getUnit(unitId);
      } else {
        unitStore.unit = undefined;
      }
    }, [unitId]);

    useEffect(() => {
      (async () => {
        if (hasPermission(PermissionKey.UPDATE_UNIT_USER)) {
          getClientUsers(auth.user.client.id, userSearch);
        }
      })();
    }, [userSearch]);

    const handleSaveUnitSuccess = () => {
      enqueueSnackbar(t('SAVED'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    };

    const onClientInputChange = (e) => {
      const search = e.target.value;
      setClientSearch(search);
    };

    const onUserInputChange = (e) => {
      const search = e.target.value;
      setUserSearch(search);
    };
    const saveUnit = async () => {
      try {
        let savedUnitId = unitId;
        if (unitId) {
          await httpPut(
            `${units}/${unitId}`,
            unitStore.unitBasicInformationDto
          );
        } else {
          const response = await httpPost(
            units,
            unitStore.unitBasicInformationDto
          );
          const unitCreatedEvent = new UnitEvent('created', {
            id: response.id
          });
          logAnalyticsEvent(unitCreatedEvent);
          savedUnitId = response.id;
        }
        handleClose(savedUnitId);
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    return (
      <BaseModal
        open={open}
        handleClose={() => {
          handleClose(undefined);
        }}
        handleAction={() => {
          formRef.current.handleSubmit();
        }}
        actionState={unitStore.unitState}
        footer={{
          actionButton: unitId ? t('Save') : t('CREATE'),
          cancelButton: t('CANCEL')
        }}
        header={{
          title: unitId ? t('UPDATE_UNIT') : t('CREATE_UNIT'),
          description: unitId
            ? t('SAVE_UNIT_SUBTITLE')
            : t('CREATE_UNIT_SUBTITLE')
        }}
      >
        <BackendResourceWrapper
          states={[unitId ? unitStore.unitState : LoadingState.DONE]}
        >
          <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={{
              title: unitId ? unitStore?.unit?.title : null,
              description: unitId ? unitStore?.unit?.description : '',
              externalId: unitId ? unitStore?.unit?.externalId : '',
              imageURL: unitId ? unitStore?.unit?.imageURL : null,
              redirectURL: unitId ? unitStore?.unit?.redirectURL : null,
              footer: unitId ? unitStore?.unit?.footer : null,
              client: ([
                UserRoles.CLIENT,
                UserRoles.CLIENT_CONTRIBUTOR,
                UserRoles.CLIENT_MANAGER
              ].includes(auth.user.roles[0] as UserRoles)
                ? auth.user.client
                : unitStore?.unit?.client) as Client,
              user: unitId ? (unitStore?.unit?.user as any) : null,
              submit: null
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .max(255)
                .required(t('SaveBasicInformation.ERRORS.TITLE_REQUIRED'))
                .nullable(),
              redirectURL: Yup.string()
                .max(255)
                .matches(URL_REGEX_VALIDATION, t('URL_VALIDATION'))
                .optional()
                .nullable(),
              description: Yup.string().max(255).optional().default(''),
              externalId: Yup.string().max(255).optional().default(''),
              client: Yup.object().required(
                t('SaveBasicInformation.ERRORS.CLIENT_FIELD_REQUIRED')
              ),
              user: Yup.object().optional().nullable(),
              imageURL: Yup.string().max(255).notRequired().nullable(),
              footer: Yup.string()
                .max(630, t('SaveBasicInformation.ERRORS.FOOTER_LENGTH'))
                .notRequired()
                .nullable()
            })}
            onSubmit={async (
              _values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setSubmitting(true);
                unitStore.unitState = LoadingState.LOADING;
                delete _values.submit;
                unitStore.updateUnitBasicInformation(_values);
                await saveUnit();
                setStatus({ success: true });
                setSubmitting(false);
                unitStore.unitState = LoadingState.DONE;
                handleSaveUnitSuccess();
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                unitStore.unitState = LoadingState.DONE;
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  style={{
                    borderTop: '1px solid #D9DBE9', // Add this line to set the bottom border color
                    borderBottom: '#D9DBE9' // Add this line to set the bottom border color

                    // paddingTop: theme.spacing(2),
                  }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledTextField
                      variant="standard"
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      name="title"
                      placeholder={t('Unit Title')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      inputProps={{
                        'aria-label': 'Unit Title',
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledTextField
                      variant="standard"
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      name="description"
                      placeholder={t('Description')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      inputProps={{
                        'aria-label': 'Description',
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledTextField
                      variant="standard"
                      error={Boolean(touched.externalId && errors.externalId)}
                      fullWidth
                      helperText={touched.externalId && errors.externalId}
                      name="externalId"
                      placeholder={t('UNIQUE_ID')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.externalId}
                      inputProps={{
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledTextField
                      variant="standard"
                      error={Boolean(touched.footer && errors.footer)}
                      fullWidth
                      helperText={touched.footer && errors.footer}
                      name="footer"
                      placeholder={t('FOOTER')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.footer}
                      multiline
                      rows={4}
                      inputProps={{
                        'aria-label': 'Footer',
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      sx={{
                        textarea: {
                          scrollbarWidth: 'none'
                        },
                        error: {
                          borderColor: 'red' // Color for the error border
                        },
                        '& .MuiInputBase-root': {
                          borderBottom: '#D9DBE9' // Color for the default border
                        },
                        '&:hover fieldset': {
                          borderBottom: '#D9DBE9' // Color for the default border
                        },
                        '& .MuiInput-underline:hover:before': {
                          borderBottom: '#D9DBE9' // Color for the default border
                        },
                        '& .MuiInput-root:after': {
                          borderBottom: '#D9DBE9' // Color for the default border
                        }
                      }}
                    />
                  </Grid>
                  {/* <Grid
                item
                xs={12}
                sm={12}
                md={12}
              >
                <TextField
                  variant='standard'
                  error={Boolean(touched.redirectURL && errors.redirectURL)}
                  fullWidth
                  helperText={touched.redirectURL && errors.redirectURL}
                  name="redirectURL"
                  placeholder={t('UNIT_FORM_REDIRECT_URL_PLACEHOLDER')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.redirectURL}
                  label={t('UNIT_FORM_REDIRECT_URL_LABEL')}
                />
              </Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <PermissionWrapper
                      permission={PermissionKey.UPDATE_UNIT_USER}
                    >
                      <Autocomplete
                        disablePortal
                        options={users}
                        onChange={(e, value) => setFieldValue('user', value)}
                        value={values.user}
                        getOptionLabel={(label) => label.name}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={onUserInputChange}
                            placeholder="Assigned to"
                            helperText={touched.user && errors.user}
                            error={Boolean(touched.user && errors.user)}
                          />
                        )}
                      />
                    </PermissionWrapper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <PermissionWrapper
                      permission={PermissionKey.UPDATE_UNIT_CLIENT}
                    >
                      <Autocomplete
                        disablePortal
                        options={clients}
                        onChange={(e, value) => setFieldValue('client', value)}
                        value={values.client}
                        getOptionLabel={(label) => label.name}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={onClientInputChange}
                            placeholder={t('Client_here')}
                            helperText={touched.client && errors.client}
                            error={Boolean(touched.client && errors.client)}
                          />
                        )}
                      />
                    </PermissionWrapper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FileUploader
                      domain={FileDomain.UNITS}
                      value={values.imageURL}
                      name="imageURL"
                      label={t('Select Unit Image')}
                      handleBlur={handleBlur}
                      onChange={(value) => setFieldValue('imageURL', value)}
                      isValid={!Boolean(touched.imageURL && errors.imageURL)}
                      notValidMessage={
                        touched.imageURL && (errors.imageURL as string)
                      }
                    />
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </BackendResourceWrapper>
      </BaseModal>
    );
  }
);

export default SaveBasicInformation;
