import {
  Box,
  FormControlLabel,
  Grid,
  TableCell,
  TextField,
  colors,
  styled
} from '@mui/material';
import { FormTextField } from 'src/components/FormFields/FormTextField';
import { BooleanLocale } from 'yup/lib/locale';
import { TypeDropdown } from '../TypeDropdown';

export const BottomRow = styled(Box)`
  display: flex;
  flex-direction: row;
  width: inherit !important;
  align-items: baseline;
`;

export const StyledCheckbox = styled(FormControlLabel)(
  ({ theme }) => `
  margin: 0;
  .MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: ${theme.spacing(1)};
  }
`
);

export const StyledTypeField = styled(TextField)`
  [class*='MuiInput-root'] {
    padding: 0;
  }
`;

export const Container = styled(Grid)<{ $isLastItem: boolean }>`
  width: 100%;
  ${({ theme, $isLastItem }) =>
    `
  border-left: ${theme.borders.shorthand(2)};
  border-right: ${theme.borders.shorthand(2)};
  border-bottom: ${$isLastItem ? theme.borders.shorthand(2) : 'none'};
  `}
  &:hover {
    background-color: ${({ theme }) => theme.criteria.background};
  }
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const TitleContainer = styled(Grid)(
  ({ theme }) => `
  border: 2px solid transparent;
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  padding-left: ${theme.spacing(4)};
  border-bottom: ${theme.borders.shorthand(1)};
  ${theme.breakpoints.up('sm')} {
    border-right: ${theme.borders.shorthand(1)};
    border-bottom: 2px solid transparent;
  }
  &:focus-within {
    background-color: ${theme.criteria.background};
    border: 2px solid ${theme.palette.primary.main};
  }
  display: flex;
  align-items: flex-start;
  svg{
    margin-top: -2px;
  }
`
);

export const FlexContainer = styled(Grid)`
  display: flex;
  align-items: baseline;
`;

export const OptionsContainer = styled(Grid)`
  border-top: ${({ theme }) => theme.borders.shorthand(1)};
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const StyledMuiTextField = styled(FormTextField)(
  ({ theme }) => `
  &.MuiTextField-root {
    padding-top: ${theme.spacing(0.2)};
    justify-content: center;
  }
  .MuiInput-underline:not(.Mui-disabled),
  .MuiInput-underline:not(.Mui-disabled):hover {
    &:before,
    &:after {
      border: none;
    }
  }
`
);
