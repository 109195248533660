import { TextFieldProps, TextField, SelectProps } from '@mui/material';
import { useFormikContext } from 'formik';
import * as S from './styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type Props = {
  submitOnBlur?: boolean;
  className?: string;
  trim?: boolean;
  onChange?: (e) => void;
} & SelectProps;

export const FormSelect = ({
  label,
  placeholder,
  value,
  name,
  submitOnBlur = true,
  className,
  onChange,
  children,
  trim = false,
  renderValue,
  ...props
}: Props) => {
  const {
    values,
    touched,
    errors,
    submitForm,
    handleChange,
    handleBlur,
    isValid,
    dirty
  } = useFormikContext();
  return (
    <S.StyledSelect
      className={className}
      error={Boolean(touched[name] && errors[name])}
      fullWidth
      name={name}
      displayEmpty={placeholder && true}
      IconComponent={ExpandMoreIcon}
      renderValue={(selected: any) => {
        if (!selected || selected?.length == 0) {
          return placeholder;
        }
        return renderValue(selected);
      }}
      value={
        trim && values[name]?.trim().length == 0
          ? values[name]?.trim()
          : values[name]
      }
      label={label}
      onBlur={(e) => {
        handleBlur(e);
        if (submitOnBlur && isValid && dirty) submitForm();
      }}
      onChange={(e) => {
        onChange?.(e);
        handleChange(e);
      }}
      onKeyDown={(key) => {
        if (key.code == 'Enter' && submitOnBlur && isValid && dirty)
          submitForm();
      }}
      variant="outlined"
      {...props}
    >
      {children}
    </S.StyledSelect>
  );
};
