import { Box, Modal, styled } from '@mui/material';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled(Box)<{
  width?: string;
  tabletWidth?: string;
}>(
  ({ theme, width: maxWidth, tabletWidth: tabletMaxWidth }) => `
    background-color: ${theme.palette.background.paper};
    width: 100%;
    margin-inline: ${theme.spacing(2)};
    border-radius: 6px;

    ${theme.breakpoints.up('md')}{
      width: ${tabletMaxWidth ?? '80%'};
    }
    ${theme.breakpoints.up('lg')}{
      width: ${maxWidth ?? '60%'};
    }
`
);

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const Footer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2)};
`;
