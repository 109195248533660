import React, { ReactNode, useEffect } from 'react';
import * as S from './styled';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import { LogicIcon } from 'src/components/Icons/Logic';
import { ChecklistItemType } from 'src/models/unit';
import { useTranslation } from 'react-i18next';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { OptionalRules } from 'src/models/logic';
import { useFormikContext } from 'formik';
import { LOGIC_MAP } from '../../constants';

interface Props extends LogicUIProps {
  ifSection: () => React.ReactNode;
  thenSection: () => React.ReactNode;
  scoreSection: () => React.ReactNode;
  icon?: ReactNode;
  ifText?: string;
}

export interface LogicUIProps {
  required?: boolean;
  isReadOnly?: boolean;
  onDelete: () => void;
  allowedValues?: { [key: string | number]: string };
  criteriaType?: ChecklistItemType;
  className?: string;
  addNewCriteria?: any;
  activeCriteriaOrder?: any;
  submitForm?: any;
}

export const LogicUI = ({
  className,
  icon,
  ifText,
  ifSection,
  thenSection,
  scoreSection,
  onDelete,
  required,
  criteriaType,
  isReadOnly
}: Props) => {
  const { t } = useTranslation();
  const renderedScoreSection = scoreSection();
  const { values, setFieldValue, submitForm } = useFormikContext() as any;

  useEffect(() => {
    if (OptionalRules.includes(values['rule']) && required) {
      setFieldValue('rule', LOGIC_MAP[criteriaType].rules[0]);
      submitForm();
    }
  }, [required]);

  return (
    <S.LogicGrid
      container
      justifyContent="space-between"
      wrap="nowrap"
      className={className}
    >
      <Grid item xs={11}>
        <S.LeftContainer className="left-container">
          <S.Container className="if-container">
            {icon ?? <LogicIcon />}
            <S.Text variant="h5" component="div">
              {ifText ?? t('CHECKLIST_BUILDER.LOGIC.IF')}
            </S.Text>
            {ifSection()}
          </S.Container>
          <S.Container className="then-container">
            <S.Text variant="h5" component="div">
              {t('CHECKLIST_BUILDER.LOGIC.THEN')}
            </S.Text>
            {thenSection()}
          </S.Container>
          {renderedScoreSection && (
            <S.Container className="then-container">
              <S.Text variant="h5" component="div">
                {t('CHECKLIST_BUILDER.LOGIC.SCORE')}
              </S.Text>
              {renderedScoreSection}
            </S.Container>
          )}
        </S.LeftContainer>
      </Grid>
      <Grid item xs={1}>
        <ConditionalWrapper show={!isReadOnly}>
          <S.CloseButtonContainer>
            <IconButton aria-label="delete" onClick={onDelete}>
              <CloseIcon />
            </IconButton>
          </S.CloseButtonContainer>
        </ConditionalWrapper>
      </Grid>
    </S.LogicGrid>
  );
};
