import { FC, ReactNode, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationDropdown } from '../TranslationDropdown';
import useAuth from 'src/hooks/useAuth';
import { Language } from '../TranslationDropdown/types';
import { reducer } from '../../contexts/Auth/reducer';
import { initialAuthState } from '../../contexts/Auth';
import { useNavigate } from 'react-router';

interface BaseLayoutProps {
  children?: ReactNode;
}

const NotAuthenticatedTranslationLayout: FC<BaseLayoutProps> = ({
  children
}) => {
  const { i18n } = useTranslation();
  const auth = useAuth();
  const { logout } = useAuth();
  const shouldShowLogoutButton =
    (auth.user && auth.user.roles && auth.user.roles.includes('user')) ||
    location.pathname.includes('register/verification');
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const navigate = useNavigate();
  const onlanguagechange = (language: Language) => {
    localStorage.setItem('LANG', language);
    i18n.changeLanguage(language);
  };

  const handleLogout = async (): Promise<void> => {
    logout();
    dispatch({
      type: 'LOGOUT'
    });
    navigate('/');
  };

  return (
    <Box
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <TranslationDropdown
          handleLanguageChanges={onlanguagechange}
          defaultLanguage={localStorage.getItem('LANG') as Language}
        />
        {shouldShowLogoutButton && (
          <Button onClick={handleLogout} variant="contained" color="primary">
            <Typography variant="button" color="white">
              Logout
            </Typography>
          </Button>
        )}
      </Box>
      {children || <Outlet />}
    </Box>
  );
};

NotAuthenticatedTranslationLayout.propTypes = {
  children: PropTypes.node
};

export default NotAuthenticatedTranslationLayout;
