import { Alert, Box, Card, DialogContent, colors, styled } from '@mui/material';
import { FormTextField } from 'src/components/FormFields';

export const HeaderBorderBox = styled(Box)`
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.borders.color};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: calc(100% - 54px);
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const BorderBox = styled(Box)`
  border: 2px solid ${({ theme }) => theme.borders.color};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border-bottom: none;
  margin-bottom: 0;
  border-radius: 3px 3px 0 0;
`;

export const StyledMuiTextField = styled(FormTextField)`
  min-height: ${({ theme }) => theme.spacing(5)};
  padding-top: &.MuiTextField-root {
    justify-content: center;
  }
  .MuiInput-underline:not(.Mui-disabled),
  .MuiInput-underline:not(.Mui-disabled):hover {
    &:before,
    &:after {
      border: none;
    }
  }
`;

export const SectionsContainer = styled(Card)`
  ${({ theme }) => `
  border-radius: 0;
  position: relative;
  width: calc(100% - 54px);
  overflow: visible;
  box-shadow: none;
  // padding-bottom: 42px;
  ${theme.breakpoints.down('md')}{
    width: 100%;
  }
  background-color: transparent;
`}
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(4)};
  width: fit-content;
  align-self: center;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  overflow: visible;
`;
