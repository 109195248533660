import { useParams, useSearchParams } from 'react-router-dom';
import { useStores } from 'src/hooks/useMobxStores';
import { ChecklistBuilderStore } from '../store/checklist-builder.store';
import { useEffect } from 'react';

export const useChecklistStore = (): ChecklistBuilderStore => {
  const {
    backend: { checklistBuilderStore }
  } = useStores();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    checklistBuilderStore.setParams({
      templateId: +searchParams.get('templateId'),
      unitId: +searchParams.get('unitId'),
      checklistId: +searchParams.get('checklistId')
    });
  }, [searchParams]);

  return checklistBuilderStore;
};
