import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import { createPortal } from 'react-dom';
import * as S from './styled.tsx';
import { useTranslation } from 'react-i18next';

export type actionType =
  | 'DUPLICATE'
  | 'DELETE'
  | 'ADD_CRITERIA'
  | 'ADD_SECTION';

export type action = {
  type: actionType;
  icon: React.ReactNode;
  tooltipText?: string;
};

interface Props {
  actions: { [key in actionType]?: () => void };
  getActiveCategoryRef: () => any;
}

const actionsMap: (
  | {
      type: actionType;
      icon: ReactElement;
    }
  | 'divider'
)[] = [
  {
    type: 'DUPLICATE',
    icon: <ContentCopyIcon fontSize="small" />
  },
  {
    type: 'DELETE',
    icon: <DeleteOutlineOutlinedIcon fontSize="small" />
  },
  'divider',
  {
    type: 'ADD_CRITERIA',
    icon: <AddCircleOutlineIcon fontSize="small" />
  },
  {
    type: 'ADD_SECTION',
    icon: <ViewAgendaOutlinedIcon />
  }
];

export const FloatingToolbar = ({ actions, getActiveCategoryRef }: Props) => {
  const { t } = useTranslation();
  const [top, setTop] = useState(0);
  const attachToRefProp = useRef(getActiveCategoryRef());
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  useEffect(() => {
    setTimeout(() => {
      const activeCategoryRef = getActiveCategoryRef();
      if (activeCategoryRef?.current) {
        setTop(activeCategoryRef.current.offsetTop);
        attachToRefProp.current = activeCategoryRef.current;
      }
    });
  }, [getActiveCategoryRef]);

  useEffect(() => {
    const updateTop = () => {
      if (attachToRefProp?.current) {
        setTop(attachToRefProp.current.offsetTop);
      }
    };
    const onScroll = () => {
      const rootScrollableElement = document.getElementById('root-container');
      setTop(rootScrollableElement.scrollTop);
    };
    window.addEventListener('resize', updateTop);
    // document
    //   .getElementById('root-container')
    //   .addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('resize', updateTop);
      // document
      //   .getElementById('root-container')
      //   .removeEventListener('scroll', onScroll);
    };
  }, []);

  const element = (
    <S.Container sx={{ top: top }}>
      {actionsMap.map((e: any) => {
        if (e === 'divider')
          return (
            <S.StyledDivider
              orientation={isMobile ? 'vertical' : 'horizontal'}
              flexItem
              key="divider"
            />
          );
        else
          return (
            <S.DarkTooltip
              title={t(`CHECKLIST_BUILDER.FLOATING_TOOLBAR.${e.type}`)}
              placement="left-start"
            >
              <S.StyledIconButton
                onClick={() => actions[e.type]?.()}
                key={e.type}
                $type={e.type}
              >
                {e.icon}
              </S.StyledIconButton>
            </S.DarkTooltip>
          );
      })}
    </S.Container>
  );

  useEffect(() => {
    if (document.getElementById('subscriptions-banner')) {
      document.getElementById('subscriptions-banner').style.visibility =
        isMobile ? 'hidden' : 'visible';
    }
  }, [isMobile]);

  return isMobile ? createPortal(element, document.body) : element;
};
