import {
  EventParams,
  PaymentEntity,
  PaymentMethods,
  ScreenNames,
  UserProperties,
  UserPropertyValues
} from './types';

export abstract class AnalyticsEvent {
  eventParams: EventParams;

  private readonly _eventTitle: string;

  protected constructor(eventTitle: string) {
    this._eventTitle = eventTitle;
  }

  get eventTitle(): string {
    return this._eventTitle;
  }
}

export class ScreenViewedEvent extends AnalyticsEvent {
  constructor(screenName: ScreenNames, params?: EventParams) {
    super(`${screenName}_load`);
    this.eventParams = params;
  }
}

export class FormSubmittedEvent extends AnalyticsEvent {
  constructor(formName: string, params?: EventParams) {
    super(`${formName}_submitted`);
    this.eventParams = params;
  }
}

export class RegistrationEvent extends AnalyticsEvent {
  constructor(method: string, params?: EventParams) {
    super(`signup_${method}`);
    this.eventParams = params;
  }
}

export class LoginEvent extends AnalyticsEvent {
  constructor(method: string, params?: EventParams) {
    super(`login_${method}`);
    this.eventParams = params;
  }
}

export class UnitEvent extends AnalyticsEvent {
  constructor(action: string, params?: EventParams) {
    super(`unit_${action}`);
    this.eventParams = params;
  }
}

export class InspectionEvent extends AnalyticsEvent {
  constructor(action: string, params?: EventParams) {
    super(`inspection_${action}`);
    this.eventParams = params;
  }
}

export class UserEvent extends AnalyticsEvent {
  constructor(action: string, params?: EventParams) {
    super(`user_${action}`);
    this.eventParams = params;
  }
}

export class PaymentStatusEvent extends AnalyticsEvent {
  constructor(paymentEntity: PaymentEntity, params?: EventParams) {
    super(`${paymentEntity}_paid`);
    this.eventParams = params;
  }
}

export class paymentMethodEvent extends AnalyticsEvent {
  constructor(paymentMethod: PaymentMethods, paymentEntity: PaymentEntity) {
    super(`payment_${paymentMethod}_selected`);
    this.eventParams = {
      paymnentType: paymentEntity
    };
  }
}

export abstract class UserProperty {
  userPropertyValue: UserPropertyValues;

  private readonly _userPropertyName: UserProperties;

  protected constructor(userPropertyName: UserProperties) {
    this._userPropertyName = userPropertyName;
  }

  get userProperty(): string {
    return this._userPropertyName;
  }
}

// export class IsMemberUserProperty extends UserProperty {
//   constructor(propertyValue: UserPropertyValues) {
//     super(UserProperties.IS_MEMBER);
//     this.userPropertyValue = propertyValue;
//   }
// }

// export class CountryUserProperty extends UserProperty {
//   constructor(propertyValue: UserPropertyValues) {
//     super(UserProperties.COUNTRY);
//     this.userPropertyValue = propertyValue;
//   }
// }

export default AnalyticsEvent;
