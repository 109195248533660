import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import { action, makeObservable, observable, set } from 'mobx';
import * as unit from 'src/models/unit';
import * as types from '../unit/types';
import type { LogicDto } from 'src/models/logic';
import _ from 'lodash';

export class ChecklistItemsStore extends BaseBackendStore {
  @observable checklistItemsCategories: unit.ChecklistItemCategory[] = [];

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  @action
  public setChecklistItemCategories(
    checklistItemsCategories: unit.ChecklistItemCategory[]
  ) {
    this.checklistItemsCategories = checklistItemsCategories;
  }

  /**
   *
   * @param checklistId
   * @param payload
   * @description
   *  - create category
   *  - insert it locally at the beginning of the list
   *  - reorder current categories
   */
  @action
  addNewCategory(
    createdCategory: unit.ChecklistItemCategory
  ): Array<unit.ChecklistItemCategory> {
    this.checklistItemsCategories
      .filter((e) => e.order >= createdCategory.order)
      .forEach((c) => {
        c.order = c.order + 1;
      });
    this.checklistItemsCategories = _.orderBy(
      [
        ...this.checklistItemsCategories,
        {
          ...createdCategory,
          checklistItems: createdCategory.checklistItems ?? []
        }
      ],
      'order'
    );
    return this.checklistItemsCategories;
  }

  @action
  updateCategory(
    categoryId: number,
    payload: types.CategoryDto
  ): Array<unit.ChecklistItemCategory> {
    const index = this.checklistItemsCategories.findIndex(
      (cat) => cat.id === categoryId
    );
    if (index > -1) {
      this.checklistItemsCategories[index] = {
        ...this.checklistItemsCategories[index],
        ...payload
      };
    }
    return this.checklistItemsCategories;
  }

  @action
  deleteCategory(categoryId: number): Array<unit.ChecklistItemCategory> {
    this.checklistItemsCategories = this.checklistItemsCategories.filter(
      (cat) => cat.id !== categoryId
    );
    return this.checklistItemsCategories;
  }

  @action
  reorderCategories(
    categories: unit.ChecklistItemCategory[]
  ): Array<unit.ChecklistItemCategory> {
    this.checklistItemsCategories = categories;
    return this.checklistItemsCategories;
  }

  @action
  deleteChecklistItem(
    categoryId: number,
    id: number,
    logicState?: LogicDto
  ): Array<unit.ChecklistItemCategory> {
    if (!logicState) {
      const index = this.checklistItemsCategories.findIndex(
        (cat) => cat.id === categoryId
      );
      if (index > -1) {
        this.checklistItemsCategories[index] = {
          ...this.checklistItemsCategories[index],
          checklistItems: this.checklistItemsCategories[
            index
          ].checklistItems.filter((item) => +item.id !== id)
        };
      }
    } else {
      const index = logicState.subChecklistItems.findIndex((e) => e.id == id);
      if (index > -1) logicState.subChecklistItems.splice(index, 1);
    }

    return this.checklistItemsCategories;
  }

  @action
  updateChecklistItem(
    categoryId: number,
    id: number,
    checklistItem: unit.ViewChecklistItem,
    checklistItemState?: unit.ViewChecklistItem
  ): Array<unit.ChecklistItemCategory> {
    const categoryIndex = this.checklistItemsCategories.findIndex(
      (cat) => cat.id === categoryId
    );
    if (categoryIndex < 0) {
      return;
    }
    if (!checklistItemState) {
      const itemIndex = this.checklistItemsCategories[
        categoryIndex
      ].checklistItems.findIndex((item) => +item.id === id);
      this.checklistItemsCategories[categoryIndex].checklistItems[itemIndex] = {
        ...checklistItem,
        id
      };
    } else {
      set(checklistItemState, {
        mandatory: checklistItem.mandatory,
        isDeleted: checklistItem.isDeleted,
        order: checklistItem.order,
        title: checklistItem.title,
        type: checklistItem.type,
        imageURL: checklistItem.imageURL,
        instruction: checklistItem.instruction,
        checklistItemOptions: checklistItem.checklistItemOptions,
        allowMultipleOptions: checklistItem.allowMultipleOptions
      });
    }
    this.checklistItemsCategories[categoryIndex] = {
      ...this.checklistItemsCategories[categoryIndex],
      checklistItems:
        this.checklistItemsCategories[categoryIndex].checklistItems
    };

    return this.checklistItemsCategories;
  }

  @action
  addChecklistItem(
    categoryId: number,
    item: unit.ViewChecklistItem,
    logicState?: LogicDto
  ): Array<unit.ChecklistItemCategory> {
    const categoryIndex = this.checklistItemsCategories.findIndex(
      (cat) => cat.id === categoryId
    );
    if (categoryIndex < 0) {
      return;
    }
    if (!logicState) {
      this.checklistItemsCategories[categoryIndex].checklistItems
        .filter((e) => e.order >= item.order)
        .forEach((c) => {
          c.order = c.order + 1;
        });
      const orderedChecklistItems = _.orderBy(
        [
          ...this.checklistItemsCategories[categoryIndex].checklistItems,
          _.cloneDeep({
            ...item,
            checklistItemOptions: item.checklistItemOptions ?? [],
            subLogics: item.subLogics ?? []
          })
        ],
        'order'
      );
      this.checklistItemsCategories[categoryIndex] = {
        ...this.checklistItemsCategories[categoryIndex],
        checklistItems: orderedChecklistItems
      };
    } else {
      logicState.subChecklistItems = _.orderBy(
        [
          ...(logicState.subChecklistItems ?? []),
          _.cloneDeep({
            ...item,
            subLogics: item.subLogics ?? []
          })
        ],
        'order'
      );
    }
    return this.checklistItemsCategories;
  }

  @action
  reorderChecklistItems(
    categoryId: number,
    checklistItems: Array<unit.ViewChecklistItem>,
    logicState?: LogicDto
  ): Array<unit.ChecklistItemCategory> {
    if (logicState) {
      logicState.subChecklistItems = checklistItems;
    } else {
      const categoryIndex = this.checklistItemsCategories.findIndex(
        (cat) => cat.id === categoryId
      );

      if (categoryIndex > -1) {
        this.checklistItemsCategories[categoryIndex] = {
          ...this.checklistItemsCategories[categoryIndex],
          checklistItems
        };
      }
    }
    return this.checklistItemsCategories;
  }

  @action
  addLogic(criteria: unit.ViewChecklistItem, item: LogicDto) {
    // criteria.subLogics = [...(criteria.subLogics ?? []), item];
    // criteria.subLogics.push(item);
    if (criteria.type == unit.ChecklistItemType.MULTIPLE_CHOICE) {
      item.logicChecklistItemsOptions = item.values.map((e) => ({
        id: e
      })) as any;
    }
    set(criteria, {
      subLogics: [...(criteria.subLogics ?? []), item]
    });
    // console.log(criteria);
  }

  @action
  updateLogic(logicState: LogicDto, newLogic: LogicDto) {
    set(logicState, {
      ...newLogic
    });
  }

  @action
  deleteLogic(criteria: unit.ViewChecklistItem, logicId: number) {
    const index = criteria.subLogics.findIndex((e) => e.id == logicId);
    if (index > -1) criteria.subLogics.splice(index, 1);
  }
}
