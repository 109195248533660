import { Box, styled } from '@mui/material';

export const styledBox = styled(Box)`
  position: relative;
  z-index: 2;
`;

export const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  minWidth: 450,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(0.5)
}));

export const ModalBoxHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.2)
}));

export const ModalBoxFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.2),
  display: 'flex',
  justifyContent: 'flex-end',
  transform: 'none!important'
}));
