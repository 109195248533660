import { FC, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { httpPost, httpPut } from 'src/axios/axiosUtils';
import {
  fotrgetPasswordURL,
  loginURL,
  refreshTokenReq,
  signUpURL,
  emailVerificationURL,
  clients as clientsURL,
  updatePasswordURL,
  users,
  magicLinkLoginURL
} from 'src/axios/requests';
import { setSession } from 'src/content/pages/Auth/helpers/tokens';
import { AuthContextValue, AuthProviderProps, AuthState } from './types';
import { reducer } from './reducer';
import {
  LoginEvent,
  RegistrationEvent
} from 'src/hooks/useAnalytics/AnalyticsEvent';
import useAnalytics from 'src/hooks/useAnalytics';
import { SaveClientInformationDto } from 'src/content/dashboards/Clients/types';

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isConfirmWelcomeScreen: false,
  user: null
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginWithGoogle: (tokenId: string) => Promise.resolve(),
  magicLinkLogin: (token: string) => Promise.resolve(),
  registerWithGoogle: (tokenId: string) => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  forgetPassword: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  userVerification: () => Promise.resolve(),
  createUserClient: () => Promise.resolve(),
  toggleWelcomeScreen: () => {},
  refreshUserToken: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { logAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        const user = JSON.parse(window.localStorage.getItem('user'));

        if (accessToken) {
          setSession(accessToken, refreshToken, user);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const refreshUserToken = async () => {
    const response = await httpPut(
      refreshTokenReq,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
        }
      }
    );
    const { accessToken, refreshToken, user } = response;
    setSession(accessToken, refreshToken, user);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const login = async (email: string, password: string): Promise<void> => {
    const response = await httpPost(loginURL, { email, password });
    const { accessToken, refreshToken, user } = response;
    const loginEmailEvent = new LoginEvent('email', {
      email: email,
      organization: user.client.name,
      organization_id: user.client.id
    });
    logAnalyticsEvent(loginEmailEvent);
    setSession(accessToken, refreshToken, user);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const magicLinkLogin = async (token: string): Promise<void> => {
    const response = await httpPost(magicLinkLoginURL, { token });
    const { accessToken, refreshToken, user } = response;
    setSession(accessToken, refreshToken, user);
    const loginGoogleEvent = new LoginEvent('magic_link_login', {
      email: user.email,
      organization: user.client.name,
      organization_id: user.client.id
    });
    logAnalyticsEvent(loginGoogleEvent);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    return response;
  };

  const loginWithGoogle = async (tokenId: string): Promise<void> => {
    const response = await httpPost(loginURL, { oauthToken: tokenId });
    const { accessToken, refreshToken, user } = response;
    setSession(accessToken, refreshToken, user);
    const loginGoogleEvent = new LoginEvent('google', {
      email: user.email,
      organization: user.client.name,
      organization_id: user.client.id
    });
    logAnalyticsEvent(loginGoogleEvent);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    return response;
  };

  const registerWithGoogle = async (tokenId: string): Promise<void> => {
    try {
      const response = await httpPost(signUpURL, { oauthToken: tokenId });
      const { accessToken, refreshToken, user } = response;
      setSession(accessToken, refreshToken, user);
      const registerGoogleEvent = new RegistrationEvent('google', {
        email: user.email
      });
      logAnalyticsEvent(registerGoogleEvent);
      dispatch({
        type: 'REGISTER',
        payload: {
          user
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logout = async (): Promise<void> => {
    setSession(null, null, null);
    dispatch({ type: 'LOGOUT' });
  };

  const forgetPassword = async (email: string): Promise<void> => {
    await httpPost(fotrgetPasswordURL, { email });
    dispatch({ type: 'FORGET_PASSWORD' });
  };
  const updatePassword = async (
    token: string,
    updatePasswordBody: { password: string; confirmPassword: string }
  ): Promise<void> => {
    await httpPut(`${updatePasswordURL}?token=${token}`, updatePasswordBody);
    dispatch({ type: 'UPDATE_PASSWORD' });
  };

  const userVerification = async (token: string): Promise<void> => {
    const response = await httpPost(
      `${emailVerificationURL}?token=${token}`,
      {}
    );
    console.log('vavaacacaca: ', JSON.stringify(response));
    const { accessToken, refreshToken, user } = response;
    const registerVerifiedEvent = new RegistrationEvent('verified', {
      email: user.email
    });
    logAnalyticsEvent(registerVerifiedEvent);
    setSession(accessToken, refreshToken, user);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    await httpPost(signUpURL, {
      email,
      name,
      password
    });
    const registerEmailEvent = new RegistrationEvent('email', {
      email
    });
    logAnalyticsEvent(registerEmailEvent);
  };

  const toggleWelcomeScreen = (toggled: boolean) => {
    dispatch({
      type: 'WELCOME',
      payload: {
        isConfirmWelcomeScreen: toggled
      }
    });
  };

  const createUserClient = async (company: SaveClientInformationDto) => {
    try {
      const userId = state.user.id;
      const { accessToken, refreshToken, user } = await httpPost(clientsURL, {
        name: company.companyName,
        type: company.companyType,
        industry: company.companyIndustry,
        communication: company.companyCommunication,
        phone: company.companyPhone,
        userId
      });
      console.log('createUserClient user token: ', JSON.stringify(user));
      setSession(accessToken, refreshToken, user);
      const registerClientEvent = new RegistrationEvent('organization', {
        organization: company.companyName,
        organization_id: user.client.id
      });
      logAnalyticsEvent(registerClientEvent);
      dispatch({
        type: 'UPDATE_COMPANY',
        payload: {
          user,
          isConfirmWelcomeScreen: false
        }
      });
    } catch (err) {
      return;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginWithGoogle,
        registerWithGoogle,
        logout,
        register,
        forgetPassword,
        updatePassword,
        userVerification,
        toggleWelcomeScreen,
        createUserClient,
        refreshUserToken,
        magicLinkLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
