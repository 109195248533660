import { Box, Chip, styled } from '@mui/material';
import { FormSelect } from 'src/components/FormFields/FormSelect';
import { LogicUI } from '../..';
import { FormTextField } from 'src/components/FormFields';

const getColor = (type, theme: any) => {
  switch (type) {
    case 'YES':
    case 'GREAT':
      return theme.chips.primary.main;
    case 'NO':
    case 'DAMAGED':
      return theme.chips.secondary.main;
    case 'POOR':
      return theme.chips.secondary.light;
    case 'GOOD':
      return theme.chips.primary.light;
    default:
      return theme.chips.tertiary.main;
  }
};
export const StyledChip = styled(Chip)<{ $type }>(({ theme, $type }) => ({
  color: getColor($type, theme),
  borderColor: getColor($type, theme)
}));

export const ValuesSelect = styled(FormSelect)`
  ${({ theme }) => `
min-width: 200px;
max-width: 300px;
&.MuiOutlinedInput-root {
  max-height: none;
  .MuiSelect-select{
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
  }
}

${theme.breakpoints.down('lg')} {
  flex-grow: 2;
  max-width: none;
}
`}
`;

export const ChoiceContainer = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  flex-wrap: wrap;
  // overflow: hidden;
`;

export const StyledLogicUI = styled(LogicUI)`
  .left-container {
    align-items: start;
  }
  .if-container {
    align-items: start;
    > svg {
      margin-top: 6px;
    }
    > .MuiTypography-root {
      margin-top: 8px;
    }
  }
`;

export const ValueTextField = styled(FormTextField)`
  ${({ theme }) => `
    max-width: 300px;
  ${theme.breakpoints.down('md')} {
    flex-grow: 2;
    max-width: none;
  }
  `}
`;
