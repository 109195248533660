import { action, makeObservable, observable } from 'mobx';
import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from 'src/axios/axiosUtils';
import { unitChecklistCategories } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import type { LogicDto } from 'src/models/logic';
import type { ChecklistItemCategory, ViewChecklistItem } from 'src/models/unit';
import { Stores } from 'src/stores';
import { ChecklistStore } from 'src/stores/backend/checklist';
import * as types from 'src/stores/backend/checklist/types';
import type { UpdateChecklistParams } from 'src/stores/backend/checklist/types';
import { ChecklistItemsStore } from 'src/stores/backend/checklistItems';
import { TemplateStore } from 'src/stores/backend/template';
import { BaseBackendStore } from 'src/stores/backend/types';
import type { CategoryDto, Reorder } from 'src/stores/backend/unit/types';

export interface ChecklistBuilderUrlParams {
  unitId?: number;
  checklistId?: number;
  templateId?: number;
  categoryId?: number;
}

export class ChecklistBuilderStore extends BaseBackendStore {
  @observable checklist: types.GetChecklistDto;

  @observable params: ChecklistBuilderUrlParams;

  @observable checklistState: LoadingState = LoadingState.IDLE;

  @observable getChecklistState: LoadingState = LoadingState.IDLE;

  private checklistItemsStore: ChecklistItemsStore;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
    this.checklistItemsStore = new ChecklistItemsStore(rootStore);
  }

  @action
  async setParams(params: ChecklistBuilderUrlParams) {
    return (this.params = params);
  }

  @action
  async clearChecklist() {
    return (this.checklist = undefined);
  }

  @action
  async getChecklist(params?: ChecklistBuilderUrlParams) {
    return this.doRequest(
      async () => {
        this.getChecklistState = LoadingState.LOADING;
        this.checklist = await httpGet(
          this.buildUrl(
            { ...this.params, ...params },
            `${this.getChecklistBaseUrl()}`
          ),
          false,
          {
            headers: {
              'request-version': '2'
            }
          }
        );
        this.checklistItemsStore.setChecklistItemCategories(
          this.checklist.checklistItemsCategories
        );
        this.getChecklistState = LoadingState.DONE;
        return this.checklist;
      },
      () => {
        this.getChecklistState = LoadingState.FAILED;
      }
    );
  }

  @action
  async updateUnitChecklist(
    { title, description, imageURL }: UpdateChecklistParams,
    params?: ChecklistBuilderUrlParams
  ) {
    this.doRequest(async () => {
      await httpPut(
        this.buildUrl({ ...this.params, ...params }, `${this.baseUrl()}`),
        {
          title,
          description,
          imageURL
        }
      );
    });
  }

  @action
  async addNewCategory(
    payload: CategoryDto,
    params?: ChecklistBuilderUrlParams
  ) {
    return this.doRequest(async () => {
      const createdCategory: ChecklistItemCategory = await httpPost(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.baseUrl()}/categories`
        ),
        payload
      );
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.addNewCategory(createdCategory);
      return createdCategory;
    });
  }

  @action
  async updateCategory(
    payload: CategoryDto,
    params?: ChecklistBuilderUrlParams
  ) {
    this.doRequest(async () => {
      const result = await httpPut(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.baseUrl()}/categories/:categoryId`
        ),
        payload
      );
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.updateCategory(params.categoryId, payload);
      return result;
    });
  }

  @action
  async deleteCategory(params?: ChecklistBuilderUrlParams) {
    this.doRequest(async () => {
      await httpDelete(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.baseUrl()}/categories/:categoryId`
        )
      );
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.deleteCategory(params.categoryId);
    });
  }

  @action
  async reorderCategories(
    categories: ChecklistItemCategory[],
    params?: ChecklistBuilderUrlParams
  ) {
    const fallbackCategoriesOrder = [
      ...this.checklist.checklistItemsCategories
    ];

    this.doRequest(
      async () => {
        this.checklist.checklistItemsCategories =
          this.checklistItemsStore.reorderCategories(categories);
        const ordered = this.checklist.checklistItemsCategories.map(
          ({ id, order }) => ({
            id,
            order
          })
        );
        return httpPatch(
          this.buildUrl(
            { ...this.params, ...params },
            `${this.baseUrl()}/categories`
          ),
          ordered
        );
      },
      () => {
        this.checklist.checklistItemsCategories = fallbackCategoriesOrder;
      }
    );
  }

  /**Checklist items */
  @action
  async deleteChecklistItem(
    id: number,
    logicState?: LogicDto,
    params?: ChecklistBuilderUrlParams
  ) {
    return this.doRequest(async () => {
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.deleteChecklistItem(
          params.categoryId,
          id,
          logicState
        );
      await httpDelete(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.baseUrl()}/categories/:categoryId/checklist-items/${id}`
        )
      );
    });
  }

  @action
  async updateChecklistItem(
    id: number,
    checklistItem: ViewChecklistItem,
    checklistItemState?: ViewChecklistItem,
    params?: ChecklistBuilderUrlParams
  ) {
    return this.doRequest(async () => {
      const result = await httpPut(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.baseUrl()}/categories/:categoryId/checklist-items/${id}`
        ),
        checklistItem
      );
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.updateChecklistItem(
          params.categoryId,
          id,
          result,
          checklistItemState
        );
      return result;
    });
  }

  @action
  async addChecklistItem(
    item: ViewChecklistItem,
    logicState?: LogicDto,
    params?: ChecklistBuilderUrlParams
  ) {
    return this.doRequest(async () => {
      const { subLogics, ...itemWithoutSubLogics }: ViewChecklistItem = item;

      const { id, order, checklistItemOptions }: ViewChecklistItem =
        await httpPost(
          this.buildUrl(
            { ...this.params, ...params },
            `${this.baseUrl()}/categories/:categoryId/checklist-items`
          ),
          itemWithoutSubLogics,
          {
            headers: {
              logicId: logicState?.id.toString()
            }
          }
        );

      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.addChecklistItem(
          params.categoryId,
          { ...item, id, checklistItemOptions },
          logicState
        );
      return { id, order };
    });
  }

  @action
  async duplicateChecklistItem(
    item: ViewChecklistItem,
    logicState?: LogicDto,
    params?: ChecklistBuilderUrlParams
  ) {
    return this.doRequest(async () => {
      const { subLogics, ...itemToAdd } = item;
      const result: ViewChecklistItem = await httpPost(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.baseUrl()}/categories/:categoryId/checklist-items`
        ),
        itemToAdd,
        {
          headers: {
            logicId: logicState?.id.toString()
          }
        }
      );

      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.addChecklistItem(
          params.categoryId,
          { ...result },
          logicState
        );
      return result;
    });
  }

  @action
  async reorderChecklistItems(
    checklistItems: Array<ViewChecklistItem>,
    logicState?: LogicDto,
    params?: ChecklistBuilderUrlParams
  ) {
    const categoryIndex = this.checklist.checklistItemsCategories.findIndex(
      (cat) => cat.id === params.categoryId
    );
    const fallbackChecklistItems = [
      ...this.checklist.checklistItemsCategories[categoryIndex].checklistItems
    ];

    this.doRequest(
      async () => {
        this.checklist.checklistItemsCategories =
          this.checklistItemsStore.reorderChecklistItems(
            params.categoryId,
            checklistItems,
            logicState
          );

        const checklistItemsOrdered: Reorder[] = (
          logicState
            ? logicState.subChecklistItems
            : this.checklist.checklistItemsCategories[categoryIndex]
                .checklistItems
        ).map(({ order, id }) => ({ order, id }));
        await httpPatch(
          this.buildUrl(
            { ...this.params, ...params },
            `${this.baseUrl()}/categories/:categoryId/checklist-items`
          ),
          checklistItemsOrdered
        );
      },
      () => {
        this.checklist.checklistItemsCategories[categoryIndex] = {
          ...this.checklist.checklistItemsCategories[categoryIndex],
          checklistItems: fallbackChecklistItems
        };
      }
    );
  }

  /**Logics */
  @action
  async addLogic(
    checklistItemState: ViewChecklistItem,
    item: LogicDto,
    params?: ChecklistBuilderUrlParams
  ) {
    this.doRequest(async () => {
      const { id }: LogicDto = await httpPost(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.logicsBaseUrl()}/logics/checklistItems/${
            checklistItemState.id
          }`
        ),
        item
      );
      this.checklistItemsStore.addLogic(checklistItemState, {
        ...item,
        id
      });
    });
  }

  @action
  async updateLogic(
    logicState: LogicDto,
    newLogic: LogicDto,
    params?: ChecklistBuilderUrlParams
  ) {
    this.doRequest(async () => {
      await httpPut(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.logicsBaseUrl()}/logics/${logicState.id}`
        ),
        newLogic
      );

      this.checklistItemsStore.updateLogic(logicState, {
        ...newLogic,
        logicChecklistItemsOptions: newLogic.values.map((e) => ({
          id: e
        })) as any
      });
    });
  }

  @action
  async deleteLogic(
    checklistItemState: ViewChecklistItem,
    logicId: number,
    params?: ChecklistBuilderUrlParams
  ) {
    this.doRequest(async () => {
      await httpDelete(
        this.buildUrl(
          { ...this.params, ...params },
          `${this.logicsBaseUrl()}/logics/${logicId}`
        )
      );
      this.checklistItemsStore.deleteLogic(checklistItemState, logicId);
    });
  }

  private async doRequest(request: () => Promise<any>, onError?: () => void) {
    try {
      this.checklistState = LoadingState.LOADING;
      const result = await request?.();
      this.checklistState = LoadingState.DONE;
      return result;
    } catch (err) {
      console.log(err);
      this.checklistState = LoadingState.FAILED;
      onError?.();
      throw err;
    }
  }

  private buildUrl = (
    params: ChecklistBuilderUrlParams,
    fullUrl: string
  ): string => {
    let result = fullUrl;
    if (params.templateId)
      result = result.replace(':templateId', params.templateId.toString());
    if (params.unitId)
      result = result.replace(':unitId', params.unitId.toString());
    if (params.checklistId)
      result = result.replace(':checklistId', params.checklistId.toString());
    if (params.categoryId)
      result = result.replace(':categoryId', params.categoryId.toString());
    return result;
  };

  private baseUrl = () => {
    return this.params?.templateId && true
      ? '/templates/:templateId'
      : '/units/:unitId/checklists/:checklistId';
  };

  private getChecklistBaseUrl = () => {
    return this.params?.templateId && true
      ? '/templates/:templateId'
      : '/checklists/:checklistId';
  };

  private logicsBaseUrl = () => {
    return this.params?.templateId && true
      ? '/templates/:templateId'
      : '/units/:unitId';
  };
}
