import { Zoom } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { emitter } from '../../axios/axios';

const ErrorBoundary: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    emitter.on('Error', ({ message }) => {
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        autoHideDuration: 3000,
        TransitionComponent: Zoom
      });
    });
  }, []);

  return <>{children}</>;
};

export default ErrorBoundary;
