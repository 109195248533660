import { makeObservable, observable } from 'mobx';
import { httpGet, httpPost, httpPut } from 'src/axios/axiosUtils';
import { tokens, unitChecklistTokens, units } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { GenerateUnitTokenDto, Token, UpdateTokenDto } from 'src/models/token';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';

const addMinutes = function (date: Date, minutes: number) {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

export class InspectionTokenStore extends BaseBackendStore {
  @observable tokens: Token[] = [];

  @observable createInspectionTokenState: LoadingState = LoadingState.IDLE;

  @observable updateInspectionTokenState: LoadingState = LoadingState.IDLE;

  @observable getInspectionTokenState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  async getChecklistTokens(checklistId: number) {
    try {
      this.getInspectionTokenState = LoadingState.LOADING;
      const res: Partial<Token[]> = await httpGet(
        `${unitChecklistTokens(checklistId)}`
      );
      this.tokens = res.map((t) => ({
        ...t,
        createdAt: new Date(t.createdAt),
        expirationDate: addMinutes(new Date(t.createdAt), t.expirationMinutes)
      }));
      this.getInspectionTokenState = LoadingState.DONE;
    } catch (err) {
      console.log('error', err);
      this.getInspectionTokenState = LoadingState.FAILED;
    }
  }

  async generateToken(token: GenerateUnitTokenDto) {
    try {
      this.createInspectionTokenState = LoadingState.LOADING;

      const response = await httpPost(`${tokens}`, token);
      this.createInspectionTokenState = LoadingState.DONE;
      return response;
    } catch (error) {
      this.createInspectionTokenState = LoadingState.FAILED;
    }
  }

  async updateInspectionToken(token: UpdateTokenDto) {
    try {
      this.updateInspectionTokenState = LoadingState.LOADING;
      await httpPut(`${tokens}/${token.id}`, token);
      this.updateInspectionTokenState = LoadingState.DONE;
    } catch (err) {
      console.log('error', err);
      this.updateInspectionTokenState = LoadingState.FAILED;
    }
  }
}
