import React, { useEffect, useState } from 'react';
import { StoresContext } from 'src/contexts/mobxStores';
import { Stores } from 'src/stores';

interface StoresProviderProps {
  getRootStore: (store: Stores) => void;
}

export const StoresProvider: React.FC<StoresProviderProps> = (props) => {
  const [rootStore] = useState(new Stores());

  const { children, getRootStore } = props;

  useEffect(() => {
    getRootStore(rootStore);
  }, [rootStore]);

  return (
    <StoresContext.Provider
      value={{
        stores: rootStore
      }}
    >
      {children}
    </StoresContext.Provider>
  );
};
