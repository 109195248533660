import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Typography, Container, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg'
};

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  () => `
  padding: 0 0 0 0px;
  width: 100%;
  display: flex;
  align-items: center;
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Content>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                  mx: 2,
                  pt: 1,
                  position: 'relative'
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 120,
                    width: 120
                  }}
                  alt="Logo."
                  src="/static/images/logo/checkfirst-Icon-logo-dark.png"
                />
              </Box>

              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('CHECKFIRST')}
                </Typography>
              </Box>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              >
                {method === 'JWT' && <JWTLogin />}
              </GoogleOAuthProvider>
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
