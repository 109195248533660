import { LogicUI, LogicUIProps } from '../..';
import { useTranslation } from 'react-i18next';
import { LOGIC_MAP, LogicForm } from '../../../../constants';
import { MenuItem } from '@mui/material';
import * as S from './styled';
import * as SS from '../../styled';
import { useFormikContext } from 'formik';
import { OptionalRules } from 'src/models/logic';
import { useEffect, useState } from 'react';

export const Text = ({
  required,
  onDelete,
  isReadOnly,
  criteriaType,
  addNewCriteria,
  activeCriteriaOrder,
  submitForm
}: LogicUIProps) => {
  const { t } = useTranslation();

  const {
    values,
    handleChange,
    setFieldValue
  }: {
    values: LogicForm;
    handleChange: any;
    setFieldValue: any;
  } = useFormikContext() as any;

  useEffect(() => {
    if (values.rule === 'IS_EMPTY') {
      setFieldValue('action', 'SCORING');
    } else {
      setFieldValue('action', values.action || 'FLAG_REPORT');
    }
  }, [values.rule, setFieldValue]);

  const [pendingAddCriteria, setPendingAddCriteria] = useState(false);

  const handleActionChange = async (e: any) => {
    const selectedValue = e.target.value;
    await setFieldValue('action', selectedValue);
    if (selectedValue === 'ADD_CRITERIA') {
      setPendingAddCriteria(true);
      await submitForm();
    }
  };

  useEffect(() => {
    if (pendingAddCriteria) {
      addNewCriteria();
      setPendingAddCriteria(false);
    }
  }, [pendingAddCriteria]);

  return (
    <LogicUI
      required={required}
      criteriaType={criteriaType}
      ifSection={() => (
        <>
          <S.RuleSelect
            submitOnBlur
            name="rule"
            placeholder={t('CHECKLIST_BUILDER.CRITERIA.TYPE_PLACEHOLDER')}
            fullWidth={false}
            renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
            disabled={isReadOnly}
          >
            {LOGIC_MAP.TEXT.rules
              .filter((e) => !OptionalRules.includes(e) || !required)
              .map((e) => (
                <MenuItem key={e} value={e}>
                  {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
                </MenuItem>
              ))}
          </S.RuleSelect>
          {values['rule'] && !OptionalRules.includes(values['rule']) && (
            <S.ValueTextField
              submitOnBlur
              type="text"
              variant="outlined"
              fullWidth={false}
              name="values"
              helperText=""
              handleChange={(e) => {
                const newValue = e?.target?.value;
                handleChange({
                  ...e,
                  target: {
                    value: [newValue],
                    name: 'values'
                  }
                });
              }}
              value={values?.['values']?.[0]}
              disabled={isReadOnly}
            />
          )}
        </>
      )}
      thenSection={() => (
        <SS.RuleSelect
          submitOnBlur
          fullWidth={false}
          name="action"
          placeholder={t('CHECKLIST_BUILDER.LOGIC.ACTION_PLACEHOLDER')}
          displayEmpty
          renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
          disabled={isReadOnly}
          onChange={handleActionChange}
        >
          {LOGIC_MAP.NUMBER.actions
            .filter(
              (action) =>
                !(values['rule'] === 'IS_EMPTY' && action === 'FLAG_REPORT')
            )
            .map((e) => (
              <MenuItem key={e} value={e}>
                {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
              </MenuItem>
            ))}
        </SS.RuleSelect>
      )}
      scoreSection={() =>
        values.action === 'SCORING' ? (
          <S.ValueTextField
            submitOnBlur
            type="text"
            variant="outlined"
            fullWidth={false}
            name="score"
            helperText=""
            value={values.score}
            disabled={isReadOnly}
          />
        ) : null
      }
      onDelete={onDelete}
      isReadOnly={isReadOnly}
    />
  );
};
