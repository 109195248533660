import { Box, Button, Divider, Grid, styled } from '@mui/material';
import { FormTextField } from 'src/components/FormFields';

export const BodyContainer = styled('div')(
  ({ theme }) => `
  padding-inline: ${theme.spacing(2)};
  border-top: ${theme.borders.shorthand(1)};
  border-bottom: ${theme.borders.shorthand(1)};

  .draggable-list-container{
    max-height: 40vh;
    overflow-y: auto;
  }
`
);

export const StyledMuiTextField = styled(FormTextField)`
  min-height: ${({ theme }) => theme.spacing(5)};
  &.MuiTextField-root {
    justify-content: center;
  }
  .MuiInput-underline:not(.Mui-disabled),
  .MuiInput-underline:not(.Mui-disabled):hover {
    &:before,
    &:after {
      border: none;
    }
  }
`;

export const ScoringTextField = styled(FormTextField)`
  .MuiInput-underline:not(.Mui-disabled),
  .MuiInput-underline:not(.Mui-disabled):hover {
    &:before,
    &:after {
      border: none;
    }
  }
  .MuiOutlinedInput-input.MuiInputBase-input {
    padding: 0;
    min-height: 23px;
    text-align: center;
  }
  max-width: 30px;
`;
export const TextFieldContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledButton = styled(Button)`
  &:hover {
    background-color: transparent;
  }
  &.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    padding: 0;
    padding-left: ${({ theme }) => theme.spacing(0.8)};
    gap: ${({ theme }) => theme.spacing(1.3)};
  }
  justify-content: start;
`;
export const AddNewButtonContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)} 0;
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

export const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.buttons.primary.background};
  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.hover};
  }
`;

export const OptionGridRow = styled(Grid)`
  margin: 0;
  width: 100%;
  div.MuiGrid-root.MuiGrid-item {
    padding: 0;
  }
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  &:focus-within {
    background-color: ${({ theme }) => theme.formFields.active};
  }
`;

export const ContainerGrid = styled(Grid)`
  margin: ${({ theme }) => theme.spacing(2)} 0;
  width: 100%;
  div.MuiGrid-root.MuiGrid-item {
    padding: 0;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
  max-height: 40px;
`;

export const FooterContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
