import { FC } from 'react';
import { LoadingState } from 'src/axios/types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, CircularProgress } from '@mui/material';

interface Props {
  states: Array<LoadingState>;
  showChildrenOnError?: boolean;
  loadingNode?: JSX.Element;
}
const BackendResourceWrapper: FC<Props> = (props) => {
  const { states, showChildrenOnError = false, children, loadingNode } = props;
  const { t }: { t: any } = useTranslation();

  if (states.includes(LoadingState.LOADING)) {
    if (loadingNode) {
      return <>{loadingNode}</>;
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 5
          }}
        >
          <CircularProgress />
        </Box>
      </div>
    );
  }

  if (states.includes(LoadingState.FAILED)) {
    return (
      <>
        {showChildrenOnError && children}
        <Alert severity="error">{t('ERROR')}</Alert>
      </>
    );
  }

  if (states.includes(LoadingState.IDLE)) {
    return null;
  }

  return <>{children}</>;
};

export default BackendResourceWrapper;
