import { useEffect, useRef, useState } from 'react';
import * as S from './styled';

interface Props {
  title: string | number;
  width?: string;
  lineHeight?: any;
  headVar?: any;
}

export const OverflownText = ({
  title,
  width = '100%',
  headVar = 'h3',
  lineHeight
}: Props) => {
  const ref = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current!;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <>
      <S.LightTooltip title={title} disableHoverListener={!isOverflown}>
        <S.StyledTypography
          variant={headVar}
          width={width}
          lineHeight={lineHeight}
          ref={ref}
        >
          {title}
        </S.StyledTypography>
      </S.LightTooltip>
    </>
  );
};
