import { FC, useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Helmet } from 'react-helmet-async';
import { LoadingState } from 'src/axios/types';
import BackendResourceWrapper from 'src/components/BackendResourceWraper';
import { useStores } from 'src/hooks/useMobxStores';
import { observer } from 'mobx-react';

import { useParams } from 'react-router';

const UnitForm: FC = observer(() => {
  const { unitId } = useParams();
  const {
    backend: { unit: unitStore }
  } = useStores();

  useEffect(() => {
    (async () => {
      if (unitId) {
        unitStore.getUnit(+unitId);
      } else {
        unitStore.unitState = LoadingState.DONE;
      }
    })();
  }, [unitId]);

  useEffect(() => {
    return () => {
      unitStore.resetUnit();
    };
  }, []);

  return (
    <BackendResourceWrapper states={[unitStore.unitState]}>
      <Helmet>
        {unitStore.unit ? (
          <title>
            Edit - {unitStore.unit ? unitStore.unit.title : 'Unit'}{' '}
          </title>
        ) : (
          <title>units</title>
        )}
      </Helmet>
      <PageTitleWrapper>{/* <PageHeader unit={unit} /> */}</PageTitleWrapper>
      {/* <SaveBasicInformation unit={unitStore.unit} /> */}
    </BackendResourceWrapper>
  );
});

export default UnitForm;
