import { Box, styled } from '@mui/material';

export const StyledContainer = styled(Box)`
  padding: 0 ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 ${({ theme }) => theme.spacing(2)};
  }
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;
