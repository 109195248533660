import instance from './axios';
import axios, { AxiosRequestConfig } from 'axios';

const accessToken = null;

export const httpGet = async (
  requestPath: string,
  withToken: Boolean = true,
  config?: AxiosRequestConfig
): Promise<any> => {
  var axiosResponse;
  // console.log(withToken);
  if (withToken) {
    axiosResponse = await instance.get(requestPath, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      },
      ...config
    });
    // console.debug(axiosResponse);
    // console.debug();
  } else {
    axiosResponse = await instance.get(requestPath, {
      ...config
    });
    // console.debug(axiosResponse);
  }

  return axiosResponse.data;
};

export const httpPost = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  // console.log(data);
  // console.log(requestPath);
  const axiosResponse = await instance.post(requestPath, data, {
    ...config
  });
  return axiosResponse.data;
};

export const httpPut = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.put(requestPath, data, {
    ...config
  });
  return axiosResponse.data;
};

export const httpPatch = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.patch(requestPath, data, {
    ...config
  });
  return axiosResponse.data;
};

export const httpDelete = async (
  requestPath: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.delete(requestPath, {
    headers: {
      Authorization: 'Bearer ' + accessToken
    },
    ...config
  });
  return axiosResponse.data;
};

export const httpUploadFile = (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): any => {
  return instance.post(requestPath, data, {
    headers: {
      Authorization: 'Bearer ' + accessToken
    },
    ...config
  });
};

export const uploadFileToS3 = (presignedPostData, file) => {
  console.log('upload url', presignedPostData);

  const instance = axios.create();

  var config = {
    method: 'put',
    url: presignedPostData,
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    data: file
  };

  // // create a form obj˝
  // const formData = new FormData();
  // console.warn("file to upload", file);
  // // append the file
  // formData.append("file", file);

  // post the data on the s3 url
  return instance(config as AxiosRequestConfig<any>);
};
