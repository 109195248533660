import { SortType } from 'src/models/sort';

export const toggleSortOrder = (
  sortState: { key: any; sort?: SortType; order?: SortType },
  targetKey: any
) => {
  let newSort = { ...sortState };
  if (newSort.key == targetKey) {
    newSort.sort = newSort.sort == SortType.asc ? SortType.desc : SortType.asc;
    newSort.order =
      newSort.order == SortType.asc ? SortType.desc : SortType.asc;
  } else {
    newSort = {
      key: targetKey,
      sort: SortType.desc,
      order: SortType.desc
    };
  }
  return newSort;
};
