import { Outlet, PartialRouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import dashboardsRoutes from './dashboards';
import BaseLayout from 'src/layouts/BaseLayout';
import baseRoutes from './base';
import { lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { DashboardInterceptor } from './dashbaord.interceptor.index';
import NotAuthenticatedTranslationLayout from 'src/components/NotAuthenticatedTranslationLayout';
import Modules from 'src/modules';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const UpdatePassword = Loader(
  lazy(() => import('src/content/pages/Auth/UpdatePassword'))
);
const UpdatePasswordSuccess = Loader(
  lazy(() => import('src/content/pages/Auth/UpdatePassword/success.screen'))
);
const ResetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/resetPassword'))
);
const ResetPasswordSuccess = Loader(
  lazy(() => import('src/content/pages/Auth/resetPassword/success.screen'))
);
const RegisterSuccessScreen = Loader(
  lazy(() => import('src/content/pages/Auth/Register/success.screen'))
);
const Login = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));

const Register = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Cover'))
);

const RegisterVerification = Loader(
  lazy(
    () =>
      import(
        'src/content/pages/Auth/Register/verification/RegisterVerificationJWT'
      )
  )
);

const Report = Loader(
  lazy(() => import('src/content/dashboards/reports/report'))
);

const MagicLinkLogin = Loader(
  lazy(() => import('src/content/dashboards/magic-link-login'))
);

const router: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '/',
    element: <NotAuthenticatedTranslationLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="login" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'magic-links/:magicLinkToken',
        element: <MagicLinkLogin />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'register/verification',
        element: <RegisterVerification />
      },
      {
        path: 'updatePassword',
        element: <UpdatePassword />
      },
      {
        path: 'updatePassword/success',
        element: <UpdatePasswordSuccess />
      },
      {
        path: 'resetPassword',
        element: <ResetPassword />
      },
      {
        path: 'resetPassword/success',
        element: <ResetPasswordSuccess />
      },
      {
        path: 'register/:email/success',
        element: <RegisterSuccessScreen />
      },
      {
        path: 'r/:reportId/:token',
        element: <Report isPublic={true} />
      }
    ]
  },
  {
    path: 'checkfirst',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <DashboardInterceptor />,
        children: dashboardsRoutes
      }
    ]
  },
  ...Modules.map((e) => e.routes)
];

export default router;
