import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChecklistItemType, ViewChecklistItem } from 'src/models/unit';
import { CriteriaForm } from './components/CriteriaForm';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { Zoom } from '@mui/material';
import { observer } from 'mobx-react';
import ConfirmationDialog from 'src/components/Dialogs/confirmationDialog';
import { useActionMenu } from 'src/hooks/useActionMenu';
import { ActionsMenu } from 'src/components/ActionsMenu';
import { Logic } from '../Logic';
import * as S from './styled';
import { LogicDto } from 'src/models/logic';
import { INITIAL_VALUES_MAP } from '../Logic/constants';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { FeatureStatus } from 'src/models/feature.toggle';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import {
  StyledIconButton,
  StyledMoreVertIcon
} from 'src/components/ActionsMenu/styled';
import item from 'src/layouts/ExtendedSidebarLayout/Sidebar/SidebarMenu/item';
import { Instructions } from '../Instructions';
import { mapChecklistItem } from './utils';
import { useStores } from 'src/hooks/useMobxStores';
import { useChecklistStore } from 'src/modules/ChecklistBuilder/hooks/useChecklistStore';

export const ALLOWED_LEVELS = 4;

interface Props {
  sectionId?: number;
  item: ViewChecklistItem;
  highestOrder: number;
  dragHandleProps: any;
  active: boolean;
  onClick: () => void;
  parentLogicState?: LogicDto;
  level?: number;
  isLastItem?: boolean;
  isReadOnly?: boolean;
  onDuplicate: (newItem: ViewChecklistItem) => void;
  onDelete: () => void;
  onEnterKeyDown: () => void;
}

const Criteria: FC<Props> = observer(
  ({
    level = 0,
    sectionId,
    item,
    highestOrder,
    dragHandleProps,
    parentLogicState,
    isLastItem = false,
    isReadOnly,
    active,
    onClick,
    onDuplicate,
    onDelete,
    onEnterKeyDown
  }: Props) => {
    const inputRef = useRef();
    if (item.isDeleted) return null;
    const {
      t
    }: {
      t: any;
    } = useTranslation();
    const checklistBuilderStore = useChecklistStore();
    const { enqueueSnackbar } = useSnackbar();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isFormActive, setIsFormActive] = useState(false);

    const duplicate = () => {
      const request = checklistBuilderStore.duplicateChecklistItem(
        {
          ...mapChecklistItem(item, highestOrder + 1),
          subLogics: item.subLogics,
          copyof: item.id
        },
        parentLogicState,
        {
          categoryId: sectionId
        }
      );
      request.then((res) => {
        onDuplicate?.(res);
        handleSuccess();
      });
    };

    const update = async (values, deleteSubLogics) => {
      if (deleteSubLogics) {
        await Promise.all(item.subLogics.map((e) => onDeleteLogic(e.id)));
      }
      const request = checklistBuilderStore.updateChecklistItem(
        item.id,
        mapChecklistItem(values),
        item,
        {
          categoryId: sectionId
        }
      );
      request.then(handleSuccess);
    };

    const handleSuccess = () => {
      enqueueSnackbar(t('CHECKLIST_BUILDER.SAVED_MESSAGE'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom,
        autoHideDuration: 2000
      });
    };

    const deleteQuestion = () => {
      const request = checklistBuilderStore.deleteChecklistItem(
        item.id,
        parentLogicState,
        {
          categoryId: sectionId
        }
      );
      request.then(() => {
        onDelete();
        handleSuccess();
      });
    };

    const addLogic = () => {
      const newLogic: LogicDto = {
        ...INITIAL_VALUES_MAP[item.type],
        // actionType: undefined as any,
        // rule: undefined,
        // values: [],
        subChecklistItems: []
      };
      const request = checklistBuilderStore.addLogic(item, newLogic);
      request.then(handleSuccess);
    };

    const onDeleteLogic = (id: number) => {
      return checklistBuilderStore.deleteLogic(item, id);
    };

    const { items: actionMenuItems } = useActionMenu({
      callbacks: {
        addLogic:
          level < ALLOWED_LEVELS &&
          ![
            ChecklistItemType.MULTI_MEDIA,
            ChecklistItemType.DATE,
            ChecklistItemType.DISCLAIMER,
            ChecklistItemType.SIGNATURE,
            ChecklistItemType.TIME
          ].includes(item.type)
            ? addLogic
            : undefined,
        addMedia:
          level < ALLOWED_LEVELS && item.type == ChecklistItemType.MULTI_MEDIA
            ? addLogic
            : undefined,
        duplicate,
        delete: () => setShowDeleteConfirmation(true),
        addInstruction: !item.instruction
          ? () => {
              update({ ...item, instruction: ' ' }, false);
            }
          : undefined,
        addImage: !item.imageURL
          ? () => {
              update({ ...item, imageURL: ' ' }, false);
            }
          : undefined
      }
    });

    useEffect(() => {
      if (active && inputRef?.current && !isFormActive) {
        (inputRef.current as any).focus();
      }
    }, [active, inputRef, inputRef.current]);

    const filterLogic = (logic, checklistItem) =>
      !logic.isDeleted &&
      !(
        checklistItem.type === ChecklistItemType.MULTIPLE_CHOICE &&
        logic.action === 'SCORING'
      );

    return (
      <S.CriteriaContainer onClick={onClick}>
        <CriteriaForm
          item={item}
          categoryId={sectionId}
          onSubmit={update}
          dragHandleProps={{ ...dragHandleProps }}
          onClick={() => setIsFormActive(true)}
          inputRef={inputRef}
          isLastItem={
            isLastItem &&
            !item.instruction &&
            !item.imageURL &&
            (item?.subLogics?.filter((e) => filterLogic(e, item)) ?? [])
              .length == 0
          }
          onEnterKeyDown={onEnterKeyDown}
          isReadOnly={isReadOnly}
        >
          {!isReadOnly && (
            <ActionsMenu items={actionMenuItems} onMenuClick={onClick} />
          )}
        </CriteriaForm>
        <Instructions
          isLastItem={
            isLastItem &&
            (item.subLogics?.filter((e) => !e.isDeleted).length == 0 ||
              !item.subLogics)
          }
          checklistItemState={item}
          onDeleteImage={() => {
            update({ ...item, imageURL: null }, false);
          }}
          onDeleteInstruction={() => {
            update({ ...item, instruction: null }, false);
          }}
          onSubmit={(values) => {
            update(
              {
                ...item,
                instruction: values.instruction,
                imageURL: values.imageURL
              },
              false
            );
          }}
        />
        <TransitionGroup>
          {item?.subLogics
            ?.filter((e) => filterLogic(e, item))
            .map((logic, index) => (
              <Collapse key={logic.id}>
                {index == 0 && !item.instruction && !item.imageURL && (
                  <S.Divider orientation="horizontal" />
                )}
                <S.LogicWrapper key={logic.id}>
                  {index > 0 && <S.Divider orientation="horizontal" />}
                  <Logic
                    sectionId={sectionId}
                    logicState={logic}
                    parentChecklistItemState={item}
                    level={level + 1}
                    isLastItem={
                      isLastItem &&
                      index ==
                        item.subLogics?.filter((e) => filterLogic(e, item))
                          .length -
                          1
                    }
                    isReadOnly={isReadOnly}
                    onDelete={() => onDeleteLogic(logic.id).then(handleSuccess)}
                  />
                </S.LogicWrapper>
              </Collapse>
            ))}
        </TransitionGroup>
        <ConfirmationDialog
          open={showDeleteConfirmation}
          onConfirm={() => {
            deleteQuestion();
          }}
          setOpen={setShowDeleteConfirmation}
          title={t('CHECKLIST_BUILDER.CRITERIA.DELETE_CONFIRMATION_MESSAGE')}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      </S.CriteriaContainer>
    );
  }
);

export default Criteria;
