import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollTop = (): null => {
  const location = useLocation();
  useEffect(() => {
    if (document.getElementById('root-container'))
      document.getElementById('root-container').scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollTop;
