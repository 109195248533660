import { makeObservable, observable } from 'mobx';
import { httpGet, httpPost, httpPut } from 'src/axios/axiosUtils';
import { webhooks, webhookDetails } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import type {
  GetHookDto,
  HookRequestPaginationParams,
  HookRequestResponse,
  HookRequestStatus,
  SaveHookDto
} from './types';

export class HooksStore extends BaseBackendStore {
  @observable hook: GetHookDto;

  @observable hooks: GetHookDto[] = [];

  @observable hooksState: LoadingState;

  @observable hookState: LoadingState = LoadingState.IDLE;

  @observable hookRequestResponse: HookRequestResponse = undefined;

  @observable hookRequestsState: LoadingState;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  async getHooks() {
    try {
      this.hooksState = LoadingState.LOADING;
      this.hooks = await httpGet(`${webhooks}`, false);
      this.hooksState = LoadingState.DONE;
    } catch (err) {
      this.hooksState = LoadingState.FAILED;
      throw err;
    }
  }

  async getHookDetails(hookId: number) {
    try {
      this.hookState = LoadingState.LOADING;
      this.hook = await httpGet(`${webhookDetails(hookId)}`, false);
      this.hookState = LoadingState.DONE;
    } catch (err) {
      this.hookState = LoadingState.FAILED;
      throw err;
    }
  }

  async getHookDetailsLogs(
    hookId: number,
    pagination: HookRequestPaginationParams
  ) {
    try {
      this.hookRequestsState = LoadingState.LOADING;
      let query = `status=${pagination.status}&page=${
        pagination.page + 1
      }&take=${pagination.take}`;
      this.hookRequestResponse = await httpGet(
        `${webhookDetails(hookId)}/requests?${query}`,
        false
      );
      this.hookRequestsState = LoadingState.DONE;
    } catch (err) {
      this.hookRequestsState = LoadingState.FAILED;
      throw err;
    }
  }

  async retryHookRequest(id: number, hookId: number) {
    try {
      this.hookRequestsState = LoadingState.LOADING;
      await httpPost(`${webhookDetails(hookId)}/requests`, {
        hookRequestId: id
      });
      this.hookRequestsState = LoadingState.DONE;
    } catch (err) {
      this.hookRequestsState = LoadingState.FAILED;
      throw err;
    }
  }

  async createHook(hook: SaveHookDto) {
    try {
      this.hookState = LoadingState.LOADING;
      this.hook = await httpPost(`${webhooks}`, hook);
      this.hookState = LoadingState.DONE;
    } catch (err) {
      this.hookState = LoadingState.FAILED;
      throw err;
    }
  }

  async updateHook(hookId: number, hook: SaveHookDto) {
    try {
      this.hookState = LoadingState.LOADING;
      this.hook = await httpPut(`${webhookDetails(hookId)}`, hook);
      this.hookState = LoadingState.DONE;
    } catch (err) {
      this.hookState = LoadingState.FAILED;
      throw err;
    }
  }
}
