import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Switch,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react';
import { ResponsiveModal } from 'src/components/ResponsiveModal';
import * as S from './styled';
import {
  ChecklistItemOptions,
  ChecklistItemType,
  ViewChecklistItem
} from 'src/models/unit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import * as Yup from 'yup';
import { DraggableList } from 'src/components/DraggableList';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useEffect, useRef, useState } from 'react';
import { reorder } from 'src/helpers/Array';
import _ from 'lodash';
import { useStores } from 'src/hooks/useMobxStores';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useToaster } from 'src/hooks/useToaster';
import { FormHelperText } from '@mui/material';
import { LogicDto } from 'src/models/logic';
import { useChecklistStore } from 'src/modules/ChecklistBuilder/hooks/useChecklistStore';

interface Props {
  open: boolean;
  checklistItemState: ViewChecklistItem;
  useNewOptions: boolean;
  categoryId: number;
  onClose: () => void;
}

const validationSchema = (t) =>
  Yup.object().shape({
    options: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().when('isDeleted', {
            is: false,
            then: Yup.string().required(
              t('CHECKLIST_BUILDER.CRITERIA.ERRORS.REQUIRED')
            ),
            otherwise: Yup.string()
          }),
          order: Yup.number(),
          isDeleted: Yup.boolean(),
          score: Yup.number().default(0)
        })
      )
      .min(2, t('CHECKLIST_BUILDER.CRITERIA.ERRORS.MIN'))
      .max(99, t('CHECKLIST_BUILDER.CRITERIA.ERRORS.MAX'))
  });

export const MultipleChoiceModal = observer(
  ({ open, useNewOptions, checklistItemState, categoryId, onClose }: Props) => {
    const formikRef = useRef(null);
    const optionRefs = useRef({});
    const { t } = useTranslation();
    const checklistBuilderStore = useChecklistStore();
    const toaster = useToaster();
    const [allowMultipleOptions, setAllowMultipleOptions] = useState(
      useNewOptions ? false : checklistItemState.allowMultipleOptions
    );

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [allowScoring, setAllowScoring] = useState(
      checklistItemState.subLogics?.filter(
        (e) => e.action == 'SCORING' && !e.isDeleted
      ).length > 0 ?? false
    );

    const handleSubmit = (values) => {
      setIsSubmitting(true);
      const updatedOptions = values.options.map((e) => ({
        ...e,
        id: e.id.toString().startsWith('new') ? undefined : e.id
      }));

      const deletedOptions = checklistItemState.checklistItemOptions.filter(
        (i) => !i.isDeleted && !updatedOptions.find((e) => e.id == i.id)
      );

      const options = useNewOptions
        ? [
            ...checklistItemState.checklistItemOptions.map((e) => ({
              ...e,
              isDeleted: true
            })),
            ...updatedOptions
          ]
        : [
            ...updatedOptions,
            ...deletedOptions.map((e) => ({ ...e, isDeleted: true }))
          ];

      const mappedChecklistItem: ViewChecklistItem = {
        title: checklistItemState.title,
        isDeleted: checklistItemState.isDeleted,
        type: ChecklistItemType.MULTIPLE_CHOICE,
        mandatory: checklistItemState.mandatory,
        order: checklistItemState.order,
        checklistItemOptions: options.map((e) => ({ ...e, score: undefined })),
        allowMultipleOptions
      };

      const request = checklistBuilderStore.updateChecklistItem(
        checklistItemState.id,
        mappedChecklistItem,
        checklistItemState,
        { categoryId }
      );
      request
        .then(async (res: ViewChecklistItem) => {
          if (allowScoring) {
            await Promise.all([
              res.checklistItemOptions
                .filter((e) => !e.isDeleted)
                .map(async (o) => {
                  const logic = checklistItemState.subLogics?.find(
                    (l) =>
                      l.action == 'SCORING' &&
                      l.logicChecklistItemsOptions?.length == 1 &&
                      l.logicChecklistItemsOptions[0].id == o.id
                  );
                  const option = values.options.find(
                    (vo) => vo.title === o.title && vo.order === o.order
                  );
                  if (logic) {
                    const logicDto = {
                      ...logic,
                      values: [o.id],
                      score: option.score
                    };
                    //Exist logic score
                    await checklistBuilderStore.updateLogic(logic, logicDto);
                  } else {
                    //New logic score
                    const logicDto: LogicDto = {
                      rule: 'IS',
                      values: [o.id],
                      action: 'SCORING',
                      score: option.score
                    };
                    await checklistBuilderStore.addLogic(
                      checklistItemState,
                      logicDto
                    );
                  }
                })
            ]);
            //delete logics if option is deleted too
            await Promise.all([
              res.checklistItemOptions
                .filter((e) => e.isDeleted)
                .map((o) => {
                  const logic = checklistItemState.subLogics?.find(
                    (l) =>
                      l.action == 'SCORING' &&
                      l.logicChecklistItemsOptions?.length == 1 &&
                      l.logicChecklistItemsOptions[0].id == o.id
                  );
                  if (logic) {
                    return checklistBuilderStore.deleteLogic(
                      checklistItemState,
                      logic.id
                    );
                  }
                })
            ]);
          } else {
            await Promise.all([
              checklistItemState.subLogics
                .filter((l) => l.action == 'SCORING')
                .map((l) =>
                  checklistBuilderStore.deleteLogic(checklistItemState, l.id)
                )
            ]);
          }
        })
        .then(() => {
          toaster(t('CHECKLIST_BUILDER.SAVED_MESSAGE'), 'success');
          onClose();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    };

    const onDragEnd = (destination, source, values, setFieldValue) => {
      if (!destination) return;
      const orderedOptions = reorder(
        values.options,
        source.index,
        destination.index
      ).map((item: any, index) => ({
        ...item,
        order: index
      }));
      setFieldValue('options', orderedOptions);
    };

    const onDelete = (index: number, values, setFieldValue) => {
      const newValues = [...values.options];
      newValues.splice(index, 1);
      setFieldValue(`options`, newValues);
    };

    const addOption = (values, setFieldValue, order = undefined) => {
      const maxOrder = Math.max(...values.options.map((e) => e.order));
      const newOption = {
        id: `new-${Math.random()}`,
        order: order != undefined ? order + 1 : maxOrder + 1,
        title: '',
        isDeleted: false
      };
      let newOptions = [...values.options];
      if (order != undefined) {
        newOptions
          .filter((e) => e.order > order)
          .forEach((e) => {
            e.order = e.order + 1;
          });
      }
      newOptions = _.sortBy([...newOptions, newOption], 'order');
      setFieldValue('options', newOptions);
      setTimeout(() => {
        optionRefs.current[newOption.id].focus();
      }, 0);
    };

    return (
      <ResponsiveModal
        open={open}
        width="40%"
        header={
          <div>
            <Typography variant="h3" fontSize={20}>
              {t('CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.TITLE')}
            </Typography>
            <Typography variant="subtitle1">
              {t('CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.SUBTITLE')}
            </Typography>
          </div>
        }
        closeButton
        onClose={onClose}
        body={
          <S.BodyContainer>
            <Formik
              innerRef={formikRef}
              validationSchema={() => validationSchema(t)}
              initialValues={{
                options: useNewOptions
                  ? [
                      {
                        id: `new-${Math.random()}`,
                        order: 1,
                        title: '',
                        isDeleted: false,
                        score: 0
                      }
                    ]
                  : checklistItemState.checklistItemOptions
                      .filter((e) => !e.isDeleted)
                      .map((e) => ({
                        ...e,
                        score:
                          checklistItemState.subLogics?.find(
                            (l) =>
                              l.action == 'SCORING' &&
                              l.logicChecklistItemsOptions?.[0].id == e.id
                          )?.score ?? 0
                      }))
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isValid, errors }) => {
                return (
                  <>
                    <S.ContainerGrid container spacing={2}>
                      <Grid item xs={7} md={9}>
                        <Box>
                          {t(
                            'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.OPTION'
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                        {allowScoring && (
                          <S.TextFieldContainer>
                            {t(
                              'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.SCORE'
                            )}
                          </S.TextFieldContainer>
                        )}
                      </Grid>
                      <Grid item xs={2} md={1}>
                        <S.TextFieldContainer>
                          {t(
                            'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.DELETE'
                          )}
                        </S.TextFieldContainer>
                      </Grid>
                    </S.ContainerGrid>

                    <DraggableList
                      list={values.options.filter((e) => !e.isDeleted)}
                      render={(e: ChecklistItemOptions, i, dragHandleProps) => (
                        <S.OptionGridRow
                          container
                          {...dragHandleProps}
                          spacing={2}
                        >
                          <Grid item xs={7} md={9}>
                            <S.TextFieldContainer>
                              <DragIndicatorIcon />
                              <S.StyledMuiTextField
                                fullWidth
                                name={`options[${i}].title`}
                                placeholder={t(
                                  'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_OPTIONS.PLACEHOLDER'
                                )}
                                trim={true}
                                autoComplete="off"
                                onEnterKeyDown={() => {
                                  addOption(values, setFieldValue, e.order);
                                }}
                                inputRef={(el) =>
                                  (optionRefs.current[e.id] = el)
                                }
                                submitOnBlur={false}
                              />
                            </S.TextFieldContainer>
                          </Grid>
                          <Grid item xs={3} md={2}>
                            {allowScoring && (
                              <S.TextFieldContainer>
                                <S.ScoringTextField
                                  trim={false}
                                  autoComplete="off"
                                  submitOnBlur={false}
                                  variant="outlined"
                                  name={`options[${i}].score`}
                                  fullWidth={false}
                                  defaultValue={0}
                                  type="number"
                                />
                              </S.TextFieldContainer>
                            )}
                          </Grid>
                          <Grid item xs={2} md={1}>
                            <S.TextFieldContainer>
                              <IconButton
                                sx={{ justifySelf: 'center' }}
                                onClick={() =>
                                  onDelete(i, values, setFieldValue)
                                }
                                type="button"
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </S.TextFieldContainer>
                          </Grid>
                        </S.OptionGridRow>
                      )}
                      onDragEnd={({ destination, source }) =>
                        onDragEnd(destination, source, values, setFieldValue)
                      }
                    />

                    {errors.options && typeof errors.options === 'string' && (
                      <FormHelperText error>{errors.options}</FormHelperText>
                    )}
                    <S.AddNewButtonContainer>
                      <S.StyledButton
                        variant="text"
                        startIcon={<AddOutlinedIcon />}
                        onClick={() => addOption(values, setFieldValue)}
                        fullWidth
                      >
                        {t(
                          'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.ADD_OPTION_BUTTON'
                        )}
                      </S.StyledButton>
                    </S.AddNewButtonContainer>
                  </>
                );
              }}
            </Formik>
          </S.BodyContainer>
        }
        footer={
          <S.FooterContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <FormControlLabel
                checked={allowScoring}
                onChange={(e: any) => {
                  setAllowScoring(!allowScoring);
                }}
                control={<Switch />}
                label={
                  t(
                    'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.SCORING_TOGGLE'
                  ) as string
                }
              />
              <FormControlLabel
                checked={allowMultipleOptions}
                onChange={(e: any) => {
                  setAllowMultipleOptions(!allowMultipleOptions);
                }}
                control={<Switch />}
                label={
                  t(
                    'CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.MULTIPLE_SELECTION_TOGGLE'
                  ) as string
                }
              />
            </Box>

            <S.ButtonsContainer>
              <Button onClick={onClose}>
                {t('CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.CANCEL_BUTTON')}
              </Button>
              <S.SubmitButton
                variant="contained"
                onClick={(e) => {
                  if (formikRef.current) {
                    formikRef.current.submitForm();
                  }
                }}
                disabled={isSubmitting}
              >
                {t('CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_MODAL.SAVE_BUTTON')}
              </S.SubmitButton>
            </S.ButtonsContainer>
          </S.FooterContainer>
        }
      />
    );
  }
);
