import { PermissionKey } from 'src/models/user';

export interface MenuItem {
  link?: string;
  iconURL?: string;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
  permission: PermissionKey;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems = (clientId: number): MenuItems[] => [
  {
    heading: '',
    items: [
      {
        name: 'Open_Modal',
        iconURL: '/static/images/sidebar/add.png',
        permission: PermissionKey.GET_DASHBOARD_STATISTICS,
        link: 'dashboard/home'
      },
      {
        name: 'Dashboard',
        iconURL: '/static/images/sidebar/dashboard.svg',
        link: 'dashboard/home',
        permission: PermissionKey.GET_DASHBOARD_STATISTICS
      },
      {
        name: 'CHECKS',
        iconURL: '/static/images/sidebar/checks.svg',
        link: `dashboard/clients/${clientId}/checks`,
        permission: PermissionKey.GET_REPORTS
      },
      {
        name: 'REPORTS',
        iconURL: '/static/images/sidebar/reports.svg',
        link: `dashboard/clients/${clientId}/reports`,
        permission: PermissionKey.GET_REPORTS
      },
      {
        name: 'UNITS',
        iconURL: '/static/images/sidebar/unit.svg',
        link: 'dashboard/units',
        permission: PermissionKey.GET_UNITS
      },
      {
        name: 'TEMPLATE_PAGE_TITLE',
        link: 'dashboard/checklists',
        iconURL: '/static/images/sidebar/template.svg',
        permission: PermissionKey.ALLOW_TO_ACCESS_TEMPLATES
      },

      {
        name: 'Issues',
        iconURL: '/static/images/sidebar/flag.svg',
        link: 'dashboard/issues',
        permission: PermissionKey.GET_ISSUES
      },
      {
        name: 'Clients',
        link: 'dashboard/clients',
        iconURL: '/static/images/sidebar/client.svg',
        permission: PermissionKey.GET_CLIENTS
      },
      {
        name: 'CLIENT_ACTIVITY_TITLE',
        link: 'dashboard/clients-activities',
        iconURL: '/static/images/sidebar/walk.svg',
        permission: PermissionKey.GET_CLIENTS_ACTIVITIES
      },
      {
        name: 'Organisation',
        iconURL: '/static/images/sidebar/organisation.svg',
        permission: PermissionKey.ALLOW_TO_PREVIEW_CLIENT_ORGANIZATION,
        link: '/',
        items: [
          {
            name: 'DETAILS',
            permission: PermissionKey.GET_CLIENT_USERS,
            link: `dashboard/clients/${clientId}/updateProfile`
          },
          {
            name: 'USERS',
            permission: PermissionKey.GET_CLIENT_USERS,
            link: `dashboard/clients/${clientId}/users`
          },
          {
            name: 'Developer',
            permission: PermissionKey.GET_API_KEYS,
            link: `dashboard/clients/${clientId}//api-keys`
          },
          {
            name: 'Webhooks',
            permission: PermissionKey.GET_API_KEYS,
            link: `dashboard/hooks`
          },
          {
            name: 'Configurations',
            permission: PermissionKey.GET_CONFIGURATIONS,
            link: `dashboard/configurations`
          }
        ]
      }
    ]
  }
];

export default menuItems;
