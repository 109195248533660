import { Box, Button, colors, styled } from '@mui/material';
import { t } from 'i18next';

export const ButtonsContainer = styled<any>(Box)`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'start'};
  position: relative;
  button {
    border-radius: 6px;
  }
  gap: ${({ theme }) => theme.spacing(2)};
  margin: ${({ theme }) => theme.spacing(2)} 0;
`;
export const BottomNavigationContainer = styled(Box)`
  margin: 0 ${({ theme }) => theme.spacing(2)};
  background-color: white;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: calc(100% - ${({ theme }) => theme.spacing(4)});
  border-radius: 6px 6px 0 0;
  border: 2px solid ${({ theme }) => theme.borders.color};
  border-bottom: none;
  button {
    width: 100%;
  }
  .MuiBottomNavigation-root {
    justify-content: space-around;
  }
  hr {
    width: 2px;
    background-color: ${({ theme }) => theme.borders.color};
  }
`;

export const StyledButton = styled(Button)`
  &,
  &:hover {
    border-color: ${({ theme }) => theme.borders.color};
    border-width: 2px;
  }
  background-color: ${({ theme }) => theme.palette.background.paper};
`;
