import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { FlaggedOption } from '../models/unit';
import { useReports } from '../hooks/useReports';

export const DashboardInterceptor: FC = ({ children }) => {
  const auth = useAuth();
  const { user } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const { getReports, reportState, getFreeReportsCount } = useReports();
  let isFreeUser = user.SubscriptionType === 'FREE';

  if (children) {
    return <>{children}</>;
  }
  return <Outlet />;
};
