import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Chip,
  Link,
  useMediaQuery
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderSearch from './Search';
import HeaderMenu from './Menu';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import PermissionWrapper from 'src/components/PermissionWrapper';
import { PermissionKey } from 'src/models/user';
import { TranslationDropdown } from 'src/components/TranslationDropdown';
import { Language } from 'src/components/TranslationDropdown/types';
import { UserMenu } from 'src/components/UserMenu';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${theme.header.background};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        z-index: 9;
`
);

const StyledDevChip = styled(Chip)(({ theme }) => ({
  minWidth: theme.spacing(12),
  height: theme.spacing(3),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.colors.warning.lighter,
  color: theme.colors.warning.dark,
  fontWeight: 400
}));

const StyledDevChipBolt = styled(Box)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.colors.warning.dark,
  marginRight: theme.spacing(1)
}));

function Header(props: { showSidebar: boolean }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onClickGearBox = () => {
    navigate('/checkfirst/dashboard/configurations');
  };

  const handleLanguageChanges = (language: Language) => {
    localStorage.setItem('LANG', language);
    i18n.changeLanguage(language);
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box
        sx={{
          textAlign: 'center',
          mx: 2,
          pt: 1,
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: `${theme.header.height}`
        }}
      >
        <Link href="/">
          <Box
            component="img"
            sx={{
              height: 45,
              width: 'fit-content'
            }}
            alt="Logo."
            src={
              isMobile
                ? '/static/images/logo/checkfirst-Icon-logo-dark.png'
                : '/static/images/logo/logo-horizontal.svg'
            }
          />
        </Link>
      </Box>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderSearch />
        <HeaderMenu />
      </Stack>
      <Box display="flex" alignItems="center">
        {/* <HeaderButtons /> */}
        <TranslationDropdown
          handleLanguageChanges={handleLanguageChanges}
          defaultLanguage={localStorage.getItem('LANG') as Language}
          isMobile={isMobile}
        />

        <PermissionWrapper permission={PermissionKey.ACCESS_SETTINGS}>
          <IconButton onClick={onClickGearBox}>
            <SettingsTwoToneIcon color="primary" />
          </IconButton>
        </PermissionWrapper>
        {isMobile ? (
          <StyledDevChipBolt />
        ) : (
          <ConditionalWrapper
            show={process.env.REACT_APP_ENV === 'DEVELOPMENT'}
          >
            <Box marginX={1} paddingX={1}>
              <StyledDevChip
                sx={{
                  minWidth: isMobile && 'fit-content'
                }}
                label={
                  <Box display="flex" alignItems="baseline">
                    <StyledDevChipBolt />
                    {!isMobile && <Box>{t('DEVELOPMENT')}</Box>}
                  </Box>
                }
              />
            </Box>
          </ConditionalWrapper>
        )}
        <UserMenu />
        {props.showSidebar && (
          <Box
            component="span"
            sx={{
              ml: 2,
              display: { lg: 'none', xs: 'inline-block' }
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? (
                  <MenuTwoToneIcon fontSize="small" />
                ) : (
                  <CloseTwoToneIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
