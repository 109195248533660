import React, { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { Box, Drawer, styled, useTheme } from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Button from '@mui/material/Button';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
`
);
interface SidebarProps {
  handleOpenModal: () => void;
}

function Sidebar({ handleOpenModal }: SidebarProps) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: theme.sidebar.background
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              height: `${theme.header.height}`
            }}
          />
          {/*<Button variant="contained" onClick={handleOpenModal}>*/}
          {/*    Open Modal*/}
          {/*</Button>*/}
          <SidebarMenu handleOpenModal={handleOpenModal} />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.sidebar.background
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: `${theme.header.height}`
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 45,
                  width: 181
                }}
                alt="Logo."
                src="/static/images/logo/logo-horizontal.svg"
              />
            </Box>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
