import { Divider, IconButton, Typography } from '@mui/material';
import * as S from './styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormTextField } from 'src/components/FormFields';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { StyledMuiTextField } from 'src/components/ChecklistItemsFormUI/styled';
import CloseIcon from '@mui/icons-material/Close';
import { ViewChecklistItem } from 'src/models/unit';
import { useSnackbar } from 'notistack';
import { useToaster } from 'src/hooks/useToaster';
import { useStores } from 'src/hooks/useMobxStores';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mapChecklistItem } from '../Criteria/utils';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import FileUploader, { FileDomain } from 'src/components/FileUploader';
import { FormFileUploader } from 'src/components/FormFields/FormFileUploader';
import { useEffect, useRef } from 'react';

interface Props {
  checklistItemState: ViewChecklistItem;
  isLastItem: boolean;
  onSubmit: (values) => void;
  onDeleteImage: () => void;
  onDeleteInstruction: () => void;
}

export const Instructions = ({
  checklistItemState,
  isLastItem,
  onSubmit,
  onDeleteImage,
  onDeleteInstruction
}: Props) => {
  const { t } = useTranslation();
  const formRef = useRef<{ dirty: boolean; isValid: boolean; values: any }>();

  const submit = (values, fieldName) => {
    const valuesToSubmit = { ...values };
    if (fieldName == 'instruction') {
      valuesToSubmit.instruction = values.instruction
        ? values.instruction
        : ' ';
    }
    onSubmit(valuesToSubmit);
  };

  useEffect(() => {
    return () => {
      if (formRef?.current?.dirty && formRef?.current?.isValid) {
        const values = {
          ...formRef.current.values,
          instruction: formRef.current.values.instruction
            ? formRef.current.values.instruction
            : ' '
        };
        onSubmit(values);
      }
    };
  }, []);

  return (
    <Formik
      initialValues={{
        instruction: checklistItemState.instruction,
        imageURL: checklistItemState.imageURL
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values, isValid, dirty }) => {
        formRef.current = {
          values,
          isValid,
          dirty
        };
        return (
          <>
            {checklistItemState.instruction && (
              <>
                <S.Divider orientation="horizontal" />
                <S.InstructionsWrapper
                  $isLastItem={isLastItem && !checklistItemState.imageURL}
                >
                  <S.Container>
                    <S.ItemsContainer>
                      <InfoOutlinedIcon />
                      <S.Label>
                        {t('CHECKLIST_BUILDER.INSTRUCTIONS.LABEL')}
                      </S.Label>
                      <S.TextField
                        multiline
                        maxRows={2}
                        placeholder={t(
                          'CHECKLIST_BUILDER.INSTRUCTIONS.PLACEHOLDER'
                        )}
                        name="instruction"
                        variant="outlined"
                        trim
                        onBlur={(e) => submit(values, 'instruction')}
                      />
                    </S.ItemsContainer>
                    <S.ButtonContainer>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          onDeleteInstruction();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </S.ButtonContainer>
                  </S.Container>
                </S.InstructionsWrapper>
              </>
            )}
            {checklistItemState.imageURL && (
              <>
                {!checklistItemState.instruction && (
                  <S.Divider orientation="horizontal" />
                )}
                <S.InstructionsWrapper $isLastItem={isLastItem}>
                  <S.Container>
                    <S.ItemsContainer>
                      <ImageOutlinedIcon />
                      <S.Label>
                        {t('CHECKLIST_BUILDER.INSTRUCTIONS.IMAGE_LABEL')}
                      </S.Label>
                    </S.ItemsContainer>
                    <S.ItemsContainer>
                      <FormFileUploader
                        domain={FileDomain.INSTRUCTIONS}
                        name="imageURL"
                        onChange={(value) =>
                          submit({ ...values, imageURL: value }, 'imageURL')
                        }
                        hideCloseButton={true}
                        openOnInit={true}
                      />
                    </S.ItemsContainer>
                    <S.ButtonContainer>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          onDeleteImage();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </S.ButtonContainer>
                  </S.Container>
                </S.InstructionsWrapper>
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
};
