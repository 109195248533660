import { makeObservable, observable } from 'mobx';
import { httpPost, uploadFileToS3 } from 'src/axios/axiosUtils';
import { uploadFileURL } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { decode } from 'base64-arraybuffer';
import { BaseBackendStore } from '../types';

export class FileStore extends BaseBackendStore {
  @observable fileUploadingState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  uploadBase64Signature = async (
    domain: string,
    fileExtension: string,
    base64: string
  ): Promise<string> => {
    try {
      this.fileUploadingState = LoadingState.LOADING;
      const { uploadUrl, fileUrl } = await httpPost(uploadFileURL, {
        domain,
        fileExtension
      });
      const arrayBuffer = decode(base64.split(',')[1]);
      await fetch(uploadUrl, {
        method: 'PUT',
        body: arrayBuffer,
        headers: {
          'Content-Type': 'image/png'
        }
      });
      this.fileUploadingState = LoadingState.DONE;
      return fileUrl;
    } catch (error) {
      this.fileUploadingState = LoadingState.FAILED;
    }
  };
}
