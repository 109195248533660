import { FC, Fragment } from 'react';

const ConditionalWrapper: FC<{ show: boolean; fallbackNode?: JSX.Element }> = ({
  show,
  children,
  fallbackNode
}) => {
  if (show) {
    return <Fragment>{children}</Fragment>;
  }
  if (fallbackNode) {
    return <Fragment>{fallbackNode}</Fragment>;
  }
  return null;
};

export default ConditionalWrapper;
