import { TextFieldProps, TextField } from '@mui/material';
import { getIn, useFormikContext } from 'formik';
import * as S from './styled';
import { useEffect, useRef } from 'react';

export type FormTextFieldProps = {
  submitOnBlur?: boolean;
  className?: string;
  trim?: boolean;
  initialFocus?: boolean;
  handleChange?: (e: any) => void;
  onEnterKeyDown?: () => void;
} & TextFieldProps;

export const FormTextField = ({
  label,
  placeholder,
  name,
  submitOnBlur = true,
  className,
  onChange,
  handleChange,
  onEnterKeyDown,
  children,
  trim = false,
  initialFocus = false,
  ...props
}: FormTextFieldProps) => {
  const {
    values,
    touched,
    errors,
    submitForm,
    handleChange: formikHandleChange,
    handleBlur,
    isValid,
    dirty
  } = useFormikContext();

  const fieldTouched = getIn(touched, name);
  const fieldErrors = getIn(errors, name);
  const fieldValues = getIn(values, name);

  return (
    <S.StyledTextField
      className={className}
      error={Boolean(fieldTouched && fieldErrors)}
      helperText={fieldTouched && fieldErrors}
      fullWidth
      name={name}
      placeholder={placeholder}
      value={
        trim && fieldValues?.trim().length == 0
          ? fieldValues?.trim()
          : fieldValues
      }
      label={label}
      onBlur={(e) => {
        handleBlur(e);
        if (submitOnBlur && isValid && dirty) submitForm();
      }}
      onChange={(e) => {
        onChange?.(e);
        if (handleChange) handleChange(e);
        else formikHandleChange(e);
      }}
      onKeyDown={(key) => {
        if (key.code == 'Enter' && !key.shiftKey) {
          if (submitOnBlur && isValid && dirty) submitForm();
          onEnterKeyDown?.();
          key.preventDefault();
        }
      }}
      {...props}
    >
      {children}
    </S.StyledTextField>
  );
};
