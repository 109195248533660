import { FC, Fragment } from 'react';
import useAuth from 'src/hooks/useAuth';
import { PermissionKey } from 'src/models/user';

const PermissionWrapper: FC<{ permission: PermissionKey }> = ({
  permission,
  children
}) => {
  const { user } = useAuth();
  // console.log('user.rolesPermissions', user.rolesPermissions)
  const permissionExist = user.rolesPermissions.find(
    (p) => p.permission === permission
  );
  if (permissionExist) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

export default PermissionWrapper;

export const UsePermission = () => {
  const { user } = useAuth();

  const hasPermission = (permission: PermissionKey) => {
    // console.debug('user', JSON.stringify(user))
    return !!(user?.rolesPermissions || []).find(
      (p) => p.permission === permission
    );
  };
  return {
    hasPermission
  };
};
