import Authenticated from 'src/components/Authenticated';
import { ChecklistBuilderLayout } from './Layout';
import { Module } from '../types';
import { ChecklistItemsPage } from './pages';

const ChecklistBuilderModule: Module = {
  routes: {
    path: 'checklist-builder',
    element: (
      <Authenticated>
        <ChecklistBuilderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <ChecklistItemsPage />
      }
    ]
  },
  name: 'ChecklistBuilder'
};

export default ChecklistBuilderModule;
