import {
  Action,
  AuthState,
  InitializeAction,
  LoginAction,
  RegisterAction,
  UpdateCompanyAction,
  WelcomeScreenAction
} from './types';

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isConfirmWelcomeScreen: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;
    return {
      ...state,
      isConfirmWelcomeScreen: true,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
    isConfirmWelcomeScreen: false
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isConfirmWelcomeScreen: false,
      user
    };
  },
  UPDATE_COMPANY: (
    state: AuthState,
    action: UpdateCompanyAction
  ): AuthState => {
    const { user, isConfirmWelcomeScreen } = action.payload;
    console.log('UPDATE_COMPANYasdasd', JSON.stringify(action.payload));
    return {
      ...state,
      user,
      isConfirmWelcomeScreen
    };
  },
  WELCOME: (state: AuthState, action: WelcomeScreenAction): AuthState => {
    const { isConfirmWelcomeScreen } = action.payload;
    return {
      ...state,
      isConfirmWelcomeScreen
    };
  }
};

export const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
