import { useContext } from 'react';
import { StoresContext } from 'src/contexts/mobxStores';

export const useStores = () => {
  const { stores } = useContext(StoresContext);
  if (stores === undefined) {
    throw new Error('useStores must be used within StoresProvider');
  }
  return stores;
};
