import { IconButton, Menu, MenuItem, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const StyledIconButton = styled(IconButton)`
  transition-duration: 900ms;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  text-decoration: none;
  padding: 0;
  &:hover {
    background-color: ${({ theme }) => theme.borders.color};
  }
`;

export const StyledActionMenu = styled(Menu)(({ theme }) => ({
  '.MuiList-root ': {
    padding: theme.spacing(1, 0)
  },
  '.MuiPopover-paper': {
    // background: "blue",
    // top: "240px",
    // left: "850px",
  },
  '.MuiIconButton-root ': {
    opacity: '0.9 !important',
    '&:hover': {
      backgroundColor: '#a0a3bd20'
    },
    display: 'inline-block',
    textDecoration: 'none',
    width: '24px',
    height: '24px',
    /*line-height: 120px, */
    borderRadius: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    transition: '.9s'
  },

  // backgroundColor: `rgba(255, 255, 255, 1)`,
  // boxShadow: `0px 2px 2px rgba(159, 162, 191, 0.32), 0px 9px 16px rgba(159, 162, 191, 0.18)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  padding: `10px 0px`,
  boxSizing: `border-box`
  // left: `1045px`,
  // top: `247px`,
}));

export const StyledActionMenuItem = styled(MenuItem)(({ theme }) => ({
  width: 'auto !important',
  '&.MuiMenuItem-root': {
    padding: theme.spacing(0.75, 2),
    width: theme.spacing(18)
  }
}));

export const StyledMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  '.MuiIconButton-root ': {
    opacity: '0.9 !important',
    '&:hover': {
      backgroundColor: 'red'
    },
    display: 'inline-block',
    textDecoration: 'none',
    width: '24px',
    height: '24px',
    /*line-height: 120px, */
    borderRadius: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    transition: '.9s'
  },
  textAlign: 'left',
  padding: 0
}));
