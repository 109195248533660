import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { MenuItem } from '@mui/material';

import { LogicUI, LogicUIProps } from '../..';
import { useTranslation } from 'react-i18next';
import * as S from './styled';
import * as SS from '../../styled';
import {
  LOGIC_MAP,
  NUMBER_RULE_DISPLAY_VALUE_MAP
} from '../../../../constants';
import {
  BetweenNumberRules,
  NumberRules,
  OptionalRules
} from 'src/models/logic';

export const Number = ({
  required,
  onDelete,
  isReadOnly,
  criteriaType,
  addNewCriteria,
  submitForm
}: LogicUIProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (values.rule === 'IS_EMPTY') {
      setFieldValue('action', 'SCORING');
    } else {
      setFieldValue('action', values.action || 'FLAG_REPORT');
    }
  }, [values.rule, setFieldValue]);

  const [pendingAddCriteria, setPendingAddCriteria] = useState(false);

  const handleActionChange = async (e: any) => {
    const selectedValue = e.target.value;
    await setFieldValue('action', selectedValue);
    if (selectedValue === 'ADD_CRITERIA') {
      setPendingAddCriteria(true);
      await submitForm();
    }
  };

  useEffect(() => {
    if (pendingAddCriteria) {
      addNewCriteria();
      setPendingAddCriteria(false);
    }
  }, [pendingAddCriteria]);

  return (
    <LogicUI
      required={required}
      criteriaType={criteriaType}
      ifSection={() => (
        <>
          <S.RuleSelect
            submitOnBlur
            name="rule"
            placeholder={t('CHECKLIST_BUILDER.CRITERIA.TYPE_PLACEHOLDER')}
            fullWidth={false}
            renderValue={(value: NumberRules) =>
              NUMBER_RULE_DISPLAY_VALUE_MAP[value] ??
              t(`CHECKLIST_BUILDER.LOGIC.${value}`)
            }
            disabled={isReadOnly}
          >
            {LOGIC_MAP.NUMBER.rules
              .filter((e) => !OptionalRules.includes(e) || !required)
              .map((e) => (
                <MenuItem key={e} value={e}>
                  {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
                </MenuItem>
              ))}
          </S.RuleSelect>
          {values.rule && !OptionalRules.includes(values.rule) && (
            <S.ValueTextField
              submitOnBlur
              type="number"
              variant="outlined"
              fullWidth={false}
              name="values.0"
              helperText=""
              value={values.values[0]}
              disabled={isReadOnly}
            />
          )}
          {BetweenNumberRules.includes(values.rule) && (
            <S.ValueTextField
              submitOnBlur
              type="number"
              variant="outlined"
              fullWidth={false}
              name="values.1"
              helperText=""
              value={values.values[1]}
              disabled={isReadOnly}
            />
          )}
        </>
      )}
      thenSection={() => (
        <SS.RuleSelect
          submitOnBlur
          fullWidth={false}
          name="action"
          placeholder={t('CHECKLIST_BUILDER.LOGIC.ACTION_PLACEHOLDER')}
          displayEmpty
          renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
          disabled={isReadOnly}
          onChange={handleActionChange}
        >
          {LOGIC_MAP.NUMBER.actions
            .filter(
              (action) => values.rule !== 'IS_EMPTY' || action !== 'FLAG_REPORT'
            )
            .map((e) => (
              <MenuItem key={e} value={e}>
                {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
              </MenuItem>
            ))}
        </SS.RuleSelect>
      )}
      scoreSection={() =>
        values.action === 'SCORING' ? (
          <S.ValueTextField
            submitOnBlur
            type="text"
            variant="outlined"
            fullWidth={false}
            name="score"
            helperText=""
            value={values.score}
            disabled={isReadOnly}
          />
        ) : null
      }
      onDelete={onDelete}
      isReadOnly={isReadOnly}
    />
  );
};
