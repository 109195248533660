import { makeObservable, observable } from 'mobx';
import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from 'src/axios/axiosUtils';
import { units as unitsReq } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import type {
  CategoryDto,
  GetBasicInformationDto,
  Reorder,
  SaveBasicInformationDto,
  UnitDetails,
  UnitMetaResponse
} from './types';
import { InstructionDetails, SaveInstructionDto } from './types';

export class instructionsStore extends BaseBackendStore {
  // @observable unit: UnitDetails;
  @observable instruction: InstructionDetails;

  @observable unitsResponse: UnitMetaResponse = {
    data: [],
    meta: {
      itemCount: 0
    }
  };

  @observable unitsState: LoadingState = LoadingState.IDLE;

  @observable unitState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  // async getUnits(params?: string) {
  //   try {
  //     this.instructionsState = LoadingState.LOADING;
  //     let url = unitsReq;
  //     if (params) {
  //       url += `?${params}`;
  //     }
  //     this.instructionsResponse = await httpGet(url, false);
  //     this.instructionsState = LoadingState.DONE;
  //   } catch {
  //     this.instructionsState = LoadingState.FAILED;
  //   }
  // }
  //
  // async getUnit(unitId: number) {
  //   try {
  //     this.instructionState = LoadingState.LOADING;
  //     this.instruction = await httpGet(`${unitsReq}/${unitId}`, false, {
  //       headers: {
  //         'request-version': '2'
  //       }
  //     });
  //     this.instructionState = LoadingState.DONE;
  //   } catch {
  //     this.instructionState = LoadingState.FAILED;
  //   }
  // }

  // setUnit(unitToSet: UnitDetails) {
  //   this.instruction = unitToSet;
  // }

  async reorderChecklistItems(
    unitId: number,
    categoryId: number,
    checklistItemsOrders: Reorder[]
  ) {
    await httpPatch(
      `${unitsReq}/${unitId}/categories/${categoryId}/checklist-items`,
      checklistItemsOrders
    );
  }

  async createUnit(unit: SaveBasicInformationDto) {
    await httpPost(`${unitsReq}`, unit);
  }

  updateUnitBasicInformation({ instruction, imageURL }: SaveInstructionDto) {
    this.instruction = {
      ...this.instruction,
      instruction,
      imageURL
    };
  }

  // get unitBasicInformationDto(): GetBasicInformationDto {
  //   return {
  //     client: {
  //       id: this.instruction.client.id
  //     },
  //     description: this.instruction.description,
  //     redirectURL: this.instruction.redirectURL,
  //     imageURL: this.instruction.imageURL,
  //     title: this.instruction.title,
  //     ...(this.instruction.user && {
  //       user: {
  //         id: this.instruction?.user?.id,
  //       }
  //     })
  //   };
  // }

  // resetUnit() {
  //   this.instruction = undefined;
  // }
}
