import { Box, styled, Divider as MuiDivider } from '@mui/material';

export const LogicWrapper = styled(Box)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
export const Divider = styled(MuiDivider)`
  background-color: ${({ theme }) => theme.borders.color};
`;

export const MediaBox = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.2)};
`;

export const CriteriaContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};
`;
