import { LogicUI, LogicUIProps } from '../..';
import { useTranslation } from 'react-i18next';
import { LOGIC_MAP, LogicForm } from '../../../../constants';
import { MenuItem } from '@mui/material';
import * as S from './styled';
import * as SS from '../../styled';
import { useFormikContext } from 'formik';
import AutoAwesome from '@mui/icons-material/AutoAwesome';

export const Multimedia = ({ onDelete, isReadOnly }: LogicUIProps) => {
  const { t } = useTranslation();
  const { values }: { values: LogicForm } = useFormikContext() as any;

  return (
    <LogicUI
      icon={<AutoAwesome />}
      ifText={t('CHECKLIST_BUILDER.LOGIC.IF_IMAGE')}
      ifSection={() => (
        <>
          <S.RuleSelect
            submitOnBlur
            name="rule"
            placeholder={t('CHECKLIST_BUILDER.CRITERIA.TYPE_PLACEHOLDER')}
            fullWidth={false}
            renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
            disabled={isReadOnly}
          >
            {LOGIC_MAP.MULTI_MEDIA.rules.map((e) => (
              <MenuItem key={e} value={e}>
                {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
              </MenuItem>
            ))}
          </S.RuleSelect>
          <S.ValueTextField
            submitOnBlur
            type="text"
            variant="outlined"
            fullWidth={false}
            name="values.0"
            helperText="" //to disable error text
            value={values?.values?.[0]}
            disabled={isReadOnly}
          />
        </>
      )}
      thenSection={() => (
        <SS.RuleSelect
          submitOnBlur
          fullWidth={false}
          name="action"
          placeholder={t('CHECKLIST_BUILDER.LOGIC.ACTION_PLACEHOLDER')}
          displayEmpty
          renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
          disabled={isReadOnly}
        >
          {LOGIC_MAP.MULTI_MEDIA.actions.map((e) => (
            <MenuItem key={e} value={e}>
              {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
            </MenuItem>
          ))}
        </SS.RuleSelect>
      )}
      onDelete={onDelete}
      isReadOnly={isReadOnly}
      scoreSection={() =>
        values.action === 'SCORING' ? (
          <S.ValueTextField
            submitOnBlur
            type="text"
            variant="outlined"
            fullWidth={false}
            name="score"
            helperText="" // to disable error text
            value={values.score}
            disabled={isReadOnly}
          />
        ) : null
      }
    />
  );
};
