import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';
import Status404 from 'src/content/pages/Status/Status404';

interface ClientRoleProps {
  children: ReactNode;
}

const ClientRole: FC<ClientRoleProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (auth.user.roles[0] != 'admin') {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to="status" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

ClientRole.propTypes = {
  children: PropTypes.node
};

export default ClientRole;
