import { ArticleTwoTone } from '@mui/icons-material';
import { Table, TableHead, TableBody } from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BackendResourceWrapper from 'src/components/BackendResourceWraper';
import {
  TemplateSortParams,
  TemplateSortKey
} from 'src/components/CreateChecklistFormModal/types';
import { IdWrapper } from 'src/components/IdWrapper';
import { useStores } from 'src/hooks/useMobxStores';
import { SortType } from 'src/models/sort';
import AddIcon from '@mui/icons-material/Add';
import * as S from '../../styled';
import { UnitSort, UnitSortKey } from 'src/models/unit';
import { observer } from 'mobx-react';
import SaveBasicInformation from 'src/content/dashboards/Units/UnitForm/components/SaveBasicInformation';
import { toggleSortOrder } from 'src/utils/table';
interface Props {
  selectedUnitId: number;
  onChange: (newUnitId: number) => void;
  onNewUnitClicked: () => void;
}

export const UnitsList = observer(
  ({ selectedUnitId, onNewUnitClicked, onChange }: Props) => {
    const { t } = useTranslation();
    const {
      backend: { unit: unitStore }
    } = useStores();

    const [sortState, setSortState] = useState<UnitSort>(undefined);

    useEffect(() => {
      const params = {
        take: `250`,
        page: '1'
      };
      if (sortState) {
        params['sort-by'] = sortState.key;
        params['sort-order'] = sortState.sort;
      }
      const queryParams = new URLSearchParams(params).toString();
      unitStore.getUnits(queryParams);
    }, [selectedUnitId, sortState]);

    const onSort = (key: UnitSortKey) => {
      const newSort = toggleSortOrder(sortState, key) as UnitSort;
      setSortState(newSort);
    };

    return (
      <>
        <BackendResourceWrapper states={[unitStore.unitsState]}>
          <S.StyledTableContainer>
            <Table sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <S.StyledTableRow>
                  <S.StyledHeaderTableCell
                    onClick={() => onSort(UnitSortKey.title)}
                  >
                    <S.StyledHeaderLabel
                      direction={
                        sortState?.key == UnitSortKey.title
                          ? sortState?.sort
                          : undefined
                      }
                    >
                      {t('SHARE_INSPECTION_MODAL.STEP_2.NAME')}
                    </S.StyledHeaderLabel>
                  </S.StyledHeaderTableCell>
                  <S.CreatedAtCell onClick={() => onSort(UnitSortKey.userName)}>
                    <S.StyledHeaderLabel
                      direction={
                        sortState?.key == UnitSortKey.userName
                          ? sortState?.sort
                          : undefined
                      }
                    >
                      {t('SHARE_INSPECTION_MODAL.STEP_2.OWNER')}
                    </S.StyledHeaderLabel>
                  </S.CreatedAtCell>
                  <S.CreatedAtCell
                    onClick={() => onSort(UnitSortKey.createdAt)}
                  >
                    <S.StyledHeaderLabel
                      direction={
                        sortState?.key == UnitSortKey.createdAt
                          ? sortState?.sort
                          : undefined
                      }
                    >
                      {t('SHARE_INSPECTION_MODAL.STEP_2.CREATED')}
                    </S.StyledHeaderLabel>
                  </S.CreatedAtCell>
                </S.StyledTableRow>
              </TableHead>
              <TableBody>
                <S.Row
                  key={`new-unit`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onNewUnitClicked();
                  }}
                >
                  <S.StyledTableCell colSpan={3}>
                    <S.AddNewButton>
                      <AddIcon />
                      {t('SHARE_INSPECTION_MODAL.STEP_2.NEW_UNIT')}
                    </S.AddNewButton>
                  </S.StyledTableCell>
                </S.Row>
                {unitStore.unitsResponse.data.map((unit) => (
                  <S.Row
                    key={`unit-${unit.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange?.(unit.id);
                    }}
                  >
                    <S.StyledTableCell>
                      <IdWrapper
                        id={unit.title}
                        width="100%"
                        lineHeight="1.45"
                        showTooltip={true}
                      />
                    </S.StyledTableCell>
                    <S.StyledTableCell>{unit.userName}</S.StyledTableCell>
                    <S.StyledTableCell>
                      {moment(unit.createdAt).format('DD/MM/YY, HH:mm')}
                    </S.StyledTableCell>
                  </S.Row>
                ))}
              </TableBody>
            </Table>
          </S.StyledTableContainer>
        </BackendResourceWrapper>
      </>
    );
  }
);
