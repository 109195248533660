import { ListSubheader, alpha, Box, List, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import SidebarMenuItemButton from './buttonItem';

import menuItems, { MenuItem } from './items';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import { PermissionKey } from 'src/models/user';
import ApiTwoToneIcon from '@mui/icons-material/ApiTwoTone';
import GroupIcon from '@mui/icons-material/Group';
import PaidIcon from '@mui/icons-material/Paid';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { FeatureStatus } from 'src/models/feature.toggle';
import WorkIcon from '@mui/icons-material/Work';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ShareIcon from '@mui/icons-material/Share';
import React, { Fragment, useEffect, useState } from 'react';
import { UsePermission } from 'src/components/PermissionWrapper';
import { observer } from 'mobx-react';
import Button from '@mui/material/Button';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

 


    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }

    /* Mui typography root */
    .MuiCollapse-wrapperInner .MuiBox-root .MuiTypography-root{
     color:#6E7191;
    }
    
    /* Mui list padding */
    .MuiList-subheader > .MuiBox-root > .MuiList-padding{
     transform:translatex(0px) translatey(0px);
    }
    
     /* Link */
     /* 865d8jr2e - increase sidebar item corner radius to at least half its height, so it is fully rounded - currently a rectangle with rounded corners! */
    .MuiList-subheader > .MuiBox-root > .MuiList-padding > .MuiListItem-gutters > a.css-8an3jv-MuiButtonBase-root-MuiButton-root{
     border-top-left-radius:52px;
     border-top-right-radius:52px;
     border-bottom-left-radius:52px;
     border-bottom-right-radius:52px;
    }


`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active
          {
            background-color: ${theme.colors.secondary.lighter};
            color: ${theme.colors.alpha.trueWhite[100]};
            border-radius: 20px,
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        
          &:hover {
            background-color: rgba(160, 163, 189, 0.2); /* 20% opacity */
            color: rgba(160, 163, 189, 0.2); /* 20% opacity */
            border-radius: 20px;
          
            /* Update icon colors */
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: rgba(${
                theme.colors.alpha.trueWhite[100]
              }, 0.2); /* 20% opacity */
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.Mui-active,
              &:hover {

                &:before {
                  transform: scale(1);
                }
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path,
  handleOpenModal
}: {
  items: MenuItem[];
  path: string;
  handleOpenModal?: () => void;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div" sx={{ paddingBottom: '10px' }}>
      {items.reduce(
        (ev, item) => reduceChildRoutes({ ev, item, path, handleOpenModal }),
        []
      )}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item,
  handleOpenModal
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
  handleOpenModal?: () => void;
}): Array<JSX.Element> => {
  const auth = useAuth();
  const { hasPermission } = UsePermission();

  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;
    if (hasPermission(item.permission)) {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          iconURL={item.iconURL}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
        >
          {renderSidebarMenuItems({
            path,
            items: item.items
          })}
        </SidebarMenuItem>
      );
    }
  } else {
    if (hasPermission(item.permission)) {
      if (item.name === 'Open_Modal') {
        ev.push(
          <SidebarMenuItemButton
            key={key}
            active={exactMatch}
            name={item.name}
            link={item.link}
            badge={item.badge}
            badgeTooltip={item.badgeTooltip}
            iconURL={item.iconURL}
            handleOpenModal={handleOpenModal}
          />
        );
      } else {
        ev.push(
          <SidebarMenuItem
            key={key}
            active={exactMatch}
            name={item.name}
            link={item.link}
            badge={item.badge}
            badgeTooltip={item.badgeTooltip}
            iconURL={item.iconURL}
          />
        );
      }
    }
  }
  return ev;
};
interface SidebarMenuProps {
  handleOpenModal?: () => void;
}

const SidebarMenu: React.FC<SidebarMenuProps> = observer(
  ({ handleOpenModal }) => {
    const location = useLocation();
    const { t }: { t: any } = useTranslation();
    const { user } = useAuth();
    const { hasPermission } = UsePermission();
    return (
      <>
        {menuItems
          ? menuItems(user?.client?.id).map((section) => (
              <MenuWrapper key={section.heading}>
                {/*<Button variant="contained" onClick={handleOpenModal}>*/}
                {/*    Open Modal*/}
                {/*</Button>*/}
                <List
                  component="div"
                  subheader={
                    <ListSubheader component="div" disableSticky>
                      {t(section.heading)}
                    </ListSubheader>
                  }
                >
                  {renderSidebarMenuItems({
                    handleOpenModal: handleOpenModal,
                    items: section.items,
                    path: location.pathname
                  })}
                </List>
              </MenuWrapper>
            ))
          : null}
      </>
    );
  }
);

export default SidebarMenu;
