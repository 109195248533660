import { useState, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { logEvent, initializeAnalytics } from 'firebase/analytics';
import AnalyticsEvent from './AnalyticsEvent';

export let analyticsInstance = null;

const useAnalytics = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  const initializeFirebase = () => {
    const app = initializeApp(firebaseConfig);
    const analytics = initializeAnalytics(app);
    analyticsInstance = analytics;
  };

  const logAnalyticsEvent = useCallback(
    (event: AnalyticsEvent) => {
      if (analyticsInstance) {
        logEvent(analyticsInstance, event.eventTitle, event.eventParams);
      }
    },
    [analyticsInstance]
  );

  return {
    analyticsInstance,
    initializeFirebase,
    logAnalyticsEvent
  };
};

export default useAnalytics;
