export enum REPORT_CONFIG_TYPES {
  SHOW_SUMMARY = 'SHOW_SUMMARY',
  SHOW_ISSUES = 'SHOW_ISSUES',
  SHOW_FLAGGED = 'SHOW_FLAGGED',
  SHOW_SCORING = 'SHOW_SCORING',
  SHOW_CO_SIGNATURE = 'SHOW_CO_SIGNATURE',
  SHOW_WATERMARK = 'SHOW_WATERMARK'
}

export const PAGE_SIZES = [10, 50, 100, 250];
