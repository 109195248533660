// Check First Requests
export const units = '/units';
export const issues = '/issues';
export const demoRentals = '/rentals/demo';
export const rentalCount = '/rentals/count';
export const clients = '/clients';
export const users = '/users';
export const clientUsers = '/client-users';
export const loginURL = '/auth/login';
export const magicLinkLoginURL = '/auth/magic-link';
export const signUpURL = '/auth/signup';
export const refreshTokenReq = '/auth/refresh';
export const emailVerificationURL = '/auth/email-verification';
export const resendVerificationURL = '/auth/resend-verification';
export const uploadFileURL = '/et-file-manager/upload-url';
export const fotrgetPasswordURL = '/auth/resetPassword';
export const updatePasswordURL = '/auth/password';

export const reports = 'reports';
export const tokens = 'tokens';
export const clientsConfigurations = '/configurations';
export const configurationKey = (key: string) => `/configurations/${key}`;
export const clientConfigurationKey = (clientId: number, key: string) =>
  `/clients/${clientId}/configurations/${key}`;
export const clientConfigurations = (clientId: number) =>
  `/clients/${clientId}/configurations`;

export const updateClientURL = (clientId: number) => `/clients/${clientId}`;

export const tokenInstructionEmail = (tokenId: number) =>
  `${tokens}/${tokenId}/tokenInstructionEmail`;

// API keys
export const clientApiKeys = (clientId: string) =>
  `/clients/${clientId}/api-keys`;

export const getPaymentPrices = `payment/prices`;

export const paymentSubscription = (priceId: string) =>
  `payment/prices/${priceId}/subscription-sessions`;

export const paymentSubscriptionDetails = (subscriptionId: string) =>
  `payment/subscriptions/${subscriptionId}`;

export const paymentPortalSessions = 'payment/portal-sessions';

export const clientActivitiesReq = '/clients-activities';

export const reportedChecklistItemsReq = (checklistItemId: number) =>
  `/checklist-items/${checklistItemId}/reported-items`;

export const unitsCountReq = `${units}/count`;

export const reportsCountReq = `${reports}/count`;
export const freeReportsCountReq = `${reports}/count`;

export const reportIssues = `/reports-issues`;

export const issueDetails = (id: number) => `/reports-issues/${id}`;

export const reportsSharingToken = (reportId: number) =>
  `${reports}/${reportId}/tokens`;

export const reportSignature = (reportId: number, token: string) =>
  `/reports/${reportId}/client-signatures?token=${token}`;

export const webhooks = '/hooks';

export const webhookDetails = (id: number) => `/hooks/${id}`;
export const unitChecklists = (unitId: number) => `/units/${unitId}/checklists`;
export const unitChecklist = (checklistId: number) =>
  `/checklists/${checklistId}`;
export const unitChecklistCategories = (unitId: number, checklistId: number) =>
  `/units/${unitId}/checklists/${checklistId}/categories`;
export const unitChecklistCategoryItems = (
  unitId: number,
  checklistId: number,
  categoryId: number
) =>
  `/units/${unitId}/checklists/${checklistId}/categories/${categoryId}/checklist-items`;
export const unitChecklistTokens = (checklistId: number) =>
  `/checklists/${checklistId}/tokens`;

export const templatesURL = `/templates`;
export const templateCategories = (templateId: number) =>
  `${templatesURL}/${templateId}/categories`;
export const templateCategoryItems = (templateId: number, categoryId: number) =>
  `${templatesURL}/${templateId}/categories/${categoryId}/checklist-items`;
export const assignTemplateToChecklistURL = (
  checklistId: number,
  templateId: number
) => `/checklists/${checklistId}/templates/${templateId}/clone`;
export const addLogic = (unitId: number, criteriaId: number) =>
  `/units/${unitId}/logics/checklistItems/${criteriaId}`;
export const updateLogic = (unitId: number, logicId: number) =>
  `/units/${unitId}/logics/${logicId}`;
export const deleteLogic = (unitId: number, logicId: number) =>
  `/units/${unitId}/logics/${logicId}`;

export const templateAddLogic = (templateId: number, criteriaId: number) =>
  `/templates/${templateId}/logics/checklistItems/${criteriaId}`;
export const templateUpdateLogic = (templateId: number, logicId: number) =>
  `/templates/${templateId}/logics/${logicId}`;
export const templateDeleteLogic = (templateId: number, logicId: number) =>
  `/templates/${templateId}/logics/${logicId}`;

export const addCheck = `/checks`;
export const getCheck = (checkId: number) => `/checks/${checkId}`;
export const getChecks = (
  clientId: number,
  page: number,
  take: number,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
  unitId?: number
) => {
  let path = `/checks/client/${clientId}?page=${page}&take=${take}`;
  if (sortBy) {
    path += `&sort-by=${sortBy}`;
  }
  if (sortOrder) {
    path += `&sort-order=${sortOrder}`;
  }
  if (unitId) {
    path += `&unitId=${unitId}`;
  }
  return path;
};
export const patchCheck = (id: number) => `/checks/${id}`;
