import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as S from './styled';
import { UserMenu } from 'src/components/UserMenu';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CreateCheckFormModal } from 'src/components/CreateCheckFormModal';
import { useSearchParams } from 'react-router-dom';
import { useGetQueryParams } from 'src/hooks/useGetQueryParams';
import useAuth from 'src/hooks/useAuth';
import { PermissionKey, PermissionLevel } from 'src/models/user';
import { useChecklistStore } from 'src/modules/ChecklistBuilder/hooks/useChecklistStore';
import { observer } from 'mobx-react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { useStores } from 'src/hooks/useMobxStores';
import BackendResourceWrapper from 'src/components/BackendResourceWraper';
import { LoadingState } from 'src/axios/types';

export const Header = observer(() => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const {
    backend: { templateStore }
  } = useStores();
  const [showCheckModal, setShowCheckModal] = useState(false);
  const getParam = useGetQueryParams();
  const templateId = getParam('templateId');
  const unitId = getParam('unitId');
  const checklistId = getParam('checklistId');
  const checklistBuilderStore = useChecklistStore();
  const auth = useAuth();
  const [loadingState, setLoadingState] = useState(LoadingState.LOADING);

  const hasAccess = auth.user.rolesPermissions.find(
    (permission) =>
      permission.level === PermissionLevel.ALL &&
      permission.permission === PermissionKey.ALLOW_TO_ACCESS_TEMPLATES
  );

  const onUseTemplate = () => {
    templateStore
      .createTemplate({ title: '', copyOf: templateId })
      .then((res) => {
        navigate(`/checklist-builder?templateId=${res.id}`);
        // window.location.reload();
      });
  };

  return (
    <>
      <S.HeaderContainer>
        <S.BackButton
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            navigate(-1);
            checklistBuilderStore.clearChecklist();
            checklistBuilderStore
              .getChecklist()
              .then(() => {
                setLoadingState(LoadingState.DONE);
              })
              .catch(() => setLoadingState(LoadingState.FAILED));
          }}
        >
          {t('CHECKLIST_BUILDER.BACK_BUTTON')}
        </S.BackButton>
        <BackendResourceWrapper
          states={[checklistBuilderStore.getChecklistState]}
          loadingNode={<></>}
        >
          {checklistBuilderStore.checklist?.client || hasAccess ? (
            <S.NewCheckButtonContainer>
              <S.StyledButton
                startIcon={<ClearAllIcon />}
                variant="outlined"
                onClick={() => setShowCheckModal(true)}
              >
                {t('CHECKLIST_BUILDER.NEW_CHECK_BUTTON')}
              </S.StyledButton>
            </S.NewCheckButtonContainer>
          ) : (
            <S.ButtonsContainer>
              <S.StyledButton
                variant="text"
                startIcon={<VisibilityOutlinedIcon />}
                disabled
              >
                {t('CHECKLIST_BUILDER.PREVIEW_BUTTON')}
              </S.StyledButton>
              <S.StyledButton
                startIcon={<LaunchOutlinedIcon />}
                variant="outlined"
                onClick={() => onUseTemplate()}
              >
                {t('CHECKLIST_BUILDER.USE_TEMPLATE_BUTTON')}
              </S.StyledButton>
            </S.ButtonsContainer>
          )}
        </BackendResourceWrapper>
        <S.UserMenuContainer>
          <UserMenu />
        </S.UserMenuContainer>
      </S.HeaderContainer>
      <CreateCheckFormModal
        open={showCheckModal}
        handleClose={() => setShowCheckModal(false)}
        selectedToken={null}
        unitId={unitId}
        templateId={templateId}
        checklistId={checklistId}
      />
    </>
  );
});
