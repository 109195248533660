import { Box, Chip, Divider, MenuItem, styled } from '@mui/material';
import { FormTextField } from 'src/components/FormFields';

export const StyledMuiSelectField = styled(FormTextField)`
  padding-top: ${({ theme }) => theme.spacing(0.8)} !important;
  .MuiInput-underline,
  .MuiInput-underline:hover {
    &:before,
    &:after {
      border: none;
    }
  }
  .MuiSelect-select.MuiInputBase-input:focus {
    background-color: transparent;
  }
  .MuiInput-root {
    padding-inline: 0;
    .MuiSelect-select {
      padding-inline: ${({ theme }) => theme.spacing(2)};
    }
  }
  ul.MuiList-root.MuiList-padding.MuiMenu-list {
    padding: 0 !important;
  }
  svg {
    right: 20px;
    top: 6px;
  }
  .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input {
    padding-right: 45px;
    padding-top: 4px;
  }
`;

export const StyledMenu = (theme) => ({
  '& ul': {
    padding: '0',
    marginBottom: '8px',
    marginTop: '8px',
    maxWidth: '350px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '500px'
    }
  }
});

export const SubtitleMenuItem = styled(MenuItem)`
  font-weight: 600;
  &.Mui-disabled.MuiMenuItem-root.MuiButtonBase-root {
    color: ${({ theme }) => theme.colors.primary.main};
    opacity: 1;
    &.Mui-selected {
      background-color: transparent;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.borders.color};
  height: 1px;
  &.MuiDivider-root {
    margin: 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root.MuiButtonBase-root:hover {
    background-color: ${({ theme }) => theme.formFields.hover};
  }
  &.MuiMenuItem-root.MuiButtonBase-root.Mui-selected {
    background-color: ${({ theme }) => theme.formFields.active};
  }
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledChip = styled(Chip)`
  ${({ theme }) => `
  color: ${theme.colors.primary.main};
  border: ${theme.borders.shorthand(1)};
  height: ${theme.spacing(3)};
  &:hover{
    cursor: pointer;
  } 
  &.field-chip{
    &.visible{
      visibility: visible;
    }
    visibility : hidden;
  }
  `}
`;

export const StyledChipsMenuItem = styled(StyledMenuItem)`
  display: flex;
  justify-content: space-between;
  svg {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const SelectedOptionsContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(0.5)};
  flex-flow: row wrap;
  overflow: hidden;
  width: 90%;
`
);

export const SelectedOptionsInsideMenu = styled(SelectedOptionsContainer)(
  ({ theme }) => `
  max-height: 25px;
`
);

export const FieldContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: start;
  span.hidden-items {
    padding-top: ${theme.spacing(0.5)};
  }
`
);
