import { Box, Button, styled } from '@mui/material';

export const HeaderContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    border-bottom: ${theme.borders.shorthand(1)};
    background-color: ${theme.palette.background.paper};
    padding: ${theme.spacing(2)} 0;
    flex-wrap: wrap;
    ${theme.breakpoints.up('md')}{
      flex-wrap: nowrap;
    }
    `
);

export const BackButton = styled(Button)`
  margin-right: auto;
`;

export const UserMenuContainer = styled(Box)(
  ({ theme }) => `
  ${theme.breakpoints.up('md')}{
    margin-left: 10.5%;
  }
  @media screen and (max-width: 498px) {
    order: 2;
  }
`
);

export const StyledButton = styled(Button)(
  ({ theme }) => `
  border-radius: ${theme.buttons.rounded.smallRadius};
  svg{
    fill: ${theme.colors.primary.main};
  }
`
);

export const NewCheckButtonContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};
  ${theme.breakpoints.up('md')}{
    margin-right: 0;
    margin-left: 0;
  }
  @media screen and (max-width: 334px) {
    order: 3;
  }
  margin-left: ${theme.spacing(1)};
`
);

export const ButtonsContainer = styled(NewCheckButtonContainer)(
  ({ theme }) => `
  @media screen and (max-width: 498px) {
    order: 3;
  }
  margin-right: ${theme.spacing(1)};
  margin-left: 0;
`
);
