import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const Body = styled(Box)(
  ({ theme }) => `
  z-index: 0;
  height: 100%;
  overflow-y: auto;
  background-color: ${theme.palette.background.default};
  width: 100%;
  padding-top: ${theme.spacing(2)};

`
);
