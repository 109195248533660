const esJSON = {
  NO: 'No',
  YES: 'Sí',
  NOT_SURE: 'No estoy seguro',
  NA: 'N/A',
  REPORT_DETAILS_HEADER_TITLE: 'Detalles del informe',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'Descargar PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Descargar XLSX',
  REPORT_DETAILS_NO_ANSWER: 'Sin respuesta',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Responsabilidad',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'Esta sección muestra quién realizó el informe.',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Verificación',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Todos los datos verificados con Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unidad',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspección',
  ISSUES_HEADER: 'Marcado',
  ISSUES_SUBHEADER: 'Gestionar los problemas encontrados durante la inspección',
  ISSUES_PRIORITY: 'Prioridad',
  ISSUES_REPORTED: 'problemas reportados',
  COMPLETED_AT: 'Completado en',
  BY: 'por',
  ON: 'en',
  CRITERIA_CHECKED: 'Los criterios fueron verificados a lo largo de',
  SECTIONS: 'secciones',
  NO_FLAGGED_ITEMS: 'No se marcaron artículos',
  FLAGGED_ITEMS: 'los artículos fueron marcados a lo largo',
  FLAGS_RAISED: 'banderas levantadas',
  CHECKER: 'El inspector informó que estaban',
  NO_ISSUES_RAISED: 'El informe no tiene problemas.',

  ISSUE_RAISED: 'Problema planteado',
  REMARK: 'Observación',
  LOCATION: 'Ubicación',
  UPPERCASE_TITLE: 'TÍTULO',
  STATUS: 'ESTADO',
  UPPERCASE_UNIT: 'UNIDAD',
  UNIT: 'Unidad',
  DEVICE_DETAILS: 'Detalles del dispositivo',
  DATE: 'Fecha',
  NAME: 'Nombre',
  SIGNATURE: 'Firma',
  COUNTER_SIGNATURE_CREATED: 'co-firma agregada',
  CO_SIGNED: 'Co-firmado',
  CO_SIGNATURE: 'Co-firma',
  IP_ADDRESS: 'Dirección IP',
  SIGN: 'Firmar',
  DOWNLOAD: 'Descargar',
  DOWNLOADED: 'Descargado exitosamente',
  CHECKLIST: 'Lista de Verificación',
  IMAGES: 'IMÁGENES',
  REPORTED: 'REPORTADO',
  ID: 'IDENTIFICACIÓN',
  HIGH: 'Alto',
  MEDIUM: 'Medio',
  LOW: 'Bajo',
  SAVED: 'Guardado exitosamente',
  REPORT_SUMMARY: 'Resumen del informe',
  CRITERIA: 'Criterios',
  RESPONSE: 'Respuesta',
  ISSUE_ID: 'ID del problema',
  ISSUES_TABLE: 'Tabla de problemas',
  SECTION: 'Sección',
  CONDITION_RESPONSES: {
    DAMAGED: 'Dañado',
    POOR: 'Pobre',
    FAIR: 'Justo',
    GOOD: 'Bueno',
    GREAT: 'Excelente'
  },
  CUSTOMISE: 'Personalizar',
  REPORT_CONFIG: {
    SHOW_SUMMARY: 'Resumen del informe',
    SHOW_ISSUES: 'Problemas detectados',
    SHOW_FLAGGED: 'Elementos marcados',
    SHOW_SCORING: 'Puntuación',
    SHOW_CO_SIGNATURE: 'Co-firma',
    SHOW_WATERMARK: 'Imágenes con marcas de agua'
  },
  SCORING_SUMMARY: {
    SCORING: 'Puntuación',
    ITEM_TYPES: {
      SINGLE_CHOICE: 'Selección múltiple',
      'Yes/No': 'Sí/No',
      MULTI_MEDIA: 'Multimedia',
      CONDITION: 'Condición',
      TEXT: 'Texto',
      MULTIPLE_CHOICE: 'Selección múltiple - respuesta múltiple'
    },
    TABLE: {
      NUMBER: 'Núm.',
      SECTION: 'Sección',
      OBTAINED: 'Obtenido',
      POSSIBLE: 'Posible',
      SCORE: 'Puntuación',
      TOTAL: 'Total'
    }
  },
  FLAGGED_ITEMS_ACCORDION_SUMMARY: 'Elementos marcados',
  SECTION_NAME: 'Sección',
  NO_FLAGGED_ITEMS_CREATED: 'No hay elementos marcados'
};

export default esJSON;
