import { Typography, styled } from '@mui/material';

export const FileUploaderContainer = styled('div')<{ $hasError: boolean }>(
  ({ theme, $hasError }) => ({
    display: 'flex',
    justifyContent: 'center',
    // padding: theme.spacing(3),
    borderRadius: theme.spacing(0),
    position: 'relative',
    borderBottom: $hasError ? `1px solid ${theme.colors.error.main}` : 'none'
  })
);

export const ImagePlaceHolderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(5),
  width: theme.spacing(5),
  cursor: 'pointer',
  textDecoration: 'none',
  borderRadius: '50%',
  overflow: 'hidden',
  transition: '.9s',
  ':hover': {
    backgroundColor: theme.icon.tertiary.backgroundHover
  }
}));

export const ImagePreview = styled('img')(({ theme }) => ({
  height: theme.spacing(8.5),
  width: theme.spacing(8.5),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
  ':hover': {
    cursor: 'pointer'
  }
}));

export const LoadingContainer = styled('div')(({ theme }) => ({
  height: theme.spacing(15),
  width: theme.spacing(15),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const CancelIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(1)}`,
  right: `-${theme.spacing(1)}`,
  cursor: 'pointer'
}));

export const Label = styled(Typography)(
  ({ theme }) => `
padding-inline: ${theme.spacing(2)};
padding-top: ${theme.spacing(1)};
`
);

export const ErrorText = styled(Typography)(
  ({ theme }) => `
  font-weight: bold;
  color: ${theme.colors.error.main};
  `
);

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
