import { Connections } from '../../connections';
import { Stores } from '..';
import { UnitStore } from './unit';
import { IssuesStore } from './issues';
import { InspectionTokenStore } from './inspectionToken';
import { FileStore } from './file';
import { ReportStore } from './report';
import { HooksStore } from './hooks';
import { ChecklistStore } from './checklist';
import { TemplateStore } from './template';
import { instructionsStore } from './instructions';
import { ChecksStore } from './checks/checks.store';
import { ChecklistBuilderStore } from 'src/modules/ChecklistBuilder/store/checklist-builder.store';

export class BackendStores {
  unit = new UnitStore(this.rootStore);

  issuesStore = new IssuesStore(this.rootStore);

  hooksStore = new HooksStore(this.rootStore);

  inspectionTokenStore = new InspectionTokenStore(this.rootStore);

  fileStore = new FileStore(this.rootStore);

  reportStore = new ReportStore(this.rootStore);

  checklistStore = new ChecklistStore(this.rootStore);

  templateStore = new TemplateStore(this.rootStore);

  instructions = new instructionsStore(this.rootStore);

  checksStore = new ChecksStore(this.rootStore);

  checklistBuilderStore = new ChecklistBuilderStore(this.rootStore);

  constructor(public rootStore: Stores) {}
}
