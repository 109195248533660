import { styled } from '@mui/material';

export const FileUploaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  borderBottom: `1px solid #D9DBE9`,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0),
  position: 'relative'
}));

export const ImagePlaceHolderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(5),
  width: theme.spacing(5),
  cursor: 'pointer',
  // backgroundColor: theme.colors.alpha.black[10],
  borderRadius: theme.spacing(0.5),

  '&.create_unit_image': {
    display: 'inline-block',
    textDecoration: 'none',
    /*width: 100px;*/
    /*height: 50px;*/
    /*line-height: 120px; */
    borderRadius: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    transition: '.9s'
  },

  ':hover': {
    backgroundColor: '#a0a3bd40'
  }
}));

export const ImagePreview = styled('img')(({ theme }) => ({
  height: theme.spacing(8.5),
  width: theme.spacing(8.5),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover'
}));

export const LoadingContainer = styled('div')(({ theme }) => ({
  height: theme.spacing(15),
  width: theme.spacing(15),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const CancelIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  cursor: 'pointer'
}));
