import { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Popover,
  Stack,
  Typography,
  styled
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { BaseModalProps, PopOverButtons } from './types';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { LoadingState } from 'src/axios/types';
import * as S from './index.styled';

export const BaseModal: FC<BaseModalProps> = ({
  open,
  handleClose,
  handleAction,
  actionState,
  footer,
  header,
  actionDisabled,
  children,
  popOverButtons,
  showXCloseButton,
  hideFooter
}) => {
  const PopOverButton = ({ buttons }: { buttons?: PopOverButtons }) => {
    return (
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <Button
              variant="contained"
              {...(!buttons && handleAction && { onClick: handleAction })}
              {...(buttons &&
                buttons?.length && { ...bindTrigger(popupState) })}
              disabled={actionDisabled || actionState === LoadingState.LOADING}
            >
              {actionState === LoadingState.LOADING ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={15} color="inherit" />
                  <span style={{ marginLeft: '8px' }}>
                    {' '}
                    {footer.actionButton}
                  </span>
                </div>
              ) : (
                footer.actionButton
              )}
            </Button>
            {buttons && !!buttons?.length && (
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 1
                  }}
                >
                  {(buttons || [])?.map((btn, index) => (
                    <Button
                      key={index}
                      sx={{ marginBottom: 1 }}
                      variant="contained"
                      onClick={btn.onClick}
                    >
                      {btn.title}
                    </Button>
                  ))}
                </Box>
              </Popover>
            )}
          </div>
        )}
      </PopupState>
    );
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <S.ModalBox>
        <Stack direction="row" justifyContent="space-between">
          <S.ModalBoxHeader>
            <Typography variant="h3">{header.title}</Typography>
            <Typography variant="subtitle1" lineHeight={1.2}>
              {header.description}
            </Typography>
          </S.ModalBoxHeader>
          {showXCloseButton && (
            <IconButton
              sx={{
                paddingBottom: '12px',
                paddingRight: '16px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent'
                }
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
        <S.styledBox>{children}</S.styledBox>
        {!hideFooter && (
          <S.ModalBoxFooter>
            <Button variant="text" onClick={handleClose}>
              {footer.cancelButton}
            </Button>
            <Box paddingX={0.5} />
            <PopOverButton buttons={popOverButtons} />
          </S.ModalBoxFooter>
        )}
      </S.ModalBox>
    </Modal>
  );
};
