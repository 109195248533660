import { styled, Divider as MuiDivider, Typography } from '@mui/material';
import { FormTextField } from 'src/components/FormFields';
import { CloseButtonContainer } from '../Logic/components/LogicUI/styled';

export const InstructionsWrapper = styled('div')<{ $isLastItem?: boolean }>(
  ({ theme, $isLastItem }) => `
  border-left: ${theme.borders.shorthand(2)};
  border-right: ${theme.borders.shorthand(2)};
  border-bottom: ${
    $isLastItem ? theme.borders.shorthand(2) : theme.borders.shorthand(1)
  };
  padding:  ${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(2)} ;
  margin-left: ${theme.spacing(2)};
  background-color: ${theme.palette.background.paper};
`
);

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ItemsContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing(1)};
  flex-wrap: wrap;
  ${theme.breakpoints.up('sm')}{
    flex-wrap: nowrap;
  }
`
);

export const TextField = styled(FormTextField)(
  ({ theme }) => `
  ${theme.breakpoints.up('sm')}{
    min-width: 355px;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-multiline {
    padding: ${theme.spacing(1)};
  }
`
);

export const Divider = styled(MuiDivider)`
  background-color: ${({ theme }) => theme.borders.color};
`;

export const ButtonContainer = styled(CloseButtonContainer)`
  padding-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const Label = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
