import { Zoom } from '@mui/material';
import { VariantType, useSnackbar } from 'notistack';

export const useToaster = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (message: string, variant: VariantType, duration = 2000) =>
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom,
      autoHideDuration: duration
    });
};
