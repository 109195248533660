import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { Card, DialogContent, Zoom, Box, Alert } from '@mui/material';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HeadingForm } from './components/HeadingForm';
import { ActionButtons } from './components/ActionButtons';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { ChecklistItemCategory } from 'src/models/unit';
import { observer } from 'mobx-react';
import { CategoryDto } from 'src/stores/backend/unit/types';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import PageHeader from 'src/components/PageHeader';
import { FloatingToolbar } from 'src/components/FloatingToolbar';
import { useParams } from 'react-router-dom';
import * as S from './styled';
import { Section } from './components/Section';
import { useChecklistStore } from 'src/modules/ChecklistBuilder/hooks/useChecklistStore';

interface Props {
  onCategoriesDragEnd: (result: DropResult) => Promise<void>;
  categories: ChecklistItemCategory[];
  pageTitle: string;
  subpageItem?: string;
  checklistDescription?: string;
  checklistImageURL?: string;
  isReadOnly?: boolean;
  ParentTitle?: string;
  ParentTitleLink?: string;
}

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)'
  },
  listItem: {}
});

export const ChecklistItemsFormUI: FC<Props> = observer(
  ({
    onCategoriesDragEnd,
    categories,
    pageTitle,
    subpageItem,
    ParentTitle,
    ParentTitleLink,
    checklistDescription,
    isReadOnly = false
  }) => {
    const { t } = useTranslation();
    const sectionsRefs = useRef({});
    const [activeSection, setActiveSection] = useState<any>({});
    const [activeDraggableId, setActiveDraggableId] = useState(undefined);
    const [highestOrder, setHighestOrder] = useState(0);
    const [hideInstructions, setHideInstructions] = useState(false);
    const [expandedSectionId, setExpandedSectionId] = useState(undefined);
    const store = useChecklistStore();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      const currentOrders = categories.length
        ? categories.map(({ order }) => order || 0)
        : [-1];
      setHighestOrder(Math.max(...currentOrders));
      if (categories.length > 0) {
        setExpandedSectionId(categories[0].id);
        setActiveSection({
          id: categories[0].id,
          order: categories[0].order,
          title: categories[0].name
        });
      }
    }, []);

    const addNewSection = (
      order: number = highestOrder,
      copyof: number = undefined
    ) => {
      setHighestOrder(highestOrder + 1);
      const dto: CategoryDto = {
        name: ' ',
        order: order + 1,
        isDeleted: false
      };
      if (copyof) {
        dto.copyof = copyof;
      }
      const request = store.addNewCategory(dto);
      request
        .then((newCategory) => {
          setActiveSection({
            id: newCategory.id,
            order: newCategory.order
          });
        })
        .then(handleSaveSuccess);
    };

    const handleSaveSuccess = () => {
      enqueueSnackbar(t('CHECKLIST_BUILDER.SAVED_MESSAGE'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom,
        autoHideDuration: 2000
      });
    };

    return (
      <React.Fragment>
        <ConditionalWrapper show={!hideInstructions && isReadOnly}>
          <S.StyledAlert
            severity="info"
            onClose={() => setHideInstructions(true)}
          >
            {t('PLATFORM_TEMPLATE_INFO')}
          </S.StyledAlert>
        </ConditionalWrapper>
        <S.HeaderBorderBox>
          <HeadingForm
            title={pageTitle}
            description={checklistDescription}
            isReadOnly={isReadOnly}
          />
        </S.HeaderBorderBox>
        {categories.length > 0 && (
          <S.StyledDialogContent>
            <S.SectionsContainer>
              <DragDropContext
                onDragEnd={onCategoriesDragEnd}
                onBeforeDragStart={({ draggableId }) =>
                  setActiveDraggableId(draggableId)
                }
              >
                <Droppable droppableId={'droppable-categroies'}>
                  {(provided) => {
                    return (
                      <Box ref={provided.innerRef}>
                        {_.sortBy(categories || [], 'order').map(
                          (category, index) => {
                            return (
                              <Draggable
                                key={category.id}
                                draggableId={category.id.toString()}
                                index={index}
                                isDragDisabled={isReadOnly}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...(category.id != activeDraggableId && {
                                        style: { transition: 'none !important' }
                                      })}
                                    >
                                      <Section
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        category={category}
                                        currentCatgories={(categories || [])
                                          .filter(
                                            (cat) =>
                                              !cat.isDeleted &&
                                              cat.id != category.id
                                          )
                                          .map((cat) => cat.name)}
                                        isExpanded={
                                          expandedSectionId == category.id
                                        }
                                        isReadOnly={isReadOnly}
                                        key={index}
                                        checklistItems={category.checklistItems}
                                        ref={(el) =>
                                          (sectionsRefs.current[category.id] =
                                            el)
                                        }
                                        active={
                                          activeSection?.id == category.id
                                        }
                                        onToggle={(expanded) => {
                                          setExpandedSectionId(
                                            expanded ? category.id : undefined
                                          );
                                        }}
                                        onClick={() => {
                                          setActiveSection({
                                            id: category.id,
                                            order: category.order,
                                            title: category.name
                                          });
                                        }}
                                        onDeleteConfirmed={() => {
                                          const activeIndex =
                                            categories.findIndex(
                                              (e) => e.id == activeSection.id
                                            );
                                          const newActiveSection =
                                            activeIndex - 1 in categories
                                              ? categories[activeIndex - 1]
                                              : categories[1];
                                          if (newActiveSection) {
                                            setActiveSection({
                                              id: newActiveSection.id,
                                              order: newActiveSection.order,
                                              title: newActiveSection.name
                                            });
                                          }
                                        }}
                                      ></Section>
                                    </Box>
                                  );
                                }}
                              </Draggable>
                            );
                          }
                        )}
                      </Box>
                    );
                  }}
                </Droppable>
              </DragDropContext>
              {categories.length > 0 && sectionsRefs.current && !isReadOnly && (
                <FloatingToolbar
                  actions={{
                    DELETE: () => {
                      sectionsRefs.current[activeSection.id]?.deleteSection();
                    },
                    ADD_CRITERIA: () =>
                      sectionsRefs.current[activeSection.id]?.addCriteria(),
                    ADD_SECTION: () => addNewSection(activeSection.order),
                    DUPLICATE: () => addNewSection(undefined, activeSection.id)
                  }}
                  getActiveCategoryRef={() =>
                    sectionsRefs?.current[activeSection.id]?.element
                  }
                />
              )}
            </S.SectionsContainer>
          </S.StyledDialogContent>
        )}
        <ConditionalWrapper show={!isReadOnly}>
          <ActionButtons
            onAddSection={() => {
              addNewSection();
            }}
            onAddQuestion={
              categories.length == 0
                ? undefined
                : () => {
                    sectionsRefs.current[
                      categories[categories.length - 1].id
                    ].addCriteria(true);
                  }
            }
            justifyContent="start"
          ></ActionButtons>
        </ConditionalWrapper>
      </React.Fragment>
    );
  }
);
