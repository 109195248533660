import { Avatar, Button, Popover, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import * as S from './styled';

export const UserMenu = () => {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const handleLogout = async (): Promise<void> => {
    setOpen(false);
    await logout();
    navigate('/login');
  };

  return (
    <>
      <S.UserBoxButton ref={ref} onClick={() => setOpen(true)}>
        <Typography variant="h4">{user.name?.split(' ')[0]}</Typography>
        {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </S.UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <S.MenuUserBox>
          <Avatar
            variant="rounded"
            alt={user.name}
            src={user.client.imageURL}
          />
          <S.UserBoxLabel variant="body1">{user.name}</S.UserBoxLabel>
        </S.MenuUserBox>
        <S.StyledDivider />
        <S.MenuItem>
          <Button
            color="primary"
            fullWidth
            startIcon={<AccountBoxIcon />}
            onClick={() => {
              navigate(`/checkfirst/dashboard/users/${user.id}/updateProfile`);
              setOpen(false);
            }}
          >
            {t('UPDATE_PROFILE')}
          </Button>
        </S.MenuItem>
        <S.MenuItem>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            startIcon={<LockOpenIcon />}
          >
            {t('SIGN_OUT')}
          </Button>
        </S.MenuItem>
      </Popover>
    </>
  );
};
