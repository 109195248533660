import { FeatureStatus } from 'src/models/feature.toggle';
import { ChecklistItemType } from 'src/models/unit';

export const OPTION_SETS = [
  [
    {
      order: 0,
      title: 'YES'
    },
    {
      order: 1,
      title: 'NO'
    },
    {
      order: 2,
      title: 'NA'
    }
  ],
  [
    {
      order: 0,
      title: 'GOOD'
    },
    {
      order: 1,
      title: 'FAIR'
    },
    {
      order: 2,
      title: 'POOR'
    },
    {
      order: 3,
      title: 'NA'
    }
  ],
  [
    {
      order: 0,
      title: 'CONFORM'
    },
    {
      order: 1,
      title: 'NOT_CONFORM'
    },
    {
      order: 2,
      title: 'PENDING'
    },
    {
      order: 3,
      title: 'NA'
    }
  ]
];

const MULTIPLE_CHOICE_TYPES_BASE = [
  ChecklistItemType.CHECK_BOX,
  ChecklistItemType.RADIO_BUTTON
];

export const MULTIPLE_CHOICE_TYPES =
  process.env.REACT_APP_MULTIPLE_CHOICE === FeatureStatus.ENABLED
    ? [...MULTIPLE_CHOICE_TYPES_BASE, ChecklistItemType.MULTIPLE_CHOICE]
    : MULTIPLE_CHOICE_TYPES_BASE;

export const MULTIPLE_CHOICE_VALUES = [
  'option-set-selected',
  `option-set-0`,
  `option-set-1`,
  `option-set-2`,
  'custom-options'
];
