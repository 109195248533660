import { makeObservable, observable } from 'mobx';
import { httpGet } from 'src/axios/axiosUtils';
import { issueDetails, reportIssues, reports, units } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import type { PaginationMeta } from 'src/models/pagination';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import type { GetIssueDto, GetIssuesParams, GetMediaDto } from './types';
import { getMediaType } from 'src/utils/media';

export class IssuesStore extends BaseBackendStore {
  @observable issue: GetIssueDto;

  @observable issues: GetIssueDto[] = [];

  @observable issuesMeta: PaginationMeta;

  @observable issuesState: LoadingState;

  @observable issueState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  async getIssues(params: GetIssuesParams) {
    try {
      const queryParams = new URLSearchParams(params as any).toString();

      this.issuesState = LoadingState.LOADING;
      const res = await httpGet(`${reportIssues}?${queryParams}`, false);
      this.issuesMeta = res.meta;
      this.issues = res.data;
      this.issuesState = LoadingState.DONE;
    } catch (err) {
      this.issuesState = LoadingState.FAILED;
    }
  }

  async getReportIssues(
    reportId: number,
    params: GetIssuesParams,
    token?: string
  ) {
    try {
      let config = {};
      const translationLanguage =
        this.rootStore.backend.reportStore.translationLanguage;
      if (translationLanguage) {
        config = {
          headers: {
            'translation-lang': translationLanguage
          }
        };
      }
      const queryParams = new URLSearchParams(params as any).toString();
      let issuesReq = `${reports}/${reportId}/issues`;
      issuesReq += `?${queryParams}`;
      if (token) {
        issuesReq += `&token=${token}`;
      }
      this.issuesState = LoadingState.LOADING;
      const res = await httpGet(`${issuesReq}`, false, config);
      this.issuesMeta = res.meta;
      this.issues = res.data;
      this.issuesState = LoadingState.DONE;
    } catch (err) {
      console.log('error', err);
      this.issuesState = LoadingState.FAILED;
    }
  }

  async getIssueDetails(issueId: number) {
    try {
      this.issueState = LoadingState.LOADING;
      this.issue = await httpGet(`${issueDetails(issueId)}`, false);
      this.issue.media = await this.mapMedia(this.issue.media);
      this.issueState = LoadingState.DONE;
    } catch (err) {
      this.issueState = LoadingState.FAILED;
    }
  }

  async mapMedia(media: GetMediaDto[]) {
    const mappedMedia = [];
    for (const m of media) {
      if (!m.type) {
        m.type = await getMediaType(m.url);
      }
      mappedMedia.push(m);
    }
    return mappedMedia;
  }
}
