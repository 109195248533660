import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Divider,
  useMediaQuery
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import * as S from './styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onAddQuestion?: () => void;
  onAddSection?: () => void;
  justifyContent?: 'start' | 'end';
};

export const ActionButtons: React.FC<Props> = ({
  onAddQuestion,
  onAddSection,
  justifyContent
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  return (
    <>
      {isDesktop && (
        <S.ButtonsContainer justifyContent={justifyContent}>
          {onAddQuestion && (
            <S.StyledButton
              variant="outlined"
              size="large"
              startIcon={<AddCircleOutlineIcon />}
              onClick={onAddQuestion}
            >
              {t('CHECKLIST_BUILDER.ADD_CRITERIA_BUTTON_LABEL')}
            </S.StyledButton>
          )}
          {onAddSection && (
            <S.StyledButton
              variant="outlined"
              size="large"
              startIcon={<ViewAgendaOutlinedIcon />}
              onClick={onAddSection}
            >
              {t('CHECKLIST_BUILDER.ADD_SECTION_BUTTON_LABEL')}
            </S.StyledButton>
          )}
        </S.ButtonsContainer>
      )}
    </>
  );
};
