import {
  Box,
  Divider,
  IconButton,
  Typography,
  alpha,
  styled
} from '@mui/material';

export const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  display: flex;
  gap: ${theme.spacing(2)};
  border-radius: ${theme.general.borderRadiusLg};
  .MuiTypography-root{
    font-weight: bold;
  }
  
  &:hover {
    background: ${theme.colors.alpha.trueWhite};
  }

  svg{
    fill: ${theme.borders.color};
  }
  `
);

export const MenuUserBox = styled(Box)(
  ({ theme }) => `
  background: ${alpha(theme.colors.alpha.black[100], 0.08)};
  padding: ${theme.spacing(2)};
  min-width: 210px;
  display: flex;
  align-items: center;
  `
);

export const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
  font-weight: ${theme.typography.fontWeightBold};
  color: ${theme.colors.primary.main};
  margin-left: ${theme.spacing(1)};
  `
);

export const Username = styled(Typography)(
  ({ theme }) => `
  color: ${theme.colors.primary};
  margin-right: ${theme.spacing(2)}
`
);

export const StyledDivider = styled(Divider)`
  margin-bottom: 0;
`;

export const MenuItem = styled(Box)(
  ({ theme }) => `
  margin: ${theme.spacing(1)};
  button {
    justify-content: start;
  }
`
);
