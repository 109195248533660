import ChipInput, { Props } from 'material-ui-chip-input';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormHelperText, styled, useTheme } from '@mui/material';

export const StyledChip = styled('div')(({ theme }) => ({
  backgroundColor: theme.borders.color,
  padding: '5px',
  borderRadius: '20px',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '14px',
  marginRight: '14px',
  fontSize: '14px'
}));

export const StyledChipInput = styled(ChipInput)(({ theme }) => ({
  width: '100%'
}));

const render = ({ text, handleDelete }, key) => (
  <StyledChip key={key} onClick={handleDelete}>
    <span
      style={{
        margin: '1px'
      }}
    >
      {text?.title || text}
    </span>
    <CancelIcon />
  </StyledChip>
);

const TagsInput = (props: Props) => {
  const theme = useTheme();
  const { helperText, ...rest } = props;
  return (
    <>
      <StyledChipInput {...rest} chipRenderer={render} theme={theme} />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default TagsInput;
