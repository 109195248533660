import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ChecklistItemType, ViewChecklistItem } from 'src/models/unit';
import { CHECKLIST_ITEM_TYPE_COMPONENT_MAP } from './constants';
import Criteria from '../Criteria';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { useEffect, useRef, useState } from 'react';
import * as S from './styled';
import { useStores } from 'src/hooks/useMobxStores';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LogicDto } from 'src/models/logic';
import { observer } from 'mobx-react';
import { reorder } from 'src/helpers/Array';
import ConfirmationDialog from 'src/components/Dialogs/confirmationDialog';
import _ from 'lodash';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { isMultiChoice } from '../Criteria/utils';

const validationSchema = (t: any) =>
  Yup.object().shape({
    rule: Yup.string().required(),
    action: Yup.string().required(),
    values: Yup.array()
      .of(Yup.string().required())
      .when('rule', {
        is: (val) => !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(val),
        then: Yup.array().min(1)
      })
      .when('rule', {
        is: (val) => ['BETWEEN', 'NOT_BETWEEN'].includes(val),
        then: Yup.array().min(2)
      })
  });

interface Props {
  level: number;
  sectionId: number;
  logicState: LogicDto;
  parentChecklistItemState: ViewChecklistItem;
  isLastItem?: boolean;
  isReadOnly?: boolean;
  onAddCriteria?: () => void;
  onDelete?: () => void;
}

export const Logic = observer(
  ({
    sectionId,
    logicState,
    parentChecklistItemState,
    level,
    isLastItem = false,
    isReadOnly,
    onDelete
  }: Props) => {
    if (logicState.isDeleted) return null;
    const { t } = useTranslation();
    const {
      backend: { checklistBuilderStore }
    } = useStores();
    const formRef = useRef<{ dirty: boolean; isValid: boolean; values: any }>();

    const { enqueueSnackbar } = useSnackbar();
    const { unitId, checklistId, templateId } = useParams();
    const [highestOrder, setHighestOrder] = useState<number>(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [activeDraggableId, setActiveDraggableId] = useState(undefined);
    const [formValues, setFormValues] = useState<any>({
      values: isMultiChoice(parentChecklistItemState.type)
        ? (logicState.logicChecklistItemsOptions ?? []).map((e) =>
            e.id.toString()
          )
        : logicState?.values
        ? [...logicState.values]
        : [],
      action:
        logicState.action == 'ADD_CRITERIA' &&
        logicState.subChecklistItems.filter((e) => !e.isDeleted).length == 0
          ? ''
          : logicState.action ?? '',
      rule: logicState.rule ?? '',
      score: logicState.score ?? 0
    });
    const [activeCriteria, setActiveCriteria] = useState<any>({});

    const onChecklistItemsDragEnd = ({ destination, source }: DropResult) => {
      if (!destination) return;
      const orderedCheckListItem = reorder(
        logicState.subChecklistItems,
        source.index,
        destination.index
      ).map((item, index) => ({
        ...item,
        order: index
      }));

      const request = checklistBuilderStore.reorderChecklistItems(
        orderedCheckListItem,
        logicState,
        {
          categoryId: sectionId
        }
      );
      request.then(handleSuccess);
    };

    const onSubmit = (values) => {
      if (values.action != 'SCORING') {
        values.score = 0;
      }
      const request = checklistBuilderStore.updateLogic(logicState, {
        ...values,
        id: logicState.id
      });
      request.then(handleSuccess);
    };

    const addNewCriteria = (order = highestOrder) => {
      const payload = {
        title: ' ', //TODO: need to change backend validation for adding a new section
        isDeleted: false,
        type: ChecklistItemType.MULTIPLE_CHOICE,
        mandatory: true,
        checklistItemOptions: [
          { id: null, isDeleted: false, order: 0, title: 'Yes' },
          { id: null, isDeleted: false, order: 1, title: 'No' },
          { id: null, isDeleted: false, order: 2, title: 'N/A' }
        ],
        order: order + 1
      };
      setHighestOrder(highestOrder + 1);
      const request = checklistBuilderStore.addChecklistItem(
        payload,
        logicState,
        {
          categoryId: sectionId
        }
      );
      request.then(({ id, order }) => {
        handleSuccess();
        setActiveCriteria({ id, order });
      });
    };

    const handleSuccess = () => {
      enqueueSnackbar(t('CHECKLIST_BUILDER.SAVED_MESSAGE'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom,
        autoHideDuration: 2000
      });
    };

    const UIComponent =
      CHECKLIST_ITEM_TYPE_COMPONENT_MAP[parentChecklistItemState.type];

    useEffect(() => {
      const currentOrders = logicState?.subChecklistItems?.length
        ? logicState.subChecklistItems.map(({ order }) => order || 0)
        : [-1];
      setHighestOrder(Math.max(...currentOrders));
      return () => {
        if (formRef?.current?.dirty && formRef?.current?.isValid) {
          onSubmit(formRef.current.values);
        }
      };
    }, []);

    const allowedValues =
      parentChecklistItemState?.checklistItemOptions
        ?.filter((e) => !e.isDeleted)
        .reduce((acc, curr) => ({ ...acc, [curr.id]: curr.title }), {}) ?? [];

    const checklistItems = _.sortBy(
      (logicState?.subChecklistItems || []).filter((e) => !e.isDeleted),
      'order'
    );

    const onDeleteCriteria = (setFieldValue, setFieldTouched) => {
      if (
        logicState.subChecklistItems?.filter((e) => !e.isDeleted).length == 0
      ) {
        setFieldValue('action', '');
        setFieldTouched('action', false);
      }
      const activeIndex = checklistItems.findIndex(
        (e) => e.id == activeCriteria.id
      );
      const newActiveCriteria =
        activeIndex - 1 in checklistItems
          ? checklistItems[activeIndex - 1]
          : checklistItems[1];
      if (newActiveCriteria) {
        setActiveCriteria({
          id: newActiveCriteria.id,
          order: newActiveCriteria.order
        });
      }
    };

    return (
      <>
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur
          enableReinitialize
        >
          {({
            values,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
            submitForm
          }) => {
            formRef.current = {
              values,
              isValid,
              dirty
            };
            return (
              <>
                <S.LogicContainer
                  className="logic-container"
                  $isLastItem={
                    isLastItem &&
                    (values.action != 'ADD_CRITERIA' ||
                      (
                        logicState?.subChecklistItems?.filter(
                          (e) => !e.isDeleted
                        ) ?? []
                      ).length == 0)
                  }
                >
                  <Form>
                    <UIComponent
                      onDelete={() => setShowDeleteConfirmation(true)}
                      criteriaType={parentChecklistItemState.type}
                      allowedValues={allowedValues}
                      required={parentChecklistItemState.mandatory}
                      isReadOnly={isReadOnly}
                      addNewCriteria={addNewCriteria}
                      activeCriteriaOrder={activeCriteria}
                      submitForm={submitForm}
                    />
                  </Form>
                </S.LogicContainer>
                <DragDropContext
                  onDragEnd={onChecklistItemsDragEnd}
                  onBeforeDragStart={({ draggableId }) =>
                    setActiveDraggableId(draggableId)
                  }
                >
                  <Droppable
                    droppableId={`droppable-nestedItems-logic-${logicState.id}`}
                  >
                    {(provided) => {
                      return (
                        <div ref={provided.innerRef}>
                          <TransitionGroup>
                            {logicState.action == 'ADD_CRITERIA' &&
                              checklistItems.map((item, index) => {
                                return (
                                  <Collapse key={item.id}>
                                    <div>
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}
                                        isDragDisabled={isReadOnly}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...(item.id !=
                                                activeDraggableId && {
                                                style: {
                                                  transition: 'none !important'
                                                }
                                              })}
                                            >
                                              <Criteria
                                                dragHandleProps={
                                                  provided.dragHandleProps
                                                }
                                                item={item}
                                                highestOrder={highestOrder}
                                                sectionId={sectionId}
                                                parentLogicState={logicState}
                                                level={level + 1}
                                                active={
                                                  item.id == activeCriteria.id
                                                }
                                                isLastItem={
                                                  isLastItem &&
                                                  index ==
                                                    logicState.subChecklistItems.filter(
                                                      (e) => !e.isDeleted
                                                    )?.length -
                                                      1
                                                }
                                                isReadOnly={isReadOnly}
                                                onClick={() => {
                                                  setActiveCriteria({
                                                    order: item.order,
                                                    id: item.id
                                                  });
                                                }}
                                                onDuplicate={() =>
                                                  setHighestOrder(
                                                    highestOrder + 1
                                                  )
                                                }
                                                onDelete={() =>
                                                  onDeleteCriteria(
                                                    setFieldValue,
                                                    setFieldTouched
                                                  )
                                                }
                                                onEnterKeyDown={() =>
                                                  addNewCriteria(
                                                    activeCriteria.order
                                                  )
                                                }
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    </div>
                                  </Collapse>
                                );
                              })}
                          </TransitionGroup>
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </>
            );
          }}
        </Formik>
        <ConfirmationDialog
          open={showDeleteConfirmation}
          onConfirm={onDelete}
          setOpen={setShowDeleteConfirmation}
          title={t('CHECKLIST_BUILDER.LOGIC.DELETE_CONFIRMATION_MESSAGE')}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      </>
    );
  }
);
