import { ModalProps, IconButton } from '@mui/material';
import * as S from './styled';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface Props extends Omit<ModalProps, 'children'> {
  width?: string;
  tabletWidth?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  body: React.ReactNode;
  closeButton?: boolean;
  children?: React.ReactNode;
}

export const ResponsiveModal = ({
  header,
  footer,
  body,
  width,
  tabletWidth,
  closeButton,
  children,
  ...props
}: Props) => {
  return (
    <S.StyledModal {...props}>
      <S.Container width={width} tabletWidth={tabletWidth}>
        {children ? (
          children
        ) : (
          <>
            <S.Header>
              {header}
              {closeButton && (
                <IconButton
                  onClick={(e) => props.onClose?.(e, 'closeButtonClick' as any)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </S.Header>
            {body}
            <S.Footer>{footer}</S.Footer>
          </>
        )}
      </S.Container>
    </S.StyledModal>
  );
};
