import {
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
  colors,
  styled
} from '@mui/material';
import { StyledMuiTextField } from '../../styled';

export const StyledAccordionSummary = styled(AccordionSummary)`
  div[class*='MuiAccordionSummary-content'] {
    margin: 0;
  }
  padding: 0;
  min-height: ${({ theme }) => theme.spacing(8)};
  border-bottom: ${({ theme }) => theme.borders.shorthand(2)};
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;

export const StyledTitle = styled(Typography)`
  padding-left: ${({ theme }) => theme.spacing(1)};
  display: flex;
  align-items: center;
  flex-grow: 2;
`;

export const IconContainer = styled(Box)`
  align-self: end;
`;

export const StyledSectionTitle = styled(StyledMuiTextField)`
  input {
    ${({ theme }) => theme.typography.h3};
    font-weight: normal;
  }
`;

export const CriteriaWrapper = styled('div')`
  // all middle criteria will have top border
  & div:not(:first-of-type) .criteria-form {
    border-top: ${({ theme }) => theme.borders.shorthand(1)};
  }
  // //
  //   & > div:last-of-type .logic-container,
  //   & > div:last-of-type .criteria-form {
  //     border-bottom: ${({ theme }) => theme.borders.shorthand(2)};
  //     border-radius: 0 0 3px 3px;
  //   }
`;

export const StyledSectionContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
