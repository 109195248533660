export enum IssuePriorityType {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

interface IssueChecklistItem {
  id: number;
  report: IssueReport;
}

interface IssueReport {
  id: number;
  unit: IssueReportUnit;
}

interface IssueReportUnit {
  id: number;
  title: string;
}

export enum IssueMediaType {
  VIDEO = 'video',
  IMAGE = 'image'
}
export interface GetMediaDto {
  id: number;
  url: string;
  type: IssueMediaType;
}

export interface GetIssueDto {
  id: number;
  title: string;
  description: string;
  priority: IssuePriorityType;
  createdAt: string;
  reportChecklistItem: IssueChecklistItem;
  media?: GetMediaDto[];
}
export interface GetIssuesParams {
  page: number;
  take: number;
  search?: string;
}
