import { ChecklistItemOptions, ViewChecklistItem } from './unit';

export const OptionalRules = ['IS_EMPTY', 'IS_NOT_EMPTY'];
export const BetweenNumberRules = ['BETWEEN', 'NOT_BETWEEN'];
export type MultipleChoiceRules = 'IS' | 'IS_NOT' | 'IS_EMPTY' | 'IS_NOT_EMPTY';
export type NumberRules =
  | 'EQUALS'
  | 'DOES_NOT_EQUAL'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'GREATER_THAN_OR_EQUAL_TO'
  | 'LESS_THAN_OR_EQUAL_TO'
  | 'BETWEEN'
  | 'NOT_BETWEEN'
  | 'IS_EMPTY'
  | 'IS_NOT_EMPTY';

export type TextRules =
  | 'IS'
  | 'IS_NOT'
  | 'CONTAINS'
  | 'DOES_NOT_CONTAIN'
  | 'STARTS_WITH'
  | 'IS_EMPTY'
  | 'IS_NOT_EMPTY';
export type MultimediaRules = 'CONTAINS' | 'DOES_NOT_CONTAIN';
export type ActionTypes =
  | 'FLAG_REPORT'
  | 'RAISE_ISSUE'
  | 'ADD_CRITERIA'
  | 'SCORING';

export interface LogicDto {
  id?: number;
  rule: MultipleChoiceRules | NumberRules | TextRules;
  values: (string | number)[];
  logicChecklistItemsOptions?: ChecklistItemOptions[];
  action: ActionTypes;
  actionParameters?: {
    issueTitle?: string;
    apiUrl?: string;
  };
  subChecklistItems?: ViewChecklistItem[];
  isDeleted?: boolean;
  score?: number;
}
