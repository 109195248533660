import axios from 'axios';
import { setSession } from 'src/content/pages/Auth/helpers/tokens';
import EventEmitter from './emitter';

// import { stores } from "../mobx-store";

interface ErrorResponse {
  timestamp: string;
  path: string;
  httpStatus: number;
  message: string | string[];
  // stack: string;
}

export const emitter = new EventEmitter();

const apiVersion = `api/v1`;
// Setup Server URL

const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
// Construct Base URL
export const baseURL = `${serverURL}/${apiVersion}`;

const instance = axios.create({
  baseURL,
  timeout: 100000
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // adding Authorization to all requests
    config.headers = {
      ...config.headers
    };
    return config;
  },
  (error) => {
    console.log(error);
    // Do something with request error
    return Promise.reject(error.response.data);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async (error) => {
    const userSession = JSON.parse(localStorage.getItem('user'));
    if (error && error.response.status === 401 && userSession) {
      // console.log('Token-Expired-Refresh-Called');
      try {
        const response = await axios.request({
          baseURL,
          url: '/auth/refresh',
          method: 'put',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
          }
        });
        // console.log('ccacacas' , JSON.stringify( response.data));

        const { accessToken, refreshToken, user } = response.data;

        userSession.SubscriptionType = response.data.user.subscriptionType;
        userSession.freeReportsPerMonth = user.client.freeReportsPerMonth;
        userSession.client.clientSubscription =
          response.data.user.clientSubscription;

        setSession(accessToken, refreshToken, user);
        return await axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (errorAuthorize) {
        console.log('Token-Expired-Refresh-Failed', errorAuthorize.response);
        if (errorAuthorize.response.status === 401) {
          console.log('ccacacaasas');

          setSession(null, null, null);
          (window as Window).location = '/';
        }

        return Promise.reject(errorAuthorize);
      }
    } else {
      emitter.emit('Error', error.response.data);
    }
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);

export default instance;
