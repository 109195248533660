import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const ParentComponentRouter: FC = ({ children }) => {
  if (children) {
    return <>{children}</>;
  }
  return <Outlet />;
};

export default ParentComponentRouter;
