import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    padding-left: ${({ theme }) => theme.spacing(1)};
  }
  .MuiOutlinedInput-root {
    border-radius: 3px;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => theme.borders.shorthand(1)};
    }
    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.error.main};
    }
  }
  .MuiInput-root.MuiInputBase-multiline {
    padding: 0 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .MuiInput-input.MuiInputBase-input.MuiInputBase-inputMultiline {
    min-height: 20px;
    line-height: 20px;
  }
`;
