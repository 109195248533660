import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, styled, Typography, useTheme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { httpPost, uploadFileToS3 } from 'src/axios/axiosUtils';
import { useTranslation } from 'react-i18next';
import { LoadingState } from 'src/axios/types';
import { uploadFileURL } from 'src/axios/requests';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import BackendResourceWrapper from '../BackendResourceWraper';
import ConditionalWrapper from '../ConditionalWrapper';
import {
  CancelIconContainer,
  FileUploaderContainer,
  ImagePlaceHolderContainer,
  ImagePreview,
  LoadingContainer
} from './styled';

export enum FileDomain {
  UNITS = 'UNITS',
  CLIENTS = 'CLIENTS',
  INSTRUCTIONS = 'INSTRUCTIONS',
  CHECKLISTS_UNITS = 'CHECKLISTS_UNITS'
}

interface Props {
  value?: string;
  domain: FileDomain;
  name: string;
  isValid: boolean;
  notValidMessage: string;
  handleBlur: any;
  onChange?: (value: string) => void;
  accecpt?: 'audio/*' | 'video/*' | 'image/*';
  label?: string;
}
/**@deprecated please use FormFileUploader component */
const FileUploader: FC<Props> = ({
  onChange,
  value = '',
  domain,
  accecpt = 'image/*',
  isValid,
  notValidMessage,
  name,
  handleBlur,
  label
}) => {
  const [imageURL, setImageURL] = useState(value);
  const [error, setError] = useState('');

  const { t }: { t: any } = useTranslation();

  const inputFile = useRef<any>(null);

  const theme = useTheme();

  const [uploadingStatue, setUploadingStatue] = useState<LoadingState>(
    LoadingState.IDLE
  );

  useEffect(() => {
    if (!isValid) {
      setError(notValidMessage);
    } else {
      setError('');
    }
  }, [isValid, notValidMessage]);

  useEffect(() => {
    handleChange(imageURL);
    setUploadingStatue(LoadingState.DONE);
  }, [imageURL]);

  const handleChange = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  const openFile = () => {
    if (inputFile.current) {
      inputFile.current!.click();
    }
  };

  const fileChange = async (files: FileList | null) => {
    try {
      const file = files?.item(0);
      if (file) {
        setUploadingStatue(LoadingState.LOADING);
        const { uploadUrl, fileUrl } = await httpPost(uploadFileURL, {
          domain
        });
        console.log(uploadUrl, fileUrl);
        await uploadFileToS3(uploadUrl, file);
        setImageURL(fileUrl);
        setUploadingStatue(LoadingState.DONE);
      }
    } catch {
      setUploadingStatue(LoadingState.FAILED);
    }
  };

  return (
    <>
      <ConditionalWrapper show={!!label}>
        <Typography paddingX={2} paddingTop={1} color={'#A0A3BD'}>
          {label}
        </Typography>
      </ConditionalWrapper>
      <FileUploaderContainer
        onClick={openFile}
        {...(!isValid && { style: { borderColor: theme.colors.error.main } })}
      >
        <input
          ref={inputFile}
          hidden
          type="file"
          accept={accecpt}
          name={name}
          onChange={(e) => fileChange(e.target.files)}
          onBlur={handleBlur}
        />
        <ConditionalWrapper
          show={!imageURL && uploadingStatue !== LoadingState.LOADING}
        >
          <ImagePlaceHolderContainer className={'create_unit_image'}>
            <img
              src="/static/images/placeholders/illustrations/image.png"
              alt="Custom Icon"
              style={{
                position: 'relative',
                top: '9px'
              }}
            />
          </ImagePlaceHolderContainer>
        </ConditionalWrapper>
        <BackendResourceWrapper
          states={[uploadingStatue]}
          showChildrenOnError={true}
          loadingNode={
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          }
        >
          <ConditionalWrapper show={!!imageURL}>
            <ImagePreview alt={name} src={imageURL} />
            <CancelIconContainer onClick={() => setImageURL('')}>
              <CancelIcon color="error" />
            </CancelIconContainer>
          </ConditionalWrapper>
        </BackendResourceWrapper>
      </FileUploaderContainer>
      <ConditionalWrapper show={!!error}>
        <Typography
          margin={'3px 8px 0'}
          fontWeight="bold"
          fontSize={13}
          color={theme.colors.error.main}
        >
          {error}
        </Typography>
      </ConditionalWrapper>
    </>
  );
};

export default FileUploader;
