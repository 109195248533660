import { makeObservable, observable } from 'mobx';
import { httpPost } from 'src/axios/axiosUtils';
import { reportSignature } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import { PostSignatureDto } from './types';
import { Language } from 'src/content/dashboards/reports/report/ReportTranslationDropdown/types';

export class ReportStore extends BaseBackendStore {
  @observable createSignatureState: LoadingState = LoadingState.IDLE;

  @observable freeReportsCount: number = undefined;

  @observable translationLanguage: Language | undefined = undefined;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  async createSignature(
    reportId: number,
    token: string,
    signature: PostSignatureDto
  ) {
    try {
      this.createSignatureState = LoadingState.LOADING;
      const response = await httpPost(
        reportSignature(reportId, token),
        signature
      );
      this.createSignatureState = LoadingState.DONE;
      return response;
    } catch (error) {
      this.createSignatureState = LoadingState.FAILED;
    }
  }

  setFreeReportsCount(number: number) {
    if (number !== undefined && number !== null) {
      console.log('Setting free reports count', number);
      this.freeReportsCount = number;
    }
  }
}
