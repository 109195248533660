import { FC } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface AlertDialogProps extends DialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description?: string;
  onClose?: () => void;
  style?: React.CSSProperties;
  dialogTitleStyle?: React.CSSProperties;
  headerVar?: number;
}

const headVars = ['h1', 'h2', 'h3', 'h4'] as const;

const AlertDialog: FC<AlertDialogProps> = ({
  open,
  setOpen,
  children,
  title,
  onClose,
  style,
  description,
  dialogTitleStyle,
  headerVar = 4,
  ...dialogProps
}) => {
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={style}
      onBackdropClick={() => setOpen(false)}
      {...dialogProps}
    >
      <DialogTitle
        style={{
          borderBottom: `1px solid ${theme.colors.info.lighter}`,
          display: 'flex',
          justifyContent: 'space-between',
          ...dialogTitleStyle
        }}
      >
        <Box>
          <Typography variant={headVars[headerVar - 1]}>{title}</Typography>
          {description && (
            <Typography variant="caption">{description}</Typography>
          )}
        </Box>
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default AlertDialog;
