import { useCallback, useState } from 'react';
import { User, UserRoles } from 'src/models/user';
import { clients as clientsBaseURL } from 'src/axios/requests';
import { httpDelete, httpGet, httpPost } from 'src/axios/axiosUtils';

export interface ClientUser {
  name: string;
  email: string;
  password?: string;
  id: number;
  role: UserRoles;
}

export interface CreateClientUser {
  name: string;
  email: string;
  role?: UserRoles;
}

export const useClientUsers = () => {
  const [users, setUsers] = useState<ClientUser[]>([]);
  const [userCount, setUsersCount] = useState<number>(0);
  const [userPage, setUsersPage] = useState<number>(1);
  const [userLimit, setUsersLimit] = useState<number>(15);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [loadingNewUser, setLoadingNewUser] = useState<boolean>(false);

  const getClientUsers = useCallback(
    async (clientId: number, search?: string) => {
      try {
        let query = `take=${userLimit}&page=${userPage}`;
        if (search) {
          query += `&search=${search}`;
        }
        setLoadingUsers(true);
        const usersResponse = await httpGet(
          `${clientsBaseURL}/${clientId}/users?${query}`,
          false
        );
        setUsers(usersResponse.data);
        setUsersCount(usersResponse?.meta?.itemCount);
      } catch (err) {
        setLoadingUsers(false);
        console.log(err);
      } finally {
        setLoadingUsers(false);
      }
    },
    [userLimit, userPage]
  );

  const removeUserFromClient = async (userId: number, clientId: number) => {
    await httpDelete(`${clientsBaseURL}/${clientId}/users/${userId}`);
  };

  const createClientUser = async (
    newUser: CreateClientUser,
    clientId: number
  ): Promise<ClientUser> => {
    const createdUser = await httpPost(`${clientsBaseURL}/${clientId}/users`, {
      ...newUser
    });
    return createdUser;
  };

  const updateClientUser = async (
    clientId: number,
    userId: number,
    user: CreateClientUser
  ) => {
    setLoadingNewUser(true);
    const updatedUser = await httpPost(
      `${clientsBaseURL}/${clientId}/users/${userId}`,
      {
        ...user
      }
    );
    setLoadingNewUser(false);
    return updatedUser;
  };

  return {
    users,
    loadingUsers,
    getClientUsers,
    removeUserFromClient,
    loadingNewUser,
    createClientUser,
    userCount,
    userPage,
    setUsersPage,
    userLimit,
    setUsersLimit,
    updateClientUser
  };
};
