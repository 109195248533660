import { Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.borders.color};
  }
  &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors.error.main};
  }
  border-radius: 3px;
  .MuiSelect-select {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
