import { Box, TextField, colors, styled } from '@mui/material';
import { FormTextField } from 'src/components/FormFields';
import { StyledMuiTextField } from '../../styled';

export const HeadingContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  .MuiInput-root {
    padding-inline: 0;
  }
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const Title = styled(StyledMuiTextField)`
  input {
    ${({ theme }) => theme.typography.h2};
  }
`;
