import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { MenuItem, OutlinedInput } from '@mui/material';

import { LogicUIProps } from '../..';
import { useTranslation } from 'react-i18next';
import * as S from './styled';
import * as SS from '../../styled';
import { LOGIC_MAP } from '../../../../constants';
import { OptionalRules } from 'src/models/logic';

export const YesNo = ({
  required,
  criteriaType,
  onDelete,
  isReadOnly,
  addNewCriteria,
  submitForm
}: LogicUIProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (values.rule === 'IS_EMPTY') {
      setFieldValue('action', 'SCORING');
    } else {
      setFieldValue('action', values.action || 'FLAG_REPORT');
    }
  }, [values.rule, setFieldValue]);

  const [pendingAddCriteria, setPendingAddCriteria] = useState(false);

  const handleActionChange = async (e: any) => {
    const selectedValue = e.target.value;
    await setFieldValue('action', selectedValue);
    if (selectedValue === 'ADD_CRITERIA') {
      setPendingAddCriteria(true);
      await submitForm();
    }
  };

  useEffect(() => {
    if (pendingAddCriteria) {
      addNewCriteria();
      setPendingAddCriteria(false);
    }
  }, [pendingAddCriteria]);

  return (
    <S.StyledLogicUI
      onDelete={onDelete}
      required={required}
      criteriaType={criteriaType}
      ifSection={() => (
        <>
          <SS.RuleSelect
            name="rule"
            placeholder={t('CHECKLIST_BUILDER.CRITERIA.TYPE_PLACEHOLDER')}
            fullWidth={false}
            renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
            submitOnBlur
            disabled={isReadOnly}
          >
            {LOGIC_MAP['Yes/No'].rules
              .filter((e) => !OptionalRules.includes(e) || !required)
              .map((e) => (
                <MenuItem key={e} value={e}>
                  {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
                </MenuItem>
              ))}
          </SS.RuleSelect>
          {values['rule'] && !OptionalRules.includes(values['rule']) && (
            <S.ValuesSelect
              fullWidth={false}
              name="values"
              multiple
              placeholder={t('CHECKLIST_BUILDER.LOGIC.YES_NO_PLACEHOLDER')}
              input={<OutlinedInput />}
              renderValue={(selected: []) => (
                <S.ChoiceContainer>
                  {selected.map((value) => (
                    <S.StyledChip
                      key={value}
                      label={value}
                      variant="outlined"
                      $type={value}
                    />
                  ))}
                </S.ChoiceContainer>
              )}
              displayEmpty
              submitOnBlur
              disabled={isReadOnly}
            >
              {LOGIC_MAP[criteriaType].allowedValues.map((e) => (
                <MenuItem key={e} value={e}>
                  {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
                </MenuItem>
              ))}
            </S.ValuesSelect>
          )}
        </>
      )}
      thenSection={() => (
        <SS.RuleSelect
          fullWidth={false}
          name="action"
          placeholder={t('CHECKLIST_BUILDER.LOGIC.ACTION_PLACEHOLDER')}
          renderValue={(e) => t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
          submitOnBlur
          disabled={isReadOnly}
          onChange={handleActionChange}
        >
          {LOGIC_MAP['Yes/No'].actions
            .filter(
              (action) => values.rule !== 'IS_EMPTY' || action !== 'FLAG_REPORT'
            )
            .map((e) => (
              <MenuItem key={e} value={e}>
                {t(`CHECKLIST_BUILDER.LOGIC.${e}`)}
              </MenuItem>
            ))}
        </SS.RuleSelect>
      )}
      scoreSection={() =>
        values.action === 'SCORING' ? (
          <S.ValueTextField
            submitOnBlur
            type="text"
            variant="outlined"
            fullWidth={false}
            name="score"
            helperText=""
            value={values.score}
            disabled={isReadOnly}
          />
        ) : null
      }
      isReadOnly={isReadOnly}
    />
  );
};
